import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import {
  isTokenInValid,
  toastSuccessMessage,
  toastErrMessage,
} from "../../../utils/utilFunctions";
import {
  getMarketPlacesApi,
  editMarketPlacesApi,
} from "../../../api/adminDashboardApi";
import AdminNav from "../../../components/AdminNav/AdminNav";
import "./AdminMarketPlaces.scss";

const AdminMarketPlaces = () => {
  const [selectedMarketPlaceId, setSelectedMarketPlaceId] = useState(1);
  const [marketPlaceData, setMarketPlaceData] = useState([]);
  const [descriptionInput, setDescriptionInput] = useState("");
  const [bothRouteInput, setBothRouteInput] = useState();
  const [soldDefaultInput, setSoldDefaultInput] = useState();
  const [isActiveInput, setIsActiveInput] = useState();
  const [barcodeSearchInput, setBarcodeSearchInput] = useState();
  const [settingsJson, setSettingsJson] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const toBool = (string) => string === "true";

  const fetchData = async () => {
    setLoading(true);
    const marketPlacesResponse = await getMarketPlacesApi();
    if (marketPlacesResponse.success) {
      setMarketPlaceData(marketPlacesResponse.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  useEffect(() => {
    populateMarketplaceData(selectedMarketPlaceId);
  }, [selectedMarketPlaceId, marketPlaceData]);

  const populateMarketplaceData = (id) => {
    if (marketPlaceData.length > 0) {
      const selectedMarketPlace = marketPlaceData.find(
        (marketplace) => marketplace.id === id
      );
      setDescriptionInput(
        selectedMarketPlace.description ? selectedMarketPlace.description : ""
      );
      setBothRouteInput(selectedMarketPlace.hasBothRouteType);
      setSoldDefaultInput(selectedMarketPlace.isSoldDefaultRouteType);
      setIsActiveInput(selectedMarketPlace.isActive);
      setBarcodeSearchInput(selectedMarketPlace.hasBarcodeSearch);
      setSettingsJson(
        JSON.stringify(selectedMarketPlace.settings, undefined, 4)
      );
    }
  };

  const handleMarketPlaceSubmit = async (e) => {
    e.preventDefault();
    let listing = {};
    try {
      listing = JSON.stringify(JSON.parse(settingsJson.trim()));
    } catch (error) {
      toastErrMessage("Incorrect json provided.");
      return;
    }

    setSubmitDisabled(true);
    if (window.confirm("Are you sure you want to do this?")) {
      const response = await editMarketPlacesApi(selectedMarketPlaceId, {
        description: descriptionInput ? descriptionInput.trim() : null,
        hasBothRouteType: bothRouteInput,
        isSoldDefaultRouteType: soldDefaultInput,
        isActive: isActiveInput,
        hasBarcodeSearch: barcodeSearchInput,
        settings: listing,
      });
      if (response.success) {
        toastSuccessMessage("Marketplace edited successfully");
        await fetchData();
      } else {
        toastErrMessage(response.message);
      }
    }
    setSubmitDisabled(false);
  };

  const BooleanSelection = () => (
    <>
      <option value={true}>Yes</option>
      <option value={false}>No</option>
    </>
  );

  return (
    <div className="adminResults dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Marketplace Settings</div>
          <AdminNav />
        </div>

        <div className="marketPlaceSettingsWrapper">
          {loading ? (
            <div>loading...</div>
          ) : marketPlaceData.length > 0 ? (
            <form onSubmit={handleMarketPlaceSubmit}>
              <div className="formSelection">
                <span>
                  <label htmlFor="marketplaceSelect">Select Marketplace</label>
                  <select
                    value={selectedMarketPlaceId}
                    onChange={(e) =>
                      setSelectedMarketPlaceId(Number(e.target.value))
                    }
                  >
                    {marketPlaceData.map((marketplace) => (
                      <option key={marketplace.id} value={marketplace.id}>
                        {marketplace.name}
                      </option>
                    ))}
                  </select>
                </span>

                <span>
                  <label htmlFor="marketplaceDescription">
                    Marketplace Description
                  </label>
                  <input
                    name="description"
                    type="text"
                    placeholder="Description for the admin side"
                    value={descriptionInput}
                    onChange={(e) => setDescriptionInput(e.target.value)}
                    required={false}
                    title="Enter any description you might find useful"
                    maxLength="255"
                  />
                </span>
              </div>
              <div className="formSelection">
                <span>
                  <label htmlFor="activeRoutes">
                    Both Active and Sold Routes? <br />
                  </label>
                  <select
                    name="hasBothRouteType"
                    title="Does it have active and sold routes?"
                    value={bothRouteInput}
                    onChange={(e) => setBothRouteInput(toBool(e.target.value))}
                  >
                    <BooleanSelection />
                  </select>
                </span>

                <span>
                  <label htmlFor="soldCheckbox">
                    Should Sold be Checked First? <br />
                  </label>
                  <select
                    name="isSoldDefaultRouteType"
                    title="Should we check sold listings first?"
                    value={soldDefaultInput}
                    onChange={(e) =>
                      setSoldDefaultInput(toBool(e.target.value))
                    }
                  >
                    <BooleanSelection />
                  </select>
                </span>
                <span>
                  <label htmlFor="barcodeSearch">
                    Barcode Search?
                    <br />
                  </label>
                  <select
                    name="hasBarcodeSearch"
                    title="Does it have barcode search?"
                    value={barcodeSearchInput}
                    onChange={(e) =>
                      setBarcodeSearchInput(toBool(e.target.value))
                    }
                  >
                    <BooleanSelection />
                  </select>
                </span>
              </div>

              <label htmlFor="settingsJSON">Settings JSON:</label>
              <p className="noteHighlight" style={{ fontSize: "14px" }}>
                Be careful! Earthquakes might occur :D
              </p>
              <textarea
                value={settingsJson}
                onChange={(e) => setSettingsJson(e.target.value)}
              />
              <div className="gap"></div>

              <input
                type="submit"
                value="Save changes"
                style={{ backgroundColor: "#1d4250" }}
                className="filterSubmit"
                disabled={submitDisabled}
              />
              <input
                type="button"
                value="Cancel"
                title="Revert unsaved changes"
                className="filterSubmit"
                disabled={submitDisabled}
                onClick={() => populateMarketplaceData(selectedMarketPlaceId)}
              />
            </form>
          ) : (
            <div>No marketplace found</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AdminMarketPlaces;
