import axios from "axios";
import { Config } from "./Config";

const { Environment, ProjectName, Module, LoggerName } = Config.GRAY_LOG;

const graylogUrl = `api/graylog`;
const GraylogApi = `${graylogUrl}/logError`;

export const logError = (method, message, data) => {
  const LogBody = {
    Project: ProjectName,
    Module: Module,
    Environment: Environment,
    level: 3,
    logger: LoggerName,
    message: message,
    Data: data,
    MethodName: method,
    source: LoggerName,
  };
  logGrayLog(LogBody);
};
export const logInfo = (method, message, data) => {
  const LogBody = {
    Project: ProjectName,
    Module: Module,
    Environment: Environment,
    level: 6,
    logger: LoggerName,
    message: message,
    Data: data,
    MethodName: method,
    source: LoggerName,
  };
  logGrayLog(LogBody);
};

export const logGrayLog = (body) => {
  console.log(body);
  axios
    .post(GraylogApi, body)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

const Logger = { logGrayLog, logInfo, logError };

export default Logger;
