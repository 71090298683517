import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import { isTokenInValid } from "../../../utils/utilFunctions";
import {
  getStoresApi,
  getVersionApi,
  getAdminFilterStatsApi,
  getMarketPlacesApi,
} from "../../../api/adminDashboardApi";
import { INFO_TYPE } from "../../../utils/Constants";
import "./AdminFilterStats.scss";
import AdminNav from "../../../components/AdminNav/AdminNav";

const AdminFilterStats = () => {
  const [stores, setStores] = useState([]);
  const [versions, setVersions] = useState([]);
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [versionId, setVersionId] = useState("");
  const [marketPlaceId, setMarketPlaceId] = useState("");
  const [filterStatsData, setFilterStatsData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const storeResponse = await getStoresApi();
    if (storeResponse.success) {
      setStores(storeResponse.data);
    }
    const versionResponse = await getVersionApi();
    if (versionResponse.success) {
      setVersions(versionResponse.data);
    }
    const marketPlaceResponse = await getMarketPlacesApi();
    if (storeResponse.success) {
      setMarketPlaces(marketPlaceResponse.data);
    }
    setLoading(false);
  };
  const fetchStatsData = async () => {
    setLoading(true);
    const statsResponse = await getAdminFilterStatsApi(
      storeId,
      versionId,
      marketPlaceId
    );
    console.log("statsResponse", statsResponse);
    if (statsResponse.success && statsResponse.data) {
      const responseData = statsResponse.data
        .map((item) => {
          const version = versions.find((ver) => ver.id == versionId);
          return {
            ...item,
            versionName: version ? version?.name : versionId,
            choppedPercentage: (
              100 -
              (item.itemsAfterFilter / item.itemsBeforeFilter) * 100
            ).toFixed(2),
          };
        })
        .sort(
          (a, b) =>
            a.isActiveListing - b.isActiveListing ||
            b.choppedPercentage - a.choppedPercentage
        );
      setFilterStatsData(responseData);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  const onLoadClick = async () => {
    await fetchStatsData();
  };

  const renderTable = () => {
    if (filterStatsData && filterStatsData.length > 0) {
      return (
        <table className="dashboard__tableBorder innerTable">
          <thead>
            <tr>
              <th className="dashboard__tableBorder">
                <h1>Version</h1>
              </th>
              <th className="dashboard__tableBorder">
                <h1>Route Type</h1>
              </th>
              <th className="dashboard__tableBorder">
                <h1>Filter Name</h1>
              </th>
              <th className="dashboard__tableBorder">
                <h1>Items Before Filter</h1>
              </th>
              <th className="dashboard__tableBorder">
                <h1>Items After Filter</h1>
              </th>
              <th className="dashboard__tableBorder">
                <h1>Chopped Percentage</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            {filterStatsData.map((x, i) => (
              <tr key={i + 1}>
                <td className="dashboard__tableBorder">
                  <h3>{x.versionName}</h3>
                </td>
                <td className="dashboard__tableBorder">
                  <h3>{x.isActiveListing ? "Active" : "Sold"}</h3>
                </td>
                <td className="dashboard__tableBorder">
                  <h3>{INFO_TYPE[x.infoType]}</h3>
                </td>
                <td className="dashboard__tableBorder">
                  <h3>{x.itemsBeforeFilter}</h3>
                </td>
                <td className="dashboard__tableBorder">
                  <h3>{x.itemsAfterFilter}</h3>
                </td>
                <td className="dashboard__tableBorder">
                  <h3>{x.choppedPercentage}</h3>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else {
      return <div className="noDataFound">No data found</div>;
    }
  };

  const renderContent = () => {
    if (loading) {
      return <div>loading...</div>;
    }

    if (filterStatsData) {
      return <div className="filterStatsWrapper">{renderTable()}</div>;
    }

    return <div className="noDataFound">No data found</div>;
  };

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Filter Stats</div>
          <AdminNav />
        </div>
        <div className="dashboard__filters">
          <select
            name="versionDropDown"
            onChange={(e) => setVersionId(e.target.value)}
            value={versionId}
            required={true}
          >
            <option value="">Select Version</option>
            {versions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <select
            name="storeDropDown"
            onChange={(e) => setStoreId(e.target.value)}
            value={storeId}
            required={true}
          >
            <option value="">Select Store</option>
            {stores.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <select
            name="marketPlaceDropDown"
            onChange={(e) => setMarketPlaceId(e.target.value)}
            value={marketPlaceId}
            required={true}
          >
            <option value="">Select Marketplace</option>
            {marketPlaces.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={() => {
              onLoadClick();
            }}
          >
            Load
          </button>
        </div>
        <div className="dashboard__collapsible"></div>
        {renderContent()}
      </section>
    </div>
  );
};

export default AdminFilterStats;
