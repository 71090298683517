import React from "react";
import PropTypes from "prop-types";
import "./StrengthBar.scss";

const StrengthBar = ({ enable, level, current }) => {
  StrengthBar.propTypes = {
    enable: PropTypes.bool.isRequired,
    level: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
  };
  return (
    <div
      className={` category__bar__item ${
        enable ? ` color__bar__${level} ` : " color__bar__default "
      }${current === 1 && " color__bar__one "}${
        current === 4 && " color__bar__six "
      }`}
    ></div>
  );
};

const StrengthBars = ({ bars }) => {
  const Categories = [];
  for (let current = 1; current < 5; current++) {
    Categories.push(
      <StrengthBar
        level={bars}
        enable={current <= bars}
        key={current}
        current={current}
      />
    );
  }
  return Categories;
};

const Strength = ({ bars }) => {
  Strength.propTypes = {
    bars: PropTypes.array.isRequired,
  };
  return (
    <div className="strength__bar__container">
      <StrengthBars bars={bars} />
    </div>
  );
};
export default Strength;
