import { toast } from "react-toastify";
import jsonwebtoken from "jsonwebtoken";
import Storage from "./WebStorage";
import escapeStringRegexp from "escape-string-regexp";
import {
  marketName,
  ExtremeChopPercentage,
  PAIR_LOGIC_TYPE,
  PAIR_TYPE,
  ITEM_STATUS,
} from "./Constants";
import { getMarketPlaceItemData } from "../api/adminDashboardApi";
import { logError } from "../logger/logger";
import * as amplitude from "@amplitude/analytics-browser";
export const toastErrMessage = (
  message,
  position = "bottom-center",
  hideProgressBar = true,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = true,
  autoClose = 3000
) => {
  toast.error(message, {
    position,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    autoClose,
  });
};
export const toastSuccessMessage = (
  message,
  position = "bottom-center",
  hideProgressBar = true,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = true,
  autoClose = 3000
) => {
  toast.success(message, {
    position,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    autoClose,
  });
};

export const isTokenInValid = () => {
  let expired = true;
  const token = Storage.getAuthToken();
  if (token && jsonwebtoken.decode(token)) {
    const decoded = jsonwebtoken.decode(token);
    const now = Date.now().valueOf() / 1000;
    if (typeof decoded.exp !== "undefined" && decoded.exp > now) {
      expired = false;
    }
  }
  return expired;
};

export const isInRole = (roleTypes) => {
  let role = false;
  const token = Storage.getAuthToken();
  if (token && jsonwebtoken.decode(token)) {
    const decoded = jsonwebtoken.decode(token);
    role = roleTypes.indexOf(decoded.role) > -1;
  }
  return role;
};

export const getWindowSize = () => {
  return window.innerWidth;
};

export const getTrimmedText = (
  text,
  windowSize,
  mblEndIndex,
  tabletEndIndex,
  lgEndIndex
) => {
  if (windowSize > 600 && windowSize < 900) {
    return text.substring(0, tabletEndIndex);
  } else if (windowSize < 600) {
    return text.substring(0, mblEndIndex);
  } else {
    return text.substring(0, lgEndIndex);
  }
};

export const infoAboutLoggedInUser = () => {
  let decoded = {};
  const token = Storage.getAuthToken();
  if (token && jsonwebtoken.decode(token)) {
    decoded = jsonwebtoken.decode(token);
  }
  return decoded;
};
export const getFormattedDate = (dateString) => {
  const m = new Date(dateString);
  const formatted_date =
    ("0" + m.getDate()).slice(-2) +
    "/" +
    ("0" + (m.getMonth() + 1)).slice(-2) +
    "/" +
    m.getFullYear() +
    " " +
    ("0" + m.getHours()).slice(-2) +
    ":" +
    ("0" + m.getMinutes()).slice(-2) +
    ":" +
    ("0" + m.getSeconds()).slice(-2);
  return formatted_date;
};
export const mapPairStringColumnValuesWithNumericValues = (
  obj,
  stringValue
) => {
  for (const [key, value] of Object.entries(obj)) {
    if (key === stringValue) return value;
  }
  return null;
};
export const mapPairsNumericColumnValuesWithString = (obj, numericValue) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === numericValue) return key;
  }
  return null;
};
export const uniquePairs = (newAllPairs, oldAllPairs) => {
  const uniqueArray = [];
  [...newAllPairs, ...oldAllPairs].forEach((pairObj) => {
    if (
      uniqueArray.findIndex(
        (pair) =>
          pair.words === pairObj.words &&
          pair.pairType === pairObj.pairType &&
          pair.categoryTypeId === pairObj.categoryTypeId &&
          pair.pairCategoryId === pairObj.pairCategoryId &&
          pair.logicType === pairObj.logicType
      ) === -1
    ) {
      uniqueArray.push(pairObj);
    }
  });
  return uniqueArray.map((pair) => {
    return {
      versionId: pair?.versionId,
      words: pair?.words,
      shouldRelax: pair?.shouldRelax,
      pairCategoryId: pair?.pairCategoryId,
      orderNumber: pair?.orderNumber,
      pairType: pair?.pairType,
      order: pair?.order,
      logicType: pair?.logicType,
      isActive: pair?.isActive,
      description: pair?.description,
      categoryTypeId: pair?.categoryTypeId,
      placement: pair?.placement,
      replaceWith: pair?.replaceWith,
    };
  });
};
export const mapPairStringColumnValuesWithNumericValuesFromArray = (
  arr,
  stringValue
) => {
  for (let element of arr) {
    if (element?.name?.toLowerCase() === stringValue?.toLowerCase())
      return element.id;
  }
  return null;
};
export const removeUnnecessaryColumn = (pairs) => {
  return pairs.map((pair) => {
    return {
      words: pair.words,
      pairCategory: pair.pairCategory?.name,
      replaceWith: pair.replaceWith,
      logicType: mapPairsNumericColumnValuesWithString(
        PAIR_LOGIC_TYPE,
        pair.logicType
      ),
      pairType: mapPairsNumericColumnValuesWithString(PAIR_TYPE, pair.pairType),
      shouldRelax: pair.shouldRelax,
      orderNumber: pair.orderNumber,
      placement: pair.placement,
      categoryType: pair.categoryType?.name,
      description: pair.description,
      isActive: pair.isActive,
      order: pair.order,
    };
  });
};
export const JWTConfig = (contentType) => {
  if (!isTokenInValid()) {
    const token = Storage.getAuthToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": contentType,
      },
    };
  } else {
    window.location.href = "/login";
    throw new Error("Not authenticated");
  }
};
export const returnResponseMessage = (errObj) => {
  return errObj.data
    ? errObj.data.message
    : errObj.message
    ? errObj.message
    : "An error occurred while processing your request.";
};
export const parseErrors = (errObj) => {
  try {
    let message = "";
    const { errors } = errObj.data;
    switch (errObj.status) {
      case 400:
        errors.map((obj, index) => {
          message = `${message + obj.param.toUpperCase()}: ${obj.msg}`;
          message = index === errors.length - 1 ? message : `${message} ,`;
          return message;
        });
        return {
          success: false,
          message,
        };
      case 401:
        return {
          success: false,
          message:
            errObj.data?.message ?? "You are not authorized. Please login",
        };
      case 403:
      case 404:
      case 409:
      case 413:
      case 422:
        return {
          success: false,
          message: returnResponseMessage(errObj),
        };
      default:
        return {
          success: false,
          message: "An error occurred while processing your request.",
        };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while processing your request.",
    };
  }
};

export const getKeyByValue = (object, value) => {
  const filterName = Object.keys(object).find((key) => object[key] === value);
  return filterName?.replace(/_/g, " ").replace(/Filter/gi, "");
};
export const filteredArrayOfObjects = (body) => {
  let newArray = [];
  for (const element of body) {
    newArray.push({
      store: element.store,
      disputeStatus: element.disputeStatus,
      barcode: element.barcode,
      title: element.title,
      strength: element.strength,
      triggerBestMatch: element.triggerBestMatch,
      condition: element.conditionName,
      uniqueCode: element.uniqueCode,
      mostProfitable: element.mostProfitable,
      slowestResponse: element.slowestResponse,
      responseDateTime: element.responseDateTime,
      marketPlaceName: element.marketPlaceName,
      averagePrice: element.averagePrice,
      profit: element.profit,
      responseTime: element.responseTime,
      apiId: element.apiId,
      itemsBeforeFiltering: element.itemsBeforeFiltering,
      itemsAfterFiltering: element.itemsAfterFiltering,
      brightResponseTime: element.brightResponseTime,
      filterResponseTime: element.filterResponseTime,
      totalTime: element.totalResponseTime,
    });
  }
  return newArray;
};
export const findInArray = (
  array,
  knownPropertyValue,
  knownPropertyName = "name",
  propertyToFind = "id"
) => {
  const object = array.find(
    (element) => element[knownPropertyName] === knownPropertyValue
  );
  return object ? object[propertyToFind] : "";
};

export const pruneOutInvalidPriceListings = (searchResults) => {
  searchResults.forEach((searchResultObject) => {
    if (searchResultObject.marketPlaceName === "Ebay") {
      const ebayItem = searchResultObject;
      const resultItemsForSameQuery = searchResults.filter(
        (resultItem) => resultItem.id === searchResultObject.id
      );

      // Filter out items with mean prices 90% greater or lesser than that of ebay
      const invalidResultItems = resultItemsForSameQuery.filter((item) => {
        if (item.meanPrice && !item.marketPlaceName.includes("Ebay")) {
          return (
            (item.meanPrice - ebayItem.meanPrice) / ebayItem.meanPrice >= 0.9 ||
            (ebayItem.meanPrice - item.meanPrice) / item.meanPrice >= 0.9
          );
        }
      });

      if (invalidResultItems.length) {
        searchResults = searchResults.filter((searchResult) => {
          return !invalidResultItems.find(
            (invalidItem) => invalidItem.apiId === searchResult.apiId
          );
        });
      }
    }
  });

  return searchResults;
};

export function containWord(word, stringToCheck, escapeString = false) {
  const regexWord = escapeString ? escapeStringRegexp(word) : word;
  let regex = new RegExp(`(?<=^|\\s)${regexWord}(?=\\s|$)`, "ig");
  let matchResult = stringToCheck.match(regex);
  if (matchResult) {
    return true;
  } else {
    if (escapeString) {
      const wordToCheck = regexWord.replace(/  ?/g, "=");
      regex = new RegExp(`(?<=^|\\s){${wordToCheck}}(?=\\s|$)`, "ig");
      matchResult = stringToCheck.match(regex);
      if (matchResult) return true;
    } else {
      //do nothing
    }
  }
  return false;
}
export const filterEbayEdingburgUniversity = (item, priceArray) => {
  if (item.marketPlace.name !== marketName.EBAY_EDINBURG_UNIVERSITY)
    priceArray.push(item);
};

export const calculateMaxPriceMarket = (priceArray, maxPriceIs) => {
  let maxMarketIs = "";
  priceArray.forEach((item) => {
    if (parseFloat(item.meanPrice) === maxPriceIs) {
      maxMarketIs = item.marketPlace.name;
    }
  });
  return maxMarketIs;
};

export const priceOfEbayMarketplace = (priceArray) => {
  let ebayMeanPrice = 0;
  priceArray.forEach((item) => {
    if (item.marketPlace.name === marketName.EBAY) {
      ebayMeanPrice = item.meanPrice;
    }
  });
  return parseFloat(ebayMeanPrice);
};

export const marketPlacesWithOutNullPrice = (priceArray) => {
  return priceArray.filter((item) => item.meanPrice != null);
};
export const calculateUpperEbayCap = (
  ebayMeanPrice,
  EbayPercentValueForHideFbDepopValue
) => {
  return (
    ebayMeanPrice + (ebayMeanPrice * EbayPercentValueForHideFbDepopValue) / 100
  );
};

export const calculateLowerEbayCap = (
  ebayMeanPrice,
  EbayPercentValueForHideFbDepopValue
) => {
  return (
    ebayMeanPrice - (ebayMeanPrice * EbayPercentValueForHideFbDepopValue) / 100
  );
};

export const extractOnlyMeanPricesFromAllMarketPlaces = (
  mrktPlacesWithOutNullPrice,
  upperCap,
  lowerCap,
  ebayMeanPrice
) => {
  let priceArr = [];
  if (ebayMeanPrice) {
    mrktPlacesWithOutNullPrice.forEach((item) => {
      const marketplaceMeanPrice = Number(item?.meanPrice || 0);
      const shouldExcludeMarketplace =
        marketplaceMeanPrice >= upperCap || marketplaceMeanPrice <= lowerCap;

      const mktPlaceName = item?.marketPlace?.name?.toLowerCase() || "";
      if (!(shouldExcludeMarketplace && mktPlaceName !== "depop")) {
        priceArr.push(Number(item.meanPrice));
      }
    });
  } else {
    mrktPlacesWithOutNullPrice.forEach((item) => {
      priceArr.push(Number(item.meanPrice));
    });
  }
  return priceArr;
};
export const createListingObject = async (
  marketPlaceItemId,
  marketName,
  _listLength,
  allData
) => {
  const res = await getMarketPlaceItemData(marketPlaceItemId);

  try {
    if (res.success) {
      const {
        data: { filtered },
      } = res;
      const filterResponseArray = filtered.map((item, i) => {
        item.index = i;
        return item;
      });
      const marketObj = {
        marketIs: marketName,
        data: filterResponseArray,
        marketPlaceItemId,
      };

      const lengthOfList = filterResponseArray.length;
      allData.push(marketObj);
      return {
        filterResponseArray,
        marketObj,
        lengthOfList,
        allData,
      };
    }
  } catch (error) {
    logError(`createListingObject Err!  ${error}`);
  }
};

export const updateAndAssignIDToListing = (
  listingArray,
  marketName,
  marketPlaceItemId
) => {
  const {
    data: { filtered },
  } = listingArray;
  filtered.map((item, i) => {
    item.index = i;
    item.marketName = marketName;
    item.marketPlaceItemId = marketPlaceItemId;
    return item;
  });
  return filtered;
};

export const calculateMaxPrice = (priceArray) =>
  priceArray.reduce((prev, current) =>
    parseFloat(prev.meanPrice) > parseFloat(current.meanPrice) ? prev : current
  );

export const copyDataIntoClipBoard = (data) => {
  return navigator.clipboard.writeText(JSON.stringify(data, null, 2));
};
export const copyData = (data) => {
  return navigator.clipboard.writeText(data);
};

export const calculatePercentageFunction = (value, totalValue) => {
  let percentageValue = 0;
  if (value !== null && totalValue !== null && totalValue !== 0) {
    percentageValue = (value / totalValue) * 100;
  }
  return percentageValue.toFixed(1);
};
export const calculateMostProfitablePrice = (item) => {
  let mostProfitable = {};
  let lowerCap = 0;
  let upperCap = 0;
  let allMarketPlaces = item.marketPlaceItems;
  let mrktPlacesWithOutNullPrice =
    marketPlacesWithOutNullPrice(allMarketPlaces);
  if (mrktPlacesWithOutNullPrice.length > 0) {
    const ebayMeanPrice = priceOfEbayMarketplace(allMarketPlaces);
    if (ebayMeanPrice) {
      upperCap = calculateUpperEbayCap(ebayMeanPrice, ExtremeChopPercentage);
      lowerCap = calculateLowerEbayCap(ebayMeanPrice, ExtremeChopPercentage);
    }
    const meanPricesArray = extractOnlyMeanPricesFromAllMarketPlaces(
      mrktPlacesWithOutNullPrice,
      upperCap,
      lowerCap,
      ebayMeanPrice
    );
    const maxPriceIs = Math.max(...meanPricesArray);
    mostProfitable.marketPlaceName = calculateMaxPriceMarket(
      allMarketPlaces,
      maxPriceIs
    );
    mostProfitable.meanPrice = maxPriceIs;
    return mostProfitable;
  } else {
    return null;
  }
};

export const amplitudeLog = (groupName, eventName, message, data) => {
  const storeId = localStorage.getItem("storeId");
  const storeName = localStorage.getItem("storeName");
  let instance = amplitude;
  instance.setGroup(groupName);
  instance.setUserId(`${storeName} ${storeId} `);
  instance.Identify({
    device_type: "mobile",
  });
  amplitude.Identify({
    device_type: "tablet",
  });
  amplitude.track(eventName, {
    groupName,
    eventName,
    message,
    data,
    storeId,
    storeName,
  });
};

export const getStatusLabel = (res) => {
  if (res === 1) {
    return ITEM_STATUS[1]; // "Pending"
  } else if (res === 2) {
    return ITEM_STATUS[2]; // "Completed"
  } else if (res === 3) {
    return ITEM_STATUS[3]; // "Not found"
  } else {
    return ITEM_STATUS[4]; // "Partial Results"
  }
};
