import React from "react";
import PropTypes from "prop-types";
import { BsZoomIn } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import depoplogo from "../../assets/images/depopCardLogo.svg";
import ebayLogo from "../../assets/images/ebayCardLogo.svg";
import fbLogo from "../../assets/images/fbCardLogo.png";
import depoplogoBlack from "../../assets/images/depopCardLogoBlack2.svg";
import ebayLogoBlack from "../../assets/images/ebayCardLogoBlack.svg";
import fbLogoBlack from "../../assets/images/fbCardLogoBlack.png";
import CardFooterContent from "../../components/CardFooter/cardFooterContent";
import Typography from "@mui/material/Typography";
import { CopyText } from "../../components/copyContent/copyContent";
import AddADispute from "../Dashboard/UserSearchResult/AddADispute";

import "./Dashboard.scss";
const SearchCard = ({
  itemId,
  title,
  condition,
  logo,
  mostProfitablePrice,
  status,
  dispute,
  onClick,
  dateSubmitted,
  isAiSearch,
  uniqueCode,
  havebestMatchprice,
  setRefreshAPI,
  marketPlaceItems,
}) => {
  const lowerUniqueCode = uniqueCode == null ? "" : uniqueCode.toLowerCase();
  const pendingDescription = "Searching marketplaces...";

  const [showDisputeModal, setShowDisputeModal] = React.useState(false);
  const selectMarketPlaceLogo = (mktplace) => {
    if (mktplace.marketPlace.name == "Ebay") {
      if (mktplace.meanPrice) {
        return ebayLogoBlack;
      }
      return ebayLogo;
    } else if (mktplace.marketPlace.name == "Depop") {
      if (mktplace.meanPrice) {
        return depoplogoBlack;
      }
      return depoplogo;
    } else if (mktplace.marketPlace.name == "Facebook") {
      if (mktplace.meanPrice) {
        return fbLogoBlack;
      }
      return fbLogo;
    }
  };

  const closeDispute = () => {
    // amplitudeLog("Feedback form ", "Cancel feedback view");
    setShowDisputeModal(false);
  };

  const showDisputeModalhandle = (e) => {
    // amplitudeLog("feedback form", "View Feeback form");
    setShowDisputeModal(true);
  };
  return (
    <div className="cardWrapper">
      <div className="userSearchResultModal">
        <AddADispute
          open={showDisputeModal}
          onClose={() => closeDispute()}
          itemId={itemId.toString()}
          noIssuesFound={true}
          description={title}
          setUpdateData={setRefreshAPI}
        />
      </div>
      <div
        className={`cardInner ${
          status === 3 || (status === 2 && havebestMatchprice)
            ? "cardNotFound"
            : status === 2
            ? ""
            : "cardPending"
        } ${dispute && (dispute === 1 || dispute === 2) ? "cardDispute" : ""}`}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onClick();
          }
        }}
        tabIndex={0}
        role="link"
      >
        <div className="searchTitle">
          <Tooltip
            title={
              <div style={{ display: "flex" }}>
                <CopyText content={title} />
                <Typography fontSize={20}>{title}</Typography>
              </div>
            }
            placement="top-start"
          >
            <p>{title}</p>
          </Tooltip>
          <span className="searchCondition">{condition}</span>
          <div className="cardStatus">
            <div className="searchCode">{lowerUniqueCode}</div>
            {isAiSearch && (
              <div className="searchCode aiOptimized">
                {"✨ Thrift AI Optimized"}
              </div>
            )}

            <div className="disputeStatus">
              {" "}
              {dispute && (dispute === 1 || dispute === 2) ? "DISPUTED" : ""}
            </div>
          </div>
        </div>
        {status === 2 && !havebestMatchprice ? (
          <div className="cardFooter">
            <span className="clientLogo">
              <i className="priceTitle">Most profitable</i>
              <img src={logo} alt="" />
            </span>
            <div className="cardPrices">
              <span className="price">
                <i className="priceTitle">Suggested selling price</i>
                &#163;{mostProfitablePrice}
              </span>
            </div>
          </div>
        ) : status === 3 || (status === 2 && havebestMatchprice) ? (
          <div className={"cardStatusFooter"}>
            {status === 3 ||
              (status === 2 && havebestMatchprice && (
                <span>
                  <BsZoomIn size={30} />
                </span>
              ))}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="searchStatus">No close matches found</span>
              {(dispute && (dispute === 1 || dispute === 2)) || (
                <a
                  className="mark-as-text"
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    cursor: "pointer",
                    fontSize: 13,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    showDisputeModalhandle(true);
                  }}
                  href="#"
                >
                  Share Feedback
                </a>
              )}
            </div>
          </div>
        ) : (
          <div className={"cardStatusFooter"}>
            {(status === 1 || status === 4) && (
              <React.Fragment>
                {marketPlaceItems.length ? (
                  marketPlaceItems.map((mkt) => {
                    return (
                      <CardFooterContent
                        key={mkt.marketPlace.name}
                        logo={selectMarketPlaceLogo(mkt)}
                        alt={`${mkt.marketPlace.name} logo`}
                        loaderSize="18px"
                        loaderColor="#99b0e0"
                        meanPrice={mkt.meanPrice}
                      />
                    );
                  })
                ) : (
                  <div className="onsubmit-query">
                    <div>
                      <CircularProgress sx={{ color: "black" }} size="20px" />
                    </div>
                    <div className="searchStatus">{pendingDescription}</div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
SearchCard.propTypes = {
  itemId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  mostProfitablePrice: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  dispute: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  dateSubmitted: PropTypes.string.isRequired,
  uniqueCode: PropTypes.string.isRequired,
  havebestMatchprice: PropTypes.bool.isRequired,
  marketPlaceItems: PropTypes.array.isRequired,
};
export default SearchCard;
