import { STORE_TYPE } from "../utils/Constants";
const RouteNames = {
  Default: {
    route: "/",
  },
  Login: {
    route: "/login",
  },
  AboutUs: {
    route: "/about-us",
  },
  Dashboard: {
    route: "/dashboard",
    roles: Object.values(STORE_TYPE),
  },
  Documentation: {
    route: "/documentation",
  },
  UserSearchResult: {
    route: "/itemResult",
    roles: Object.values(STORE_TYPE),
  },
  Admin: {
    Results: {
      route: "/admin/results",
      roles: [STORE_TYPE.ADMIN],
    },
    RemoveList: {
      route: "/removedListing/:id",
    },
    ApiStats: {
      route: "/admin/apiStats",
      roles: [STORE_TYPE.ADMIN],
    },
    ApiFilterStats: {
      route: "/admin/apiFilterStats",
      roles: [STORE_TYPE.ADMIN],
    },
    MarketPlaceItemData: {
      route: "/admin/marketPlaceItemJson/:marketPlaceItemId",
      roles: [STORE_TYPE.ADMIN],
    },
    FilterData: {
      route: "/admin/filterJson/:dataId",
      roles: [STORE_TYPE.ADMIN],
    },
    PAIRS: {
      route: "/admin/pairs",
      roles: [STORE_TYPE.ADMIN],
    },
    BAD_WORD_STATS: {
      route: "/admin/badWordStats",
      roles: [STORE_TYPE.ADMIN],
    },
    TestFilter: {
      route: "/admin/testFilter",
      roles: [STORE_TYPE.ADMIN],
    },
    Stores: {
      route: "/admin/stores",
      roles: [STORE_TYPE.ADMIN],
    },
    Organization: {
      route: "/admin/organization",
      roles: [STORE_TYPE.ADMIN],
    },
    WordAnchorBank: {
      route: "/admin/wordAnchorBank",
      roles: [STORE_TYPE.ADMIN],
    },
    MarketPlaces: {
      route: "/admin/marketPlaces",
      roles: [STORE_TYPE.ADMIN],
    },
    BulkUpload: {
      route: "/admin/bulkUpload",
      roles: [STORE_TYPE.ADMIN],
    },
    LuminatiErrors: {
      route: "/admin/luminatiErrors",
      roles: [STORE_TYPE.ADMIN],
    },
    DisputedItems: {
      route: "/admin/disputedItems",
      roles: [STORE_TYPE.ADMIN],
    },
    Conditions: {
      route: "/admin/conditions",
      roles: [STORE_TYPE.ADMIN],
    },

    UnassignedKeyWords: {
      route: "/admin/unassignedKeyWords",
      roles: [STORE_TYPE.ADMIN],
    },
    PostageSettings: {
      route: "/admin/postageSettings",
      roles: [STORE_TYPE.ADMIN],
    },
    Strength: {
      route: "/admin/strength/:strengthId/:query",
      roles: [STORE_TYPE.ADMIN],
    },
  },
  NotFound: {
    route: "/NotFound",
  },
  Health: {
    route: "/health",
  },
};

export default RouteNames;
