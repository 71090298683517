import { socketActions } from "../actionTypes";

const initialState = { socket: null, status: "non-initialized" };

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case socketActions.INIT_SOCKET:
      return {
        ...state,
        status: "connecting",
        socket: null,
      };

    case socketActions.ADD_SOCKET:
      return {
        ...state,
        socket: action.payload.socket,
        status: "connected",
      };
    case socketActions.CLOSE_SOCKET:
      if (state?.socket?.connected) state.socket.close();
      return {
        ...state,
        socket: initialState.socket,
        status: initialState.status,
      };

    default:
      return state;
  }
};

export default socketReducer;
