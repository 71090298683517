import propTypes from "prop-types";
const getFormattedPrice = (value) => (value ? `£${value}` : "");

const HighPriceCell = ({ row }) => {
  HighPriceCell.propTypes = {
    row: propTypes.shape({
      highestPrice: propTypes.number,
    }).isRequired,
  };
  return <div>{getFormattedPrice(row.highestPrice)}</div>;
};
export default HighPriceCell;
