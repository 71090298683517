import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ebaylogo from "../../../assets/images/ebaylogo.png";
import facebooklogo from "../../../assets/images/facebook.png";
import depoplogo from "../../../assets/images/depoplogo.png";
import {
  RiCloseFill,
  RiArrowLeftSLine,
  RiInformationLine,
} from "react-icons/ri";
import InputField from "../../../components/InputField/InputField";
import {
  toastErrMessage,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";
import { postDisputeApi, closeDisputeApi } from "../../../api/dashboardApi";

import "./cardpopup.scss";

const CardPopup = ({
  onClose,
  _onChange,
  _value,
  onDisputeUpdate,
  _popShow,
  itemId,
  title,
  barcode,
  condition,
  disputeStatus,
  marketPlaceItems,
  itemImages,
  uniqueCode,
}) => {
  const lowerUniqueCode = uniqueCode.toLowerCase();
  const [showDispute, setShowDispute] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(true);
  const [showMarketPlace, setShowMarketPlace] = useState(false);
  const [totalListing, setTotalListing] = useState(0);
  const [itemsOrder, setItemsOrder] = useState([]);
  const [disputeDescription, setDisputeDescription] = useState("");

  useEffect(() => {
    const listing = marketPlaceItems.reduce(
      (acc, curr) => acc + curr.itemsBeforeFiltering,
      0
    );
    setTotalListing(listing);
    const ebayPrice = marketPlaceItems.find(
      (x) => x.marketPlace.name === "Ebay"
    );
    const order = marketPlaceItems
      .map((io) => {
        const differenceIsNotBigThan75 =
          (io.meanPrice && io.marketPlace.name !== "Depop") ||
          !ebayPrice ||
          (io.marketPlace.name === "Depop" &&
            io.meanPrice &&
            io.meanPrice > ebayPrice.meanPrice * 0.25);
        return {
          ...io,
          meanPrice: differenceIsNotBigThan75 ? io.meanPrice : 0,
          profit: differenceIsNotBigThan75 ? io.profit : 0,
        };
      })
      .sort((a, b) => (b.profit || -1000) - (a.profit || -1000));
    setItemsOrder(order);
  }, [marketPlaceItems]);

  const showPopups = () => {
    setShowSearchPopup(false);
    setShowDispute(true);
    setDisputeDescription("");
  };

  const hidePopups = () => {
    setShowSearchPopup(true);
    setShowDispute(false);
  };

  const submitDispute = async (e) => {
    e.preventDefault();
    if (!disputeDescription || !disputeDescription.trim()) return;
    const payload = {
      itemId: itemId,
      description: disputeDescription.trim(),
    };
    const response = await postDisputeApi(payload);
    if (response.success) {
      onDisputeUpdate(itemId, 1);
      toastSuccessMessage("Marked incorrect successfully");
      hidePopups();
    } else {
      toastErrMessage(response.message);
    }
  };

  const closeDispute = async (e) => {
    e.preventDefault();
    const response = await closeDisputeApi(itemId);
    if (response.success) {
      onDisputeUpdate(itemId, 4);
    } else {
      toastErrMessage(response.message);
    }
  };

  const updateDisputeDescription = (desc) => {
    setDisputeDescription(desc);
  };

  return (
    <div>
      <div className="submitPopupWrapper whyThriftWrapper cardPopup">
        {showSearchPopup && (
          <div className="submitPopupInner">
            <form className="dashboard__form">
              <span
                className="appPop__iconClose desktopNav"
                onClick={onClose}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    onClose();
                  }
                }}
              >
                <RiCloseFill />
              </span>
              <span
                className="appPop__iconClose mobileNav"
                onClick={onClose}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    onClose();
                  }
                }}
              >
                <RiArrowLeftSLine />
              </span>
              <div className="productTitle">
                <a
                  href="https://ecommerce.shopiago.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <h1>{title}</h1>
                </a>
              </div>
              <div>
                <span className="productCondition">
                  {" "}
                  Tracking Code : {lowerUniqueCode}
                </span>
              </div>
              <span className="productCondition">
                {condition.name}
                {barcode && <i>{barcode}</i>}
              </span>
              <div className="popupBody">
                <div className="itemDetail">
                  {itemImages.length > 0 && (
                    <ul className="productPicture">
                      {itemImages
                        .sort(
                          (a, b) =>
                            a.marketPlace.imagePriority -
                            b.marketPlace.imagePriority
                        )
                        .map((ii, index) => {
                          if (index < 3) {
                            return (
                              <li key={itemId + "-" + index}>
                                <img src={ii.url || ii.marketPlaceUrl} alt="" />
                              </li>
                            );
                          }
                        })}
                    </ul>
                  )}
                  <p
                    className={
                      itemImages.length > 0
                        ? "itemDescription"
                        : "imageNotFound"
                    }
                  >
                    {itemImages.length > 0
                      ? "Example images from various marketplaces."
                      : "Oops! These sellers haven’t uploaded any pictures."}
                  </p>
                </div>

                <p className="avgPrice marketInfoWrapper">
                  Marketplace prices
                  <span className="marketInfoPopup">
                    <RiInformationLine
                      onClick={() => setShowMarketPlace(true)}
                    />
                  </span>
                </p>
                <p className="priceDesc">
                  We have reviewed {totalListing} listings across the following
                  marketplaces:
                </p>
              </div>
              <div className="popupFooter popupFooterInner marketPlacePrices">
                <ul className="priceHeading">
                  <li>Marketplace</li>
                  <li>
                    Suggested
                    <span className="toolTip">
                      The price we suggest you sell this item at on each
                      marketplace.
                    </span>
                  </li>
                  <li>
                    Profit
                    <span className="toolTip">
                      The suggested selling price minus all fees and postage.
                    </span>
                  </li>
                </ul>
                {itemsOrder.map((io, index) => {
                  return (
                    <ul
                      key={index}
                      {...(index === 0 && { className: "selectedRow" })}
                    >
                      <li>
                        <img
                          src={
                            io.marketPlace.name === "Ebay" ||
                            io.marketPlace.name === "Ebay Edinburg University"
                              ? ebaylogo
                              : io.marketPlace.name === "Facebook"
                              ? facebooklogo
                              : depoplogo
                          }
                          alt={`${io.marketPlace.name} logo`}
                        />
                      </li>
                      <li>{io.meanPrice ? `£${io.meanPrice}` : "-"}</li>
                      <li>{io.profit ? `£${io.profit}` : "-"}</li>
                    </ul>
                  );
                })}
              </div>
              {!disputeStatus || disputeStatus === 3 || disputeStatus === 4 ? (
                <p className="figDetail">
                  Do these figures not look quite right?
                  <span
                    onClick={showPopups}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        showPopups();
                      }
                    }}
                  >
                    Mark as incorrect
                  </span>
                </p>
              ) : (
                <p className="figDetailDisputed">
                  <span>You previously marked this item as incorrect. </span>
                  <span
                    onClick={closeDispute}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        closeDispute();
                      }
                    }}
                  >
                    Actually, it looks ok
                  </span>
                </p>
              )}
            </form>
          </div>
        )}

        {showDispute && (
          <div className="submitPopupInner disputeWrapper">
            <form className="dashboard__form">
              <span
                className="appPop__iconClose desktopNav"
                onClick={hidePopups}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    hidePopups();
                  }
                }}
              >
                <RiCloseFill />
              </span>

              <span
                className="appPop__iconClose mobileNav"
                onClick={hidePopups}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    hidePopups();
                  }
                }}
              >
                <RiArrowLeftSLine />
              </span>
              <div className="disputePopupTitle">
                <h1>Help us make THRIFT better</h1>
              </div>

              <div className="popupBody">
                <p>Think something is wrong with your result? </p>
                <p>
                  Tell us what’s up and we’ll fix it. Please use the field below
                  to explain what’s not quite right this time.
                </p>

                <span className="disputePopupInput">
                  <InputField
                    type="text"
                    placeholder="Description"
                    name="Description"
                    onChange={updateDisputeDescription}
                    value={disputeDescription}
                    className="despute__description"
                    required={true}
                  />
                </span>
              </div>
              <div className="popupFooter popupFooterInner">
                <button type="button" onClick={submitDispute}>
                  Report
                </button>
              </div>
            </form>
          </div>
        )}

        {showMarketPlace && (
          <div className="submitPopupWrapper whyThriftWrapper cardPopup marketPlaceTooltip">
            <div className="submitPopupInner tooltipWrapper">
              <form className="dashboard__form">
                <span
                  className="appPop__iconClose"
                  onClick={() => setShowMarketPlace(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      setShowMarketPlace(false);
                    }
                  }}
                  role=""
                >
                  <RiCloseFill />
                </span>

                <div className="popupBody">
                  <span>
                    <label htmlFor="suggested">Suggested</label>
                    <p>
                      The price we suggest you sell this item at on each
                      marketplace.
                    </p>
                  </span>

                  <span>
                    <label htmlFor="profit">Profit</label>
                    <p>
                      The suggested selling price minus all fees and postage.
                    </p>
                  </span>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
CardPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  _onChange: PropTypes.func.isRequired,
  _value: PropTypes.any.isRequired,
  onDisputeUpdate: PropTypes.func.isRequired,
  _popShow: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  barcode: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  disputeStatus: PropTypes.string.isRequired,
  marketPlaceItems: PropTypes.array.isRequired,
  itemImages: PropTypes.array.isRequired,
  uniqueCode: PropTypes.string.isRequired,
};
export default CardPopup;
