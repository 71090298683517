import React from "react";
import PropTypes from "prop-types";
import { RiAlertFill } from "react-icons/ri";
import "./NoResultBanner.scss";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CopyText } from "../../../components/copyContent/copyContent";
import { updateTriggerBestMatch } from "../../../api/dashboardApi";
import {
  getTrimmedText,
  getWindowSize,
  toastSuccessMessage,
  infoAboutLoggedInUser,
} from "../../../utils/utilFunctions";
import { FullStory } from "@fullstory/browser";

const NoResultBanner = (props) => {
  const { setShowBanner, data, setUpdateData } = props;

  let loggedInUser = infoAboutLoggedInUser();
  const getTheBestMatch = async () => {
    loggedInUser.detail =
      "User has clicked on View Close Match button to view close match";
    FullStory.event("View Close Match", loggedInUser);
    await updateTriggerBestMatch(data.id);
    toastSuccessMessage("Item re-submitted successfully");
    setUpdateData(true);
  };
  return (
    <div className="banner_card">
      <div className="banner_row1">
        <div className="warning">
          <span className="banner_icon">
            <RiAlertFill size={30} />
          </span>
          <p className="title_not_found">
            No exact matches found for{" "}
            <Tooltip
              title={
                <div style={{ display: "flex" }}>
                  <CopyText content={data?.title} />
                  <Typography fontSize={20}>{data?.title}</Typography>
                </div>
              }
              placement="top-start"
            >
              <span>
                {data?.title
                  ? data.title.length > 20
                    ? getTrimmedText(data.title, getWindowSize(), 15, 40, 70) +
                      "..."
                    : data.title
                  : ""}
              </span>
            </Tooltip>
          </p>
        </div>
        <div>
          <span
            className="close_btn show-web"
            onClick={() => setShowBanner(false)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setShowBanner(false);
              }
            }}
          >
            Close
          </span>
        </div>
      </div>
      <div className="banner_row2">
        <div className="info">would you like us to show you a close match?</div>
        <div
          onClick={getTheBestMatch}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              getTheBestMatch();
            }
          }}
          className="button button_text"
          tabIndex={0}
          role=""
        >
          No exact matches, show a close match
        </div>
      </div>

      <hr className="br" />
      <div className="banner_row3">
        <div>
          <span
            className="close_btn show-mob"
            onClick={() => setShowBanner(false)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setShowBanner(false);
              }
            }}
          >
            Close
          </span>
        </div>
      </div>
    </div>
  );
};
NoResultBanner.propTypes = {
  setShowBanner: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  setUpdateData: PropTypes.func.isRequired,
};
export default NoResultBanner;
