import React from "react";
import PropTypes from "prop-types";
import "./InputField.scss";

const InputField = ({
  type,
  className = "",
  value,
  placeholder,
  onChange,
  required = false,
  title = "Please fill out this field",
  customProps = {},
  fixedPlaceHolder = "",
  placeholderCls = "",
}) => {
  return (
    <div className="input-field__container">
      {fixedPlaceHolder && (
        <span className={`input-field__fixedPlaceholder ${placeholderCls}`}>
          {fixedPlaceHolder}
        </span>
      )}
      <input
        type={type}
        className={`input-field ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        title={title}
        autoComplete="new-password"
        {...customProps}
      />
    </div>
  );
};
InputField.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  title: PropTypes.string,
  customProps: PropTypes.object,
  fixedPlaceHolder: PropTypes.string,
  placeholderCls: PropTypes.string,
};
export default InputField;
