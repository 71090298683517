import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as MuiLink } from "@mui/material";
import "./ItemsResultList.scss";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CopyText } from "../../components/copyContent/copyContent";
import EbayLogo from "../../assets/images/ebaylogo.png";
import DepopLogo from "../../assets/images/depoplogo.png";
import FacebookMarketplace from "../../assets/images/facebookMarketplace.png";
import RemoveImage from "../../assets/images/removed.png";
import { LoaderDots } from "@thumbtack/thumbprint-react";
const ItemsResultList = ({
  img,
  itemName,
  price,
  marketName,
  listRemove,
  removelist,
  listadd,
  updateData,
}) => {
  const [isLinkDisabled, setIsLinkDisabled] = useState(false);

  const handleLinkClick = () => {
    // Disable the link
    setIsLinkDisabled(true);

    // Call the listRemove or listadd function
    if (removelist === "0") {
      listRemove();
    } else {
      listadd();
    }
  };
  useEffect(() => {
    setIsLinkDisabled(false);
  }, [updateData]);

  let logoWidth, logoHeight;
  if (marketName === "Ebay" || marketName === "Depop") {
    logoWidth = "46px";
    logoHeight = "18px";
  } else {
    logoWidth = "97px";
    logoHeight = "18px";
  }

  const data = itemName;

  return (
    <>
      <div className="br-mobile"></div>
      <div className={removelist == "0" ? "" : "listings-container"}>
        <div className="all-market-listings-container">
          <div className="pic__item-name__Link">
            {removelist == "0" ? (
              <div className={img ? "item-pic" : "no-item-pic"}>
                {img ? (
                  <img
                    src={`${img}`}
                    srcSet={`${img}`}
                    alt={"Item"}
                    loading="lazy"
                    style={{
                      borderRadius: "15px",
                      width: "126px",
                      height: "119px",
                    }}
                  />
                ) : (
                  <span className="no-picFound">No Image</span>
                )}
              </div>
            ) : (
              <div className="remove-pic">
                <img
                  src={`${RemoveImage}`}
                  srcSet={`${RemoveImage}`}
                  alt={"Item"}
                  loading="lazy"
                  style={{
                    borderRadius: "15px",
                    width: "126px",
                    height: "119px",
                  }}
                />
              </div>
            )}

            <div
              className={
                removelist == "0"
                  ? "item-name__link "
                  : "remove-item-name__link"
              }
            >
              <div
                className={
                  removelist == "0" ? "marketlogo " : "remove-marketlogo"
                }
              >
                {marketName && (
                  <img
                    src={
                      marketName === "Ebay"
                        ? EbayLogo
                        : marketName === "Depop"
                        ? DepopLogo
                        : FacebookMarketplace
                    }
                    alt="THRIFT"
                    width={logoWidth}
                    height={logoHeight}
                  />
                )}
              </div>
              <div
                className={removelist == "0" ? "item-name" : "remove-item-name"}
              >
                <Tooltip
                  title={
                    <div style={{ display: "flex" }}>
                      <CopyText content={itemName} />
                      <Typography fontSize={20}>{itemName}</Typography>
                    </div>
                  }
                >
                  <p>{data}</p>
                </Tooltip>
              </div>
              <div className="link">
                {isLinkDisabled ? (
                  <span style={{ display: "flex" }} className="loaderdots">
                    {removelist === "0"
                      ? "Remove this listing"
                      : "Add this listing again"}
                    <LoaderDots size="small" className="dots" theme="muted" />
                  </span>
                ) : (
                  <MuiLink
                    onClick={handleLinkClick}
                    color="inherit"
                    disabled={isLinkDisabled}
                  >
                    {removelist === "0"
                      ? "Remove this listing"
                      : "Add this listing again"}
                  </MuiLink>
                )}
              </div>
              <div className="link-mb">
                <div className="mb-price">{price}</div>
                {isLinkDisabled ? (
                  <span>
                    <LoaderDots size="small" className="dots" theme="muted" />
                  </span>
                ) : (
                  <MuiLink
                    onClick={handleLinkClick}
                    color="inherit"
                    disabled={isLinkDisabled}
                  >
                    {removelist === "0" ? "Remove" : "Add"}
                  </MuiLink>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              removelist == "0" ? "list-item-price" : "remove-list-item-price"
            }
          >
            {price}
          </div>
        </div>
        <div />
      </div>
    </>
  );
};
ItemsResultList.propTypes = {
  img: PropTypes.string.isRequired,
  itemName: PropTypes.string,
  price: PropTypes.number,
  marketName: PropTypes.string,
  listRemove: PropTypes.func,
  removelist: PropTypes.any,
  listadd: PropTypes.func,
  updateData: PropTypes.func,
};
export default ItemsResultList;
