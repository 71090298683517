import { Add } from "@mui/icons-material";
import { Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logger from "../../../logger/logger";
import {
  getStoresApi,
  postStoresApi,
  updateStore,
  getOrganizations,
} from "../../../api/adminDashboardApi";
import EditIcon from "@mui/icons-material/Edit";
import AdminNav from "../../../components/AdminNav/AdminNav";
import Header from "../../../components/Header/Header";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import { STORE_TYPE, STORE_TYPES, isActive } from "../../../utils/Constants";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getKeyByValue,
  isTokenInValid,
  toastErrMessage,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";
import "./AdminStores.scss";
import { CreateAndEditStore } from "./CreateAndEdit";
import { Select } from "../../../components/Select";
import { TextField } from "../../../components/TextField";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";

import "../../../components/ReactDataTable/ReactDataTable.scss";

const tableHeaders = [
  { label: "Organization", value: "organizationName", filter: true },
  { label: "Store Name", value: "name", filter: true },
  { label: "Store Code", value: "" },
  { label: "Store Type", value: "" },
  { label: "Active", value: "" },
  { label: "Enable Navigation", value: "" },
  { label: "Api Access", value: "" },
  { label: "Edit", value: "" },
  { label: "Delete", value: "" },
  { label: "Api Token", value: "" },
  { label: "Api Expiry Date", value: "" },
  { label: "Navigation Link", value: "" },
];

const AdminStores = () => {
  const [storeData, setStoreData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [storeOpen, setStoreModal] = useState(false);

  const [singleStoreInfo, setSingleStoreInfo] = useState({
    data: {},
    showEditModal: false,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const [sortKey, setSortKey] = useState(null);
  const [storeName, setStoreName] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [storeType, setStoreType] = useState("all");
  const [storeActive, setStoreActive] = useState("all");
  const [navigationEnabled, setNavigationEnabled] = useState("all");
  const [apiAccess, setApiAccess] = useState("all");
  const [navigationLink, setNavigationLink] = useState("");
  const [OrganzationData, setOrganzationData] = useState([{}]);
  const [filterOrganzationId, setFilterOrganzationId] = useState("");
  const [filterStoreId, setFilterStoreId] = useState("");
  const [filterStoreCodeId, setFilterStoreCodeId] = useState("");
  const [filterStoreTypeId, setFilterStoreTypeId] = useState("");
  const [filterStoreActiveId, setFilterStoreActiveId] = useState("");
  const [StoreFilterData, setStoreFilterData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const res = await getOrganizations();
    const storesResponse = await getStoresApi();
    if (storesResponse.success) {
      let respData = storesResponse.data;
      respData = respData.map((el) => ({
        ...el,
        organizationName: el.organization.name,
      }));

      setStoreFilterData(storesResponse.data);
      setOrganzationData(res.data);
      setStoreData(respData);
      setFilteredData(respData);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  const updateStoreHandle = async (id, storeObj, _name) => {
    if (window.confirm(`Are you sure you want to change?`)) {
      const response = await updateStore(id, storeObj);
      if (response.success) {
        toastSuccessMessage("Status updated successfully");
        await fetchData();
      } else {
        toastErrMessage(response.message);
      }
    }
  };
  const clearFilter = async () => {
    setFilterOrganzationId("");
    setFilterStoreId("");
    setFilterStoreTypeId("");
    setFilterStoreActiveId("");
    setFilterStoreCodeId("");
    filterData();
  };

  const handleStoreSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await postStoresApi({
        name: data.storeName.trim(),
        code: data.storeCode.trim(),
        password: data.password.trim(),
        email: data.email,
        type: data.storeType,
        apiAccess: data.apiAccess,
        apiToken: data.apiToken,
        apiExpiry: data.apiExpiry,
        isEnableNavigation: data.enableNavigation,
        navigationLink: data.navigationLink,
        organizationId: data.organizationId,
      });

      if (response.success) {
        toastSuccessMessage("Store created successfully");
        setStoreModal(false);
        await fetchData();
      } else {
        toastErrMessage(response.message);
      }
    } catch (error) {
      Logger.logError(` Error while creating new store : ${error.message}`);
    }

    setLoading(false);
  };

  const handleEditSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await updateStore(data.storeId, {
        name: data.storeName.trim(),
        code: data.storeCode.trim(),
        type: data.storeType,
        email: data.email,
        apiAccess: data.apiAccess,
        apiToken: data.apiToken,
        apiExpiry: data.apiExpiry,
        isEnableNavigation: data.enableNavigation,
        navigationLink: data.navigationLink,
        organizationId: data.organizationId,
      });
      if (response.success) {
        toastSuccessMessage("Store updated successfully");
        setStoreModal(false);
        setSingleStoreInfo({ data: {}, showEditModal: false });
        await fetchData();
      } else {
        toastErrMessage(response.message);
      }
    } catch (error) {
      Logger.logError(` Error while updating store data: ${error.message}`);
    }

    setLoading(false);
  };
  const handleSearch = (
    filterOrganzationId,
    filterStoreId,
    filterStoreCodeId,
    filterStoreTypeId,
    filterStoreActiveId
  ) => {
    function filterByOrganizationId(arr, organizationId) {
      return arr.filter((item) => item.organizationId == organizationId);
    }

    function filterById(arr, id) {
      return arr.filter((item) => item.id == id);
    }
    function filterByCode(arr, code) {
      return arr.filter((item) => item.id == code);
    }
    function filterByType(arr, type) {
      return arr.filter((item) => item.type == type);
    }

    function filterByIsActive(arr, isActive) {
      return arr.filter((item) => item.isActive == isActive);
    }

    function customFilter(arr, filters) {
      let filteredArray = arr;

      if (filters.organizationId !== "") {
        filteredArray = filterByOrganizationId(
          filteredArray,
          filters.organizationId
        );
      }

      if (filters.id !== "") {
        filteredArray = filterById(filteredArray, filters.id);
      }
      if (filters.code !== "") {
        filteredArray = filterByCode(filteredArray, filters.code);
      }

      if (filters.type !== "") {
        filteredArray = filterByType(filteredArray, filters.type);
      }

      if (filters.isActive !== "") {
        filteredArray = filterByIsActive(filteredArray, filters.isActive);
      }

      return filteredArray;
    }

    const filters = {
      organizationId: filterOrganzationId,
      id: filterStoreId,
      code: filterStoreCodeId,
      type: filterStoreTypeId,
      isActive: filterStoreActiveId,
    };
    setFilteredData(customFilter(StoreFilterData, filters));
  };

  const hideStore = async (id) => {
    setLoading(true);
    const answer = window.confirm(
      "Are you sure you want to delete this store?"
    );
    if (answer) {
      const dbResponse = await updateStore(id, {
        shoudBeHide: true,
        isActive: false,
        apiAccess: false,
        isEnableNavigation: false,
        apiHits: 0,
        apiToken: "",
        navigationLink: "",
      });
      if (dbResponse.success) {
        await fetchData();
      } else {
        toastErrMessage(dbResponse.message);
      }
    }
    setLoading(false);
  };

  function handleApiAccess(store) {
    const { apiToken, id, apiAccess, name } = store;
    apiToken
      ? updateStoreHandle(id, { apiAccess: !apiAccess }, name)
      : toastErrMessage("Please add api token to access api");
  }

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
  };

  const filterData = () => {
    let data = [...storeData];

    data = data.filter((item) => !item.shoudBeHide);

    if (sortKey) {
      data = data.sort((a, b) => {
        let fa = a[sortKey].toLowerCase(),
          fb = b[sortKey].toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    }

    const checkEmpty = (value, computed) =>
      value == "" || value == "all" ? true : computed;
    data = data.filter(
      (el) =>
        checkEmpty(
          storeName,
          el.name.toLowerCase().includes(storeName.toLowerCase())
        ) &&
        checkEmpty(
          storeCode,
          el.code.toLowerCase().includes(storeCode.toLowerCase())
        ) &&
        checkEmpty(storeType, el.type == storeType) &&
        checkEmpty(storeActive, el.isActive == storeActive) &&
        checkEmpty(
          navigationEnabled,
          el.isEnableNavigation === navigationEnabled
        ) &&
        checkEmpty(apiAccess, el.apiAccess === apiAccess) &&
        checkEmpty(
          navigationLink,
          el.navigationLink
            ?.toLowerCase()
            ?.includes(navigationLink.toLowerCase())
        )
    );
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setTotalItems(data.length);
    data = data.slice(startIndex, endIndex);
    setFilteredData(data);
  };

  useEffect(() => {
    filterData();
  }, [
    storeData,
    currentPage,
    sortKey,
    storeName,
    storeCode,
    storeType,
    storeActive,
    navigationEnabled,
    apiAccess,
    navigationLink,
  ]);

  const boolOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];
  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Stores</div>
          <AdminNav />
        </div>
        {!loading && !singleStoreInfo.showEditModal && (
          <CreateAndEditStore
            open={storeOpen}
            setOpen={setStoreModal}
            submit={handleStoreSubmit}
          />
        )}
        {!loading && singleStoreInfo.showEditModal && (
          <CreateAndEditStore
            open={storeOpen}
            setOpen={setStoreModal}
            singleStoreData={singleStoreInfo.data}
            setSingleStore={setSingleStoreInfo}
            isEditModal={true}
            editSubmit={handleEditSubmit}
          />
        )}
        <div className="storeWrapper">
          {loading ? (
            <div>loading...</div>
          ) : filteredData ? (
            <div className="innerTable">
              <div className="adminSearchDropdown">
                <select
                  name="organizationDropDown"
                  onChange={(e) => setFilterOrganzationId(e.target.value)}
                  value={filterOrganzationId}
                  required={true}
                >
                  <option value="">Select Organization</option>
                  {OrganzationData.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <select
                  name="storeNameDropDown"
                  onChange={(e) => setFilterStoreId(e.target.value)}
                  value={filterStoreId}
                  required={true}
                >
                  <option value="">Select StoreName</option>
                  {storeData.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <select
                  name="storeCodeDropDown"
                  onChange={(e) => setFilterStoreCodeId(e.target.value)}
                  value={filterStoreCodeId}
                  required={true}
                >
                  <option value="">Select StoreCode</option>
                  {storeData.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.code}
                    </option>
                  ))}
                </select>
                <select
                  name="storeTypeDropDown"
                  onChange={(e) => setFilterStoreTypeId(e.target.value)}
                  value={filterStoreTypeId}
                  required={true}
                >
                  <option value="">Select StoreType</option>
                  {STORE_TYPES.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <select
                  name="storeActiveDropDown"
                  onChange={(e) => setFilterStoreActiveId(e.target.value)}
                  value={filterStoreActiveId}
                  required={true}
                >
                  <option value="">Select Store Status</option>
                  {isActive.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="adminSearchDropdown-menu-item">
                  <button
                    type="button"
                    onClick={() => {
                      handleSearch(
                        filterOrganzationId,
                        filterStoreId,
                        filterStoreCodeId,
                        filterStoreTypeId,
                        filterStoreActiveId
                      );
                    }}
                  >
                    Search
                  </button>
                  <button type="button" onClick={clearFilter}>
                    Clear
                  </button>
                </div>
              </div>
              <div style={{ paddingBottom: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gridGap: "20px",
                    py: 2,
                  }}
                >
                  <TextField
                    label={"Store Name"}
                    name="storeName"
                    placeholder="admin"
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                    title="Please enter store name"
                  />
                  <TextField
                    label={"Store Code"}
                    name="storeCode"
                    placeholder="Test Store"
                    value={storeCode}
                    onChange={(e) => setStoreCode(e.target.value)}
                    title="Please enter store name"
                  />
                  <TextField
                    label={"Navigation Link"}
                    name="navigationLink"
                    placeholder="https://ecommerce.shopiago.com"
                    value={navigationLink}
                    onChange={(e) => setNavigationLink(e.target.value)}
                    title=""
                  />
                  <Select
                    label={"Store Type"}
                    labelId="apiAccess"
                    name="storeType"
                    value={storeType}
                    setValue={(e) => setStoreType(e.target.value)}
                    options={[{ label: "All", value: "all" }, ...STORE_TYPES]}
                  />
                  <Select
                    label={"Is Active"}
                    labelId="isActive"
                    name="isActive"
                    value={storeActive}
                    setValue={(e) => setStoreActive(e.target.value)}
                    options={boolOptions}
                  />
                  <Select
                    label={"Navigation Enabled"}
                    labelId="enableNavigation"
                    name="enableNavigation"
                    value={navigationEnabled}
                    setValue={(e) => setNavigationEnabled(e.target.value)}
                    options={boolOptions}
                  />
                  <Select
                    label={"Have Api Access"}
                    labelId="apiAccess"
                    name="apiAccess"
                    value={apiAccess}
                    setValue={(e) => setApiAccess(e.target.value)}
                    options={boolOptions}
                  />
                </Box>
                <Button
                  variant="contained"
                  endIcon={<Add />}
                  onClick={() => setStoreModal(true)}
                >
                  Add Store
                </Button>
              </div>
              {filteredData.length > 0 ? (
                <table className="dashboard__tableBorder">
                  <thead>
                    <tr>
                      {tableHeaders.map((head) => (
                        <th
                          className={`dashboard__tableBorder ${
                            head?.filter ? "dashboard__tableBorder--hover" : ""
                          }`}
                          onClick={
                            head?.filter ? () => setSortKey(head.value) : null
                          }
                        >
                          <h1>{head.label}</h1>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((store) => (
                      <tr key={store.id}>
                        <td className="dashboard__tableBorder">
                          <h3>
                            {store.organizationName || store.organization.name}{" "}
                          </h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3>{store.name}</h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3> {store.code}</h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3> {getKeyByValue(STORE_TYPE, store.type)}</h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3>
                            <ToggleButton
                              onToggle={() =>
                                updateStoreHandle(
                                  store.id,
                                  { isActive: !store.isActive },
                                  store.name
                                )
                              }
                              isActive={store.isActive}
                            />
                          </h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3>
                            <ToggleButton
                              onToggle={() =>
                                updateStoreHandle(
                                  store.id,
                                  {
                                    isEnableNavigation:
                                      !store.isEnableNavigation,
                                  },
                                  store.name
                                )
                              }
                              isActive={store.isEnableNavigation}
                            />
                          </h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3>
                            <ToggleButton
                              onToggle={() => handleApiAccess(store)}
                              isActive={store.apiAccess}
                            />
                          </h3>
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              setSingleStoreInfo({
                                data: store,
                                showEditModal: true,
                              });
                              setStoreModal(true);
                            }}
                            style={{ color: "black" }}
                          >
                            <EditIcon />
                          </Button>
                        </td>
                        <td>
                          <Button
                            onClick={() => hideStore(store.id)}
                            style={{ color: "black" }}
                          >
                            {" "}
                            <DeleteIcon />
                          </Button>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3> {store.apiAccess && store.apiToken}</h3>
                        </td>
                        <td className="dashboard__tableBorder">
                          <h3>
                            {store.apiAccess && store.apiExpiry !== "0000-00-00"
                              ? store.apiExpiry
                              : ""}
                          </h3>
                        </td>

                        <td className="dashboard__tableBorder">
                          <h3>
                            <h3>{store.navigationLink}</h3>
                          </h3>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>No store found</div>
              )}
            </div>
          ) : (
            <div>No store found</div>
          )}
          <div className="pagination">
            <CustomPagination
              perPage={itemsPerPage}
              totalPosts={totalItems}
              currentPage={currentPage}
              nextPage={handleNextbtn}
              prevPage={handlePrevbtn}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
export default AdminStores;
