import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { isTokenInValid, amplitudeLog } from "../../utils/utilFunctions";
import ResultPagination from "./ResultPagination";

import "./Dashboard.scss";
import SubmitItem from "../../components/infoPopup/SubmitItem";
import CardPopup from "./CardPopup/CardPopup";
import Banner from "./banner/NoResultBanner";
import Search from "../../assets/images/search.svg";
import { ITEM_STATUS_CODES } from "../../utils/Constants";

const Dashboard = () => {
  const [newItemAdded, setNewItemAdded] = useState(false);
  const [findButtonClicked, setFindButtonClicked] = useState(false); // internal state for submit popup
  const [cardPopup, setCardPopup] = useState(false);
  const [resultsReturned, setResultsReturned] = useState(true);
  const [data, setData] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const [weakDescription, setWeakDescription] = useState(false);
  const [onQuery, setOnQuery] = useState(true);
  const [showBackBtn, setShowBackBtn] = useState(false);

  useEffect(() => {
    setNewItemAdded(false);
  }, [findButtonClicked]);

  useEffect(() => {
    amplitudeLog(
      "Product search",
      "View Product search page ",
      `The Product search page loads for the user`
    ); // groupnme/event name/ message
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
    }
    verifyToken();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const itemData = data[0];
      const havePriceinSomeMarketplace = itemData.marketPlaceItems.some(
        (mktplace) => mktplace.meanPrice != null
      );
      const haveBestMatchPriceinSomeMarketplace =
        itemData.marketPlaceItems.some(
          (mktplace) => mktplace.bestMatchPrice != null
        );
      if (
        data[0].status === ITEM_STATUS_CODES.COMPLETED &&
        !havePriceinSomeMarketplace &&
        haveBestMatchPriceinSomeMarketplace
      ) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    }
  }, [data]);

  const [updateData, setUpdateData] = useState(false);
  return (
    <div className="dashboard">
      <div
        className={`dashboard__container dashboard__containers  ${
          weakDescription && " weak__desciption_set"
        }`}
      >
        <Header
          isAuthenticated={true}
          setOnQuery={setOnQuery}
          showBackBtn={showBackBtn}
          setShowBackBtn={setShowBackBtn}
        />

        <SubmitItem
          itemSubmitted={setNewItemAdded}
          setWeakDescription={setWeakDescription}
          onQuery={onQuery}
          setOnQuery={setOnQuery}
          showBackBtn={showBackBtn}
          setShowBackBtn={setShowBackBtn}
        />
      </div>
      <section className="dashboard__results dashboard__results-width">
        {resultsReturned && (
          <div className="dashboard__headingSec">
            <span className="dashboard_icon">
              <img src={Search} alt="" />
            </span>
            Search results
          </div>
        )}
        {showBanner && (
          <div className="dashboard_banner">
            <Banner
              setShowBanner={setShowBanner}
              data={data?.[0]}
              setUpdateData={setUpdateData}
            />
          </div>
        )}
        <div className="dashboard__tableContainer">
          <div className="searchCardsMain">
            <ResultPagination
              newItemAdded={newItemAdded}
              setNewItemAdded={setNewItemAdded}
              setResultsReturned={setResultsReturned} //
              data={data}
              setData={setData}
              updateData={updateData}
              setUpdateData={setUpdateData}
            />
          </div>
        </div>
        {cardPopup && (
          <CardPopup onClose={() => setCardPopup(false)} popShow={cardPopup} />
        )}
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Dashboard;
