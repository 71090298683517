import propTypes from "prop-types";
import { getFormattedDate } from "../../utils/utilFunctions";
const RequestTimeCell = ({ row }) => {
  RequestTimeCell.propTypes = {
    row: propTypes.shape({
      submittedDateTime: propTypes.instanceOf(Date), // Adjust the prop type accordingly
    }).isRequired,
  };
  return (
    <div>
      {row.submittedDateTime ? getFormattedDate(row.submittedDateTime) : ""}
    </div>
  );
};

export default RequestTimeCell;
