import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import { isTokenInValid } from "../../../utils/utilFunctions";
import {
  getMarketPlacesApi,
  postTestFilterApi,
} from "../../../api/adminDashboardApi";
import { INFO_TYPE } from "../../../utils/Constants";

import "./AdminTestFilter.scss";
import AdminNav from "../../../components/AdminNav/AdminNav";

const AdminTestFilter = () => {
  const [selectedType, setSelectedType] = useState(16);
  const [filterName, setFilterName] = useState("Ghost Word");
  const [userSearch, setUserSearch] = useState("Playstation 3");
  const [listingsJson, setListingsJson] = useState([]);
  const [filterResponse, setFilterResponse] = useState();
  const [userCondition, setUserCondition] = useState("new or never used");
  const [sampleJson, setSampleJson] = useState();
  const [loading, setLoading] = useState(true);
  const [marketPlaceData, setMarketPlaceData] = useState({});
  const [selectedMarketPlaceId, setSelectedMarketPlaceId] = useState(1);

  const conditionTypes = [
    {
      value: "new or never used",
      text: "New or never used",
    },
    {
      value: "used but like new",
      text: "Used but Like New",
    },
    {
      value: "used in worn condition",
      text: "Used in worn condition",
    },
    {
      value: "refurbished",
      text: "Refurbished",
    },
    {
      value: "not working - for parts only",
      text: "Not working - for parts only",
    },
  ];

  const filterTypes = Object.keys(INFO_TYPE).map((key) => {
    return {
      value: key,
      text: INFO_TYPE[key],
    };
  });

  const fetchData = async () => {
    setLoading(true);
    const marketPlaceResponse = await getMarketPlacesApi();
    if (marketPlaceResponse.success) {
      setMarketPlaceData(marketPlaceResponse.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    let listing = [];
    try {
      listing = JSON.parse(listingsJson);
      listing = listing.map((x) => {
        const newObj = {};
        for (let key in x) {
          if (x.hasOwnProperty(key)) {
            newObj[key] = isNaN(x[key]) ? x[key].toLowerCase() : x[key];
          }
        }
        return newObj;
      });
    } catch (error) {
      alert("Incorrect json provided.");
      return;
    }

    let userSearchCleaned = userSearch.toLowerCase().trim();

    const response = await postTestFilterApi(selectedType, {
      marketPlaceId: selectedMarketPlaceId,
      userSearch: userSearchCleaned,
      listing,
      userCondition,
    });
    if (response.success) {
      setFilterResponse(response.data);
    } else {
      alert(response.message);
    }
  };

  const handleFilterChange = (e) => {
    setSelectedType(e.target.value);
    let filterFullName = INFO_TYPE[e.target.value];
    setFilterName(filterFullName);
    setFilterResponse();

    let sampleJSON = {};
    switch (filterFullName) {
      case "All":
        sampleJSON.note =
          "Add title, currency,location, condition, price, ShippingPrice, Listing Type, Description, Date Listed to test all filters :";
        sampleJSON.json = `[
          {
             "title":"playstation 5 with 7 games",
             "currency":"£",
             "location":"uk",
             "condition":"brand new",
             "price":23.47,
             "shippingPrice":13.47,
             "listingType":"bid",
             "description":"playstation 4 with tags",
             "dateListed":"3 week"
          }
       ]`;
        break;
      case "Bad Word":
      case "With And":
      case "Word Anchor":
      case "Ghost Word":
      case "Sanitiser":
        sampleJSON.note =
          "Only add title in each listing to test this filter :";
        sampleJSON.json = `[
          { "title" : "playstation 5 with 7 games" }, 
          { "title" : "playstation 3 plus 2 games bluray" }
]`;
        break;

      case "Currency":
        sampleJSON.note = "Add currency in each listing to test this filter :";
        sampleJSON.json = `[
          {
            "currency" : "£",
            "location": "New York, USA"
          }, 
          {
            "currency" : "$",
            "location": "Toronto, Canada"
          },
          {
            "currency" : "₩",
            "location": "Seoul, Korea"
          },
          {
            "currency" : "£",
            "location": "London, United Kingdom"
          }
]`;
        break;

      case "Location":
        sampleJSON.note = "Add location in each listing to test this filter :";
        sampleJSON.json = `[
            {
              "currency" : "£",
              "location": "uk"
            }, 
            {
              "currency" : "$",
              "location": "UK"
            },
            {
              "currency" : "₩",
              "location": "Seoul, Korea"
            },
            {
              "currency" : "£",
              "location": "London, United Kingdom"
            }
  ]`;
        break;
      case "Condition":
        sampleJSON.note =
          "Only add ebay conditions in each listing to test this filter :";
        sampleJSON.json = `[
          { "condition" : "brand new" }, 
          { "condition" : "new (other)" },
          { "condition" : "pre-owned" },
          { "condition" : "refurbished" },
          { "condition" : "parts only" }
]`;
        break;

      case "Floating Buoys":
        sampleJSON.note =
          "Add ebay condition AND price in each listing to test this filter :";
        sampleJSON.json = `[
          { "condition" : "new or never used", "price" : 23.47 }, 
          { "condition" : "used but like new", "price" : 18.47 },
          { "condition" : "used in worn condition", "price" : 13.74 },
          { "condition" : "refurbished", "price" : 12.43 },
          { "condition" : "not working - for parts only", "price" : 9.42 }
]`;
        break;

      case "Postage Cap":
        sampleJSON.note =
          "Only add shipping price in each listing to test this filter :";
        sampleJSON.json = `[
          { "shippingPrice" : 13.47 }, 
          { "shippingPrice" : 0 },
          { "shippingPrice" : 23.74 },
          { "shippingPrice" : 123.74 }
  ]`;
        break;

      case "Postage Cap Extreme Chop":
        sampleJSON.note =
          "Only add (non-zero) shipping price in each listing to test this filter :";
        sampleJSON.json = `[
          { "shippingPrice" : 13.47 }, 
          { "shippingPrice" : 8.46 },
          { "shippingPrice" : 23.74 },
          { "shippingPrice" : 123.74 }
]`;
        break;

      case "Best Offer":
        sampleJSON.note =
          "Only add listing type in each listing to test this filter :";
        sampleJSON.json = `[
        { "listingType" : "bid" }, 
        { "listingType" : "best offer accepted" },
        { "listingType" : "buy it now" }
]`;
        break;

      case "Extreme Chop Average":
        sampleJSON.note =
          "Only add price in each listing to test this filter :";
        sampleJSON.json = `[
        { "price" : 0.47 }, 
        { "price" : 8.46 },
        { "price" : 13.74 },
        { "price" : 25.43 }
]`;
        break;
      case "Phish Price":
        sampleJSON.note =
          "Only add price in each listing to test this filter :";
        sampleJSON.json = `[
          { "price" : 0 }, 
          { "price" : 8.46 },
          { "price" : 1111 },
          { "price" : 123 }
  ]`;
        break;
      case "Dead Stock":
        sampleJSON.note = "Add date listed and price to test this filter :";
        sampleJSON.json = `[
            { "dateListed":"3 week", "price" : 0 }, 
            { "dateListed":"2 day", "price" : 8.46 },
            { "dateListed":"3 month", "price" : 1111 },
            { "dateListed":"1 year", "price" : 123 }
    ]`;
        break;
      case "Barter":
        sampleJSON.note = "Add description and price to test this filter :";
        sampleJSON.json = `[
              { "description":"playstation 4 send offers", "price" : 0 }, 
              { "description":"xbox one open to offers", "price" : 8.46 },
              { "description":"skull candy crusher", "price" : 1111 },
              { "description":"iphone 8", "price" : 123 }
      ]`;
        break;

      case "Ignore Words":
        sampleJSON.note = "Add title and description to test this filter :";
        sampleJSON.json = `[
                { "title":"playstation 4 with tags", "description":"playstation 4 with tags" }, 
                {"title":"xbox one without tags", "description":"xbox one without tags"},
                {"title":"skull candy ignore headphones bass", "description":"skull candy ignore headphones bass" },
                { "title":"iphone 8 #brandNew", "description":"iphone 8 #brandNew" }
        ]`;
        break;

      case "Calculate Postage":
        sampleJSON.note =
          "Add (non-zero) shipping prices to calculate nearest Royal Mail postage price :";
        sampleJSON.json = `[
                { "shippingPrice" : 20 }, 
                { "shippingPrice" : 25 },
                { "shippingPrice" : 15 },
                { "shippingPrice" : 10 }
]`;
        break;

      default:
        sampleJSON.note = "WARNING: This filter cannot be tested:";
        sampleJSON.json = "";
        break;
    }
    setSampleJson(sampleJSON);
  };

  const handleConditionChange = (e) => {
    setUserCondition(e.target.value);
  };

  return (
    <div className="adminResults dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Test Filter</div>
          <AdminNav />
        </div>

        <div className="testFilterWrapper">
          {["Remove Parts Only", "Kicked By Bad Word"].includes(filterName) && (
            <p className="noteHighlight">Note: {filterName} does not work</p>
          )}
          <form onSubmit={handleFilterSubmit}>
            <div className="searchSelection">
              <span>
                <label htmlFor="selectFilter">Select Filter</label>
                <select value={selectedType} onChange={handleFilterChange}>
                  {filterTypes.map((filterType) => (
                    <option key={filterType.value} value={filterType.value}>
                      {filterType.text.includes("Calculate Postage")
                        ? filterType.text
                        : `${filterType.text} Filter`}
                    </option>
                  ))}
                </select>
              </span>

              {!["Calculate Postage"].includes(filterName) && (
                <span>
                  <label htmlFor="selectMarketplace">Select Marketplace</label>
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    <select
                      value={selectedMarketPlaceId}
                      onChange={(e) =>
                        setSelectedMarketPlaceId(Number(e.target.value))
                      }
                    >
                      {marketPlaceData.map((marketplace) => (
                        <option key={marketplace.id} value={marketplace.id}>
                          {marketplace.name}
                        </option>
                      ))}
                    </select>
                  )}
                </span>
              )}

              {[
                "All",
                "With And",
                "Bad Word",
                "Word Anchor",
                "Ghost Word",
                "Sanitiser",
              ].includes(filterName) && (
                <span>
                  <label htmlFor="userSearch">User Search</label>
                  <input
                    name="userSearch"
                    type="text"
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                  />
                </span>
              )}

              {["All", "Condition", "Floating Buoys", "Bad Word"].includes(
                filterName
              ) && (
                <span>
                  <label htmlFor="userCondition">
                    User Condition: <br />
                  </label>
                  <select
                    name="userCondition"
                    value={userCondition}
                    onChange={handleConditionChange}
                  >
                    {conditionTypes.map((conditionType) => (
                      <option
                        key={conditionType.value}
                        value={conditionType.value}
                      >
                        {conditionType.text}
                      </option>
                    ))}
                  </select>
                </span>
              )}
            </div>

            <label htmlFor="listingJson">Listing JSON:</label>
            <textarea
              value={listingsJson}
              onChange={(e) => setListingsJson(e.target.value)}
            />
            <div className="gap"></div>
            <label className="sampleText">
              Sample listing for{" "}
              {filterName === INFO_TYPE[26]
                ? filterName
                : `${filterName} Filter`}{" "}
              <br />
              {sampleJson
                ? sampleJson.note
                : "Only add title in these sample listings to test this filter :"}
            </label>
            <textarea
              className="sampleText"
              type="text"
              value={
                sampleJson
                  ? sampleJson.json
                  : `[
                {"title":"playstation 5 with 7 games"}, 
                {"title":"playstation 5 plus 2 games bluray"}
]`
              }
              readOnly
            />

            <input type="submit" value="Submit" className="filterSubmit" />
          </form>
          <h2>
            Result After{" "}
            {filterName === INFO_TYPE[26] ? "Calculation" : "Filter"}
          </h2>
          {!Array.isArray(filterResponse) || filterResponse.length > 0 ? (
            <pre className={filterResponse && "resultJson"}>
              {JSON.stringify(filterResponse, null, 4)}
            </pre>
          ) : (
            <div>No data found</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AdminTestFilter;
