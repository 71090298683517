import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  getWordBankApi,
  deleteWordBankApi,
} from "../../../api/adminDashboardApi";
import {
  toastErrMessage,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";
import { PAGE_SIZE } from "../../../utils/Constants";
import { Edit, Delete } from "@mui/icons-material";
import DataTable from "react-data-table-component";
const WordAnchorBankTable = React.memo(function WordAnchorBankTable({
  refreshData,
  handleEditClick,
  marketPlaces,
  displayForm,
}) {
  const [wordBankData, setWordBankData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const fetchData = async (pageSize, pageNumber) => {
    setLoading(true);
    const wordBankResponse = await getWordBankApi(pageSize, pageNumber);
    if (wordBankResponse.success) {
      setWordBankData(wordBankResponse.data.rowsData);
      setTotalRows(wordBankResponse.data.count.totalRows);
    }
    setLoading(false);
  };
  async function handleDeleteClick(event) {
    event.preventDefault();
    const id = event.currentTarget.dataset.id;
    if (window.confirm("Delete for all eternity?")) {
      const response = await deleteWordBankApi(id);
      if (response.success) {
        toastSuccessMessage("Word deleted successfully");
        await fetchData(perPage, currentPage);
      } else {
        toastErrMessage(response.message);
      }
    }
  }
  function handlePageChange(page) {
    setCurrentPage(page);
    fetchData(perPage, page);
  }
  const EditIcon = React.memo(({ word }) => (
    <button
      className="wordIconWrapper"
      disabled={displayForm}
      data-id={word.id}
      data-word={word.word}
      data-marketplaceid={word.marketPlaceId}
      onClick={handleEditClick}
    >
      <Edit sx={{ fontSize: "1.5em" }} />
    </button>
  ));
  const TrashIcon = React.memo(({ id }) => (
    <button
      className="wordIconWrapper"
      disabled={displayForm}
      data-id={id}
      onClick={handleDeleteClick}
    >
      <Delete sx={{ fontSize: "1.5em" }} />
    </button>
  ));
  TrashIcon.propTypes = {
    id: PropTypes.string.isRequired,
  };
  EditIcon.propTypes = {
    word: PropTypes.shape({
      id: PropTypes.string.isRequired,
      word: PropTypes.string.isRequired,
      marketPlaceId: PropTypes.string.isRequired,
    }).isRequired,
  };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      center: true,
    },
    {
      name: "Word",
      selector: (row) => row.word,
      center: true,
      sortable: true,
    },
    {
      name: "Marketplace",
      selector: (row) =>
        marketPlaces.length > 0 &&
        marketPlaces.find((marketPlace) => marketPlace.id === row.marketPlaceId)
          .name,
      sortable: true,
      center: true,
    },
    {
      name: "Edit",
      cell: (row) => <EditIcon word={row} />,
      sortable: true,
      center: true,
    },
    {
      name: "Delete",
      cell: (row) => <TrashIcon id={row.id} />,
      sortable: true,
      center: true,
    },
  ];
  const handlePerRowsChange = useCallback(async (newPerPage, page) => {
    setPerPage(newPerPage);
    await fetchData(newPerPage, page);
  });
  useEffect(() => {
    fetchData(perPage, currentPage);
  }, [refreshData]);
  const customStyles = {
    table: {
      style: {
        borderLeft: "1px solid silver",
        borderRight: "1px solid silver",
      },
    },
    headRow: {
      style: {
        border: "unset",
        minHeight: "unset",
      },
    },
    rows: {
      style: {
        minHeight: "unset",
        "&:not(:last-of-type)": {
          border: "unset",
        },
      },
    },
    headCells: {
      style: {
        padding: "unset",
        fontSize: "1.6rem",
        fontWeight: "800",
        color: "#262626",
      },
    },
    cells: {
      style: {
        padding: "unset",
        color: "#262626",
        fontSize: "1.4rem",
      },
    },
    pagination: {
      style: {
        padding: "0",
        justifyContent: "unset",
      },
    },
  };
  return (
    <DataTable
      columns={columns}
      data={wordBankData}
      fixedHeader={true}
      selectableRows
      progressPending={loading}
      paginationPerPage={perPage}
      pagination
      customStyles={customStyles}
      paginationServer
      paginationTotalRows={totalRows}
      paginationRowsPerPageOptions={[...new Set(Object.values(PAGE_SIZE))]}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
    />
  );
});
WordAnchorBankTable.propTypes = {
  refreshData: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  marketPlaces: PropTypes.array.isRequired,
  displayForm: PropTypes.bool.isRequired,
};
export default WordAnchorBankTable;
