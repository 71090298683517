import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FaAngleDown } from "react-icons/fa";
import PropTypes from "prop-types";
import "./BasicCard.scss";
import { marketName } from "../../utils/Constants";

import EbayWhite from "../../assets/images/ebay_white.png";
import EbayLogo from "../../assets/images/ebaylogo.png";
import DepopWhite from "../../assets/images/depop_white.png";
import DepopLogo from "../../assets/images/depoplogo.png";
import FacebookWhite from "../../assets/images/facebook_white@2x.png";
import FacebookMarketplace from "../../assets/images/facebookMarketplace.png";

export default function BasicCard({
  marketPlace,
  listAt,
  price,
  maxMarket,
  totalProfit,
  recentListings,
  mbl,
}) {
  const [ani, setAni] = useState(false);
  const profit = maxMarket === marketPlace ? true : false;
  let back,
    priceColor,
    cardWidth,
    cardHeight,
    textAlignment,
    margTop,
    margBottom,
    cardTopMarg,
    margBottomListAt,
    margTopListAt,
    margTopPrice,
    shadow,
    logoWidth,
    logoHeight,
    textAlignmentRight,
    boxRadius,
    boxbottom;

  if (marketPlace === "Ebay") {
    logoWidth = "47.21px";
    logoHeight = "20px";
  } else if (marketPlace === "Depop") {
    logoWidth = "67px";
    logoHeight = "19px";
  } else if (marketPlace === marketName.FACEBOOK) {
    logoWidth = "149px";
    logoHeight = "30px";
  }

  if (profit) {
    back =
      "transparent linear-gradient(179deg, #FFA94C 0%, #EA4350 100%) 0% 0% no-repeat padding-box;";
    priceColor = "white";
    shadow = "2px 3px 12px #FA7C89B8;";
    boxRadius = "12px";
  } else {
    back = "white";
    shadow = mbl ? "none" : "";
    priceColor = "black";
    boxRadius = mbl ? "0px" : "12px";
    boxbottom = "1px solid #e2e7f6";
  }
  if (mbl) {
    cardWidth = "327px";
    cardHeight = "100px";
    cardTopMarg = "15px";
    textAlignment = "left";
    margBottomListAt = 17;
    margTopListAt = -6;
    margTopPrice = -17;
    textAlignmentRight = "Right";
  } else {
    if (ani) {
      cardHeight = "130px";
      margTop = 0;
      margBottom = 8;
      margTopPrice = -2;
      margTopListAt = marketPlace === "Depop" ? -9 : -8;
    } else {
      cardHeight = "187px";
      margTop = 2.5;
      margBottom = 12.5;
      margTopPrice = 1;
      margTopListAt = marketPlace === "Depop" ? -9 : -10;
    }
    cardWidth = "169px";
    textAlignment = "center";
    textAlignmentRight = "center";
  }

  const onEnter = () => {
    if (profit && (mbl || !mbl)) {
      setAni(true);
    }
  };
  const onLeave = () => {
    if (profit && (mbl || !mbl)) {
      setAni(false);
    }
  };

  let result;
  if (mbl) {
    result = listAt;
  } else if (ani) {
    result = "";
  } else {
    result = listAt;
  }

  let mt;

  if (mbl) {
    if (ani) {
      mt = -17;
    } else {
      mt = margTopPrice;
    }
  } else {
    mt = margTopPrice;
  }

  BasicCard.propTypes = {
    marketPlace: PropTypes.string.isRequired,
    listAt: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    maxMarket: PropTypes.number.isRequired,
    totalProfit: PropTypes.number.isRequired,
    recentListings: PropTypes.array.isRequired,
    mbl: PropTypes.string.isRequired,
  };

  let logoSrc = "";
  if (marketPlace === "Ebay") {
    logoSrc = profit ? EbayWhite : EbayLogo;
  } else if (marketPlace === "Depop") {
    logoSrc = profit ? DepopWhite : DepopLogo;
  } else if (marketPlace === "Facebook") {
    logoSrc = profit ? FacebookWhite : FacebookMarketplace;
  }

  return (
    <div
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      className="card-main-div"
    >
      <Card
        sx={{
          width: cardWidth,
          height: cardHeight,
          borderRadius: boxRadius,
          marginBottom: profit ? cardTopMarg : null,
          background: back,
          boxShadow: shadow,
          borderBottom: boxbottom,
          ml: 5,
          "&:hover":
            maxMarket === marketPlace
              ? {
                  cursor: "pointer",
                }
              : "",
        }}
      >
        <CardContent>
          {profit ? (
            <Typography
              sx={{
                fontSize: "10px",
                textAlign: textAlignment,
                color: "#FFFFFF",
                fontWeight: "bold",
                opacity: "70%",
                // mt: ani ? -2 : null,
              }}
              gutterBottom
            >
              MOST PROFITABLE
            </Typography>
          ) : (
            <div style={{ paddingTop: 10 }} />
          )}

          <Typography
            sx={{
              fontSize: "14px",
              mb: margBottom,
              mt: margTop,
              textAlign: textAlignment,
              color: `${price} ? 'white' : 'black'`,
            }}
            gutterBottom
          >
            {
              <img
                src={logoSrc}
                alt="THRIFT"
                width={logoWidth}
                height={logoHeight}
              />
            }
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontSize: "12px",
              mb: margBottomListAt,
              mt: margTopListAt,
              textAlign: textAlignmentRight,
              color: price ? "#ffffff" : "#767676",
              fontWeight: profit ? "bold" : "normal",
              opacity: price ? "70%" : "",
              mr: mbl ? 0 : "",
            }}
          >
            {result}
          </Typography>
          {price ? (
            <Typography
              sx={{
                textAlign: textAlignmentRight,
                fontSize: "24px",
                mt: mt,
                fontWeight: profit ? "800" : "500",
                fontFamily: "Nunito Sans",
                color: profit ? priceColor : "#262626",
              }}
            >
              £{price}
            </Typography>
          ) : (
            <Typography
              sx={{
                textAlign: textAlignmentRight,
                fontSize: "24px",
                mt: margTopPrice,
                fontWeight: "500",
                fontFamily: "Nunito Sans",
                color: "#262626",
              }}
            >
              {price ? `£${price}` : "-"}
            </Typography>
          )}
          {profit && (
            <Typography
              sx={{
                textAlign: "center",
                fontSize: 15,
                color: "white",
                mt: 0,
              }}
            >
              <span
                onClick={mbl ? () => setAni(!ani) : null}
                onKeyDown={(e) => {
                  if (mbl && (e.key === "Enter" || e.key === " ")) {
                    setAni(!ani);
                  }
                }}
              >
                <FaAngleDown />
              </span>
            </Typography>
          )}
        </CardContent>
      </Card>

      {mbl ? <div style={{ marginBottom: "10px" }} /> : null}
      {ani &&
        (mbl ? (
          <div className="animateClassMbl">
            <div className="animateClassMblLeft">
              <div className="animateClassMblLeftText">
                THRIFT has reviewed
                <span style={{ fontWeight: "bold" }}>
                  <br />
                  {recentListings} recent listings
                </span>
              </div>
            </div>
            <div className="animateClassMblRight">
              <div className="animateClassMblRightText">
                Including listing fees your profit would be{" "}
                <span style={{ fontWeight: "bold" }}>£{totalProfit}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="animateClass">
            <div className="animateClassTop">
              THRIFT has reviewed{" "}
              <span style={{ fontWeight: "bold" }}>
                {recentListings} recent listings
              </span>{" "}
            </div>
            <hr className="below-line" />
            {/* <div className="animateClassBelow" > */}
            Including listing fees your profit would be{" "}
            <span style={{ fontWeight: "bold" }}>£{totalProfit}</span>
          </div>
          // </div>
        ))}
    </div>
  );
}
