import React, { useState, useEffect, lazy, Suspense } from "react";
import propTypes from "prop-types";
import { Sync } from "@mui/icons-material";
import groupBy from "lodash.groupby";
import DataTable from "react-data-table-component";
import MarketPlaceExpandableComponent from "../MarketPlaceExpandableComponent/MarketPlaceExpandableComponent";
import {
  getStoresApi,
  getVersionApi,
  getOrganizations,
} from "../../api/adminDashboardApi";
import { PAGE_SIZE, STORE_TYPE } from "../../utils/Constants";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CopyText } from "../../components/copyContent/copyContent";
import { getTrimmedText, getWindowSize } from "../../utils/utilFunctions";

import RouteNames from "../../routes/RouteNames";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ReactDataTable.scss";

const ExportExcel = lazy(() => import("../ExportExcel/ExportExcel"));

const ReactDataTable = ({
  viewName = "",
  columns,
  customStyles,
  api,
  updateTable = true,
  sorting = false,
  defaultSortBy = "id",
  sortOrder = "desc",
  selectorFunctionToSelectorNameMapping = [],
  filtering = false,
  filterByOptions = [],
  conditionalRowStyles = [],
  showPageSizeChangeOption = false,
  customProps = {},
  exportData = false,
}) => {
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortField, setSortField] = useState(defaultSortBy);
  const [sortDirection, setSortDirection] = useState(sortOrder);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterStoreId, setFilterStoreId] = useState("");
  const [filterVersionId, setFilterVersionId] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchBy, setSearchBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [stores, setStores] = useState([]);
  const [versions, setVersions] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [versionId, setVersionId] = useState("");
  const [reRun, setReRun] = useState(false);
  const [conditionId, setConditionId] = useState();
  const [toggleCleared, setToggleCleared] = useState(false);
  const [storeType, setStoreType] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterOrganizationId, setFilterOrganizationId] = useState("");
  const [organzationData, setOrganzationData] = useState([]);
  const [organisationId, setOrganisationId] = useState("");
  const storeTypesArray = [
    { id: STORE_TYPE.ADMIN, name: "Admin" },
    { id: STORE_TYPE.TEST, name: "Test" },
    { id: STORE_TYPE.BHF, name: "BHF" },
    { id: STORE_TYPE.STANDARD, name: "Standard" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const groupResultData = (results) => {
    const groupedData = groupBy(results, "id");
    const formattedData = [];
    for (const [key, value] of Object.entries(groupedData)) {
      let currentResult = {};
      value.forEach((marketplace) => {
        if (
          currentResult &&
          Object.keys(currentResult).length === 0 &&
          currentResult.constructor === Object
        ) {
          currentResult.id = key;
          currentResult.store = marketplace.store;
          currentResult.organisationName = marketplace.organisation;
          currentResult.organisationId = marketplace.organisationId;
          currentResult.storeId = marketplace.storeId;
          const getTitleContent = () => {
            const title = marketplace?.title;
            return title ? processTitle(title) : "";
          };

          const processTitle = (title) =>
            title.length > 15
              ? getTrimmedText(title, getWindowSize(), 12, 10, 10) + "..."
              : title;

          currentResult.strength = (
            <a
              className="title_a"
              rel="noopener noreferrer"
              target="_blank"
              href={RouteNames.Admin.Strength.route
                .replace(":strengthId", key)
                .replace(":query", marketplace.title)}
            >
              {marketplace.strength}
            </a>
          );

          currentResult.title = (
            <Tooltip
              title={
                <div style={{ display: "flex" }}>
                  <CopyText content={marketplace.title} />
                  <Typography fontSize={20}>{marketplace.title}</Typography>
                </div>
              }
              placement="top"
            >
              <a
                className="title_a"
                rel="noopener noreferrer"
                target="_blank"
                href={`${RouteNames.UserSearchResult.route}/${key} `}
              >
                {getTitleContent()}
              </a>
            </Tooltip>
          );
          currentResult.barcode = marketplace.barcode;
          currentResult.conditionId = marketplace.conditionId;
          currentResult.conditionName = marketplace.conditionName;
          currentResult.createdAt = marketplace.createdAt;
          currentResult.disputeStatus = marketplace.disputeStatus;
          currentResult.itemsBeforeFiltering = marketplace.itemsBeforeFiltering;
          currentResult.itemsAfterFiltering = marketplace.itemsAfterFiltering;
          currentResult.status = marketplace.status;
          currentResult.type = marketplace.type;
          currentResult.uniqueCode = marketplace.uniqueCode;
          currentResult.removeListing = marketplace.removeListing;
          currentResult.triggerBestMatch = marketplace.triggerBestMatch;
          currentResult.submittedTime = marketplace.submittedDateTime;
          currentResult.responseTime = marketplace.responseDateTime;
          currentResult.brightResponseTime = marketplace.brightResponseTime;
          currentResult.filterResponseTime = marketplace.filterResponseTime;
          currentResult.totalResponseTime = marketplace.totalResponseTime;
          currentResult.timeStampDiff = marketplace.responseTime;
          currentResult.brightDataResponse = marketplace.brightDataResponse;
          currentResult.strength = (
            <a
              className="title_a"
              rel="noopener noreferrer"
              target="_blank"
              href={RouteNames.Admin.Strength.route
                .replace(":strengthId", key)
                .replace(":query", marketplace.title)}
            >
              {marketplace.strength}
            </a>
          );

          currentResult.marketPlaceItems = [];
        }
        currentResult.marketPlaceItems.push({
          marketPlaceItemId: marketplace.marketPlaceItemId,
          marketPlaceName: marketplace.marketPlaceName,
          apiId: marketplace.apiId,
          detailStatus: marketplace.detailStatus,
          itemsBeforeFiltering: marketplace.itemsBeforeFiltering,
          itemsAfterFiltering: marketplace.itemsAfterFiltering,
          highestPrice: marketplace.highestPrice,
          meanPrice: marketplace.meanPrice,
          lowestPrice: marketplace.lowestPrice,
          nonAdjustedPrice: marketplace.nonAdjustedPrice,
          profit: marketplace.profit,
          isActiveListing: marketplace.isActiveListing,
          responseTime: marketplace.responseTime,
          brightResponseTime: marketplace.brightResponseTime,
          filterResponseTime: marketplace.filterResponseTime,
          totalResponseTime: marketplace.totalResponseTime,
          responseDateTime: marketplace.responseDateTime,
          uniqueCode: marketplace.uniqueCode,
          triggerBestMatch: marketplace.triggerBestMatch,
          removeListing: marketplace.removeListing,
          submittedDateTime: marketplace.submittedDateTime,
          brightDataResponse: marketplace.brightDataResponse,
          organisationName: marketplace.organisation,
          organisationId: marketplace.organisationId,
        });
      });
      formattedData.push(currentResult);
      currentResult = {};
    }

    formattedData.sort((a, b) => b.id - a.id);
    return formattedData;
  };
  const fetchData = async ({
    page,
    pageSize,
    sortBy,
    sortByDirection,
    searchByType,
    searchValue,
    selectedStore,
    selectedVersion,
    selectedStoreTypeId,
    startDate,
    endDate,
    organisationId,
  }) => {
    setLoading(true);
    const response = await api(
      page,
      pageSize,
      sortBy,
      sortByDirection,
      searchByType,
      searchValue,
      selectedStore,
      selectedVersion,
      selectedStoreTypeId,
      startDate,
      endDate,
      organisationId
    );
    if (response.success) {
      let searchResults = response.data.results;
      if (viewName === "AdminResults") {
        setRawData(searchResults);
        const groupedData = groupResultData(searchResults);
        setData(groupedData);
      } else {
        setData(searchResults);
      }
      setTotalRows(response.data.count);
    }
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData({
      page,
      pageSize: perPage,
      sortBy: sortField,
      sortByDirection: sortDirection,
      searchByType: searchBy,
      searchValue: searchText,
      selectedStore: storeId,
      selectedVersion: versionId,
      selectedStoreTypeId: storeType,
      startDate,
      endDate,
      organisationId,
    });
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setToggleCleared(!toggleCleared);
    setPerPage(newPerPage);

    await fetchData({});
  };
  useEffect(async () => {
    if (updateTable)
      fetchData({
        page: currentPage,
        pageSize: perPage,
        sortBy: sortField,
        sortByDirection: sortDirection,
        searchByType: searchBy,
        searchValue: searchText,
        selectedStore: storeId,
        selectedVersion: versionId,
        selectedStoreTypeId: storeType,
        startDate,
        endDate,
        organisationId,
      });
    const res = await getOrganizations();
    setOrganzationData(res.data);
  }, [updateTable]);
  const handleSort = async (column, sortDirectionBy) => {
    let columnSelector = column.selector;
    const selectorFound = selectorFunctionToSelectorNameMapping.find(
      (cs) => cs.name === column.name
    );
    if (selectorFound) {
      columnSelector = selectorFound.selector;
    }

    setSortField(columnSelector);
    setSortDirection(sortDirectionBy);

    await fetchData({
      page: currentPage,
      pageSize: perPage,
      sortBy: columnSelector,
      sortByDirection: sortDirectionBy,
      searchByType: searchBy,
      searchValue: searchText,
      selectedStore: storeId,
      selectedVersion: versionId,
      selectedStoreTypeId: storeType,
      startDate,
      endDate,
      organisationId,
    });
  };
  const handleSearch = async ({
    searchByType,
    searchValue,
    selectedStore,
    selectedVersion,
    selectedStoreTypeId,
    startDate,
    endDate,
    selectOrganization,
    clearSearch,
  }) => {
    if (
      (searchByType && searchValue) ||
      selectedStore ||
      selectedVersion ||
      clearSearch ||
      selectedStoreTypeId ||
      startDate ||
      endDate ||
      selectOrganization
    ) {
      setSearchBy(searchByType);
      setSearchText(searchValue);
      setStoreId(selectedStore);
      setOrganisationId(selectOrganization);
      setVersionId(selectedVersion);
      setStoreType(selectedStoreTypeId);
      setResetPaginationToggle(!resetPaginationToggle);

      await fetchData({
        page: currentPage,
        pageSize: perPage,
        sortBy: sortField,
        sortByDirection: sortDirection,
        searchByType,
        searchValue,
        selectedStore,
        selectedVersion,
        selectedStoreTypeId,
        startDate,
        endDate,
        organisationId: selectOrganization,
      });
    }
  };
  const clearFilter = async () => {
    setFilterText("");
    setFilterBy("");
    setFilterStoreId("");
    setFilterOrganizationId("");
    setFilterVersionId("");
    setStoreType("");
    setStartDate(null);
    setEndDate(null);
    if (searchText || storeId || versionId || storeType || organisationId) {
      await handleSearch({
        searchByType: "",
        searchValue: "",
        selectedStore: "",
        selectedVersion: "",
        selectedStoreTypeId: "",
        startDate: "",
        endDate: "",
        selectOrganization: "",
        clearSearch: true,
      });
    }
  };
  const filterByChanged = (value) => {
    setFilterBy(value);
  };
  const onRerunClick = async () => {
    if (
      window.confirm(
        `Are you sure you want to Re-Test selected data?\r${
          conditionId ? "You have selected different condition as well." : ""
        }`
      )
    ) {
      setLoading(true);
      const { submitRerunApi } = customProps;
      const response = await submitRerunApi(
        selectedRows.reverse(),
        conditionId
      );

      if (response.success) {
        setToggleCleared(!toggleCleared);
        fetchData({
          page: currentPage,
          pageSize: perPage,
          sortBy: sortField,
          sortByDirection: sortDirection,
          searchByType: searchBy,
          searchValue: searchText,
          selectedStore: storeId,
          selectedStoreTypeId: storeType,
          startDate,
          endDate,
          organisationId,
        });
      }
    }
  };
  const getConditions = () => {
    const { conditions } = customProps;
    return conditions;
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className="adminSearchDropdown">
        <div className="rerun">
          {reRun && (
            <>
              <div style={{ marginLeft: 18, marginBottom: 6 }}>
                <span style={{ fontSize: 14 }}>
                  {" "}
                  Rerun Test Data {`${" "}`}
                </span>
                <Sync
                  sx={{ fontSize: "1.5em", cursor: "pointer" }}
                  onClick={onRerunClick}
                />
              </div>
              <div>
                <select
                  name="searchBy"
                  onChange={(e) => setConditionId(e.target.value)}
                  value={conditionId}
                  required={true}
                >
                  <option value="">Select New Condition</option>
                  {getConditions().map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </div>
        <div className="datepicker">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText={"Start date"}
            maxDate={new Date()}
            dateFormat={"dd/MM/yyyy"}
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat={"dd/MM/yyyy"}
            placeholderText={"End date"}
          />
        </div>
        <div className="adminSearchDropdown-menu-item">
          <select
            name="storeTypeDropDown"
            onChange={(e) => setStoreType(e.target.value)}
            value={storeType}
            required={true}
            className="adminSearchDropdown-menu-item"
          >
            <option value="">Select Account Type</option>
            {storeTypesArray.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="adminSearchDropdown-menu-item">
          <select
            name="versionDropDown"
            onChange={(e) => setFilterVersionId(e.target.value)}
            value={filterVersionId}
            required={true}
          >
            <option value="">Select Version</option>
            {versions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="adminSearchDropdown-menu-item">
          <select
            name="organizationDropDown"
            onChange={(e) => setFilterOrganizationId(e.target.value)}
            value={filterOrganizationId}
            required={true}
          >
            <option value="">All organisations</option>
            {organzationData.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="adminSearchDropdown-menu-item">
          <select
            name="storeDropDown"
            onChange={(e) => setFilterStoreId(e.target.value)}
            value={filterStoreId}
            required={true}
          >
            <option value="">Select Store</option>
            {stores.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
        <div className="adminSearchDropdown-menu-item">
          <select
            name="searchBy"
            onChange={(e) => filterByChanged(e.target.value)}
            value={filterBy}
            required={true}
          >
            <option value="">Search By</option>
            {filterByOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div className="adminSearchDropdown-menu-item">
          <input
            id="search"
            type="text"
            placeholder="Search Term"
            name="searchValue"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className="adminSearchDropdown-menu-item">
          <button
            type="button"
            onClick={() => {
              handleSearch({
                searchByType: filterBy,
                searchValue: filterText,
                selectedStore: filterStoreId,
                selectedVersion: filterVersionId,
                selectedStoreTypeId: storeType,
                startDate: startDate,
                endDate: endDate,
                selectOrganization: filterOrganizationId,
              });
            }}
          >
            Search
          </button>
          <button type="button" onClick={clearFilter}>
            Clear
          </button>
        </div>
      </div>
    );
  }, [
    reRun,
    stores,
    versions,
    filterStoreId,
    filterVersionId,
    filterText,
    storeType,
    resetPaginationToggle,
    filterBy,
    startDate,
    endDate,
    filterOrganizationId,
    conditionId,
  ]);
  const getSubHeaderDropDownsData = async () => {
    const storeResponse = await getStoresApi();
    const filteredArray = storeResponse?.data.filter(
      (item) => item.organizationId == filterOrganizationId
    );
    if (storeResponse.success) {
      const storeData = filterOrganizationId
        ? filteredArray
        : storeResponse.data;
      setStores(storeData);
    }

    const versionResponse = await getVersionApi();
    if (versionResponse.success) {
      setVersions(versionResponse.data);
    }
  };
  useEffect(() => {
    if (filtering) getSubHeaderDropDownsData();
  }, [filtering, filterOrganizationId]);
  const handleRowSelected = React.useCallback((state) => {
    setReRun(state.selectedCount > 0);
    setConditionId((_conditionId) =>
      state.selectedCount === 0 ? "" : _conditionId
    );
    setSelectedRows(
      state.selectedRows.map((x) => {
        return {
          title: x.title,
          condition: x.conditionId,
          barcode: x.barcode,
          storeId: x.storeId,
        };
      })
    );
  }, []);

  // function for tilte clicked
  return (
    <>
      {exportData ? (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="exportButton">
            <ExportExcel rawData={rawData} data={data} />
          </div>
        </Suspense>
      ) : (
        ""
      )}

      <DataTable
        className="react-data-table"
        noHeader={true}
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationRowsPerPageOptions={[...new Set(Object.values(PAGE_SIZE))]}
        onChangePage={handlePageChange}
        customStyles={customStyles}
        sortServer={sorting}
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationComponentOptions={{
          rowsPerPageText: false,
          noRowsPerPage: !showPageSizeChangeOption,
        }}
        onSort={handleSort}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader={filtering}
        subHeaderComponent={subHeaderComponentMemo}
        fixedHeader={true}
        persistTableHead
        conditionalRowStyles={conditionalRowStyles}
        onSelectedRowsChange={handleRowSelected}
        {...customProps}
        onRowExpandToggled={onRowExpandToggled}
        clearSelectedRows={toggleCleared}
        expandableRowsComponent={(rowData) => (
          <MarketPlaceExpandableComponent
            data={rowData.data}
            customStyles={customStyles}
          />
        )}
      />
    </>
  );
};

const onRowExpandToggled = (state, a, b) => {
  //  const
};
ReactDataTable.propTypes = {
  viewName: propTypes.string,
  columns: propTypes.array.isRequired,
  customStyles: propTypes.object,
  api: propTypes.func.isRequired,
  updateTable: propTypes.bool,
  sorting: propTypes.bool,
  defaultSortBy: propTypes.string,
  sortOrder: propTypes.string,
  selectorFunctionToSelectorNameMapping: propTypes.array,
  filtering: propTypes.bool,
  filterByOptions: propTypes.array,
  conditionalRowStyles: propTypes.array,
  showPageSizeChangeOption: propTypes.bool,
  customProps: propTypes.object,
  exportData: propTypes.bool,
};

export default ReactDataTable;
