import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import StrengthBar from "../CategoryBar/StrengthBar";
import ConditionCard from "../../components/ConditionCard/ConditionCard";
import SearchCard from "../../components/SearchCard/SearchCard";
import { CATEGORY_TYPE } from "../../utils/Constants";
import "./SubmitItem.scss";
import SearchField from "../SearchText/SearchField";
import SearchAlert from "../../components/SearchAlert/SearchAlert";
import Brand from "../../assets/images/brand.svg";
import Model from "../../assets/images/model.svg";
import ItemType from "../../assets/images/item-type.svg";
import Material from "../../assets/images/material.svg";
import Size from "../../assets/images/size.svg";
import Quantity from "../../assets/images/quantity.svg";
import ArrowBack from "../../assets/images/arrow-back.svg";
import Alert from "../../assets/images/alert.svg";
import {
  toastErrMessage,
  amplitudeLog,
  getStatusLabel,
} from "../../utils/utilFunctions";
import {
  postItemApi,
  getQueryStrenght,
  getByIdItemApi,
} from "../../api/dashboardApi";
import debounce from "lodash.debounce";
const StrengthItemCard = ({ icon, title, example, categoryTypeId, bars }) => {
  const barMatch = bars.findIndex(
    (bar) => bar.categoryTypeId === categoryTypeId && bar.found
  );

  const ifMatch = barMatch !== -1;
  StrengthItemCard.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.string,
    example: PropTypes.string,
    categoryTypeId: PropTypes.any,
    bars: PropTypes.any,
  };
  return (
    <div
      className={`strength__card__container ${
        ifMatch && " strength__is__selected"
      }`}
    >
      <div className="strength__item_image">
        <img src={icon} alt={icon} />
      </div>
      <div className="strength__card_text">
        <div>{title}</div>
        <div>{example}</div>
      </div>
    </div>
  );
};
const storeId = localStorage.getItem("storeId");

const strengthItems = [
  {
    icon: Brand,
    title: "Brand",
    example: "eg. Apple, Nike, Next",
    categoryTypeId: CATEGORY_TYPE.BRAND,
  },
  {
    icon: Model,
    title: "Model",
    example: "eg. iPhone 12, AirMax, chino",
    categoryTypeId: CATEGORY_TYPE.MODEL,
  },
  {
    icon: ItemType,
    title: "Item Type",
    example: "eg. Toaster",
    categoryTypeId: CATEGORY_TYPE.ITEM_CATEGORY,
  },
  {
    icon: Material,
    title: "Material",
    example: "eg. leather, wood, cotton",
    categoryTypeId: CATEGORY_TYPE.MATERIAL,
  },
  {
    icon: Size,
    title: "Size",
    example: "eg. large, size 12, 64GB",
    categoryTypeId: CATEGORY_TYPE.SIZE,
  },
  {
    icon: Quantity,
    title: "Quantity",
    example: "eg. x2, pair of, bundle",
    categoryTypeId: CATEGORY_TYPE.QUANTITY,
  },
];
let initialConditions = [
  {
    id: 1,
    title: "New or Never Used",
    details: "Brand new, unused, or unworn, with packaging or with tags.",
    selected: false,
  },
  {
    id: 3,
    title: "Used but like new",
    details:
      "Used with minimal signs of use. May be missing packaging, is no longer sealed or without tags.",
    selected: false,
  },
  {
    id: 5,
    title: "Used",
    details:
      "Previously used or worn. Some signs of wear, scratches, scuffs or dents.",
    selected: false,
  },
  {
    id: 7,
    title: "Refurbished",
    details: "Professionally refurbed, in working order with slight wear.",
    selected: false,
  },
  {
    id: 9,
    title: "Parts only",
    details: "Not working, but individual parts can be used.",
    selected: false,
  },
];

const StrengthHelpDetials = ({ bars }) => {
  StrengthHelpDetials.propTypes = {
    bars: PropTypes.number.isRequired,
  };
  return (
    <div className="strentg__help__container">
      <div className="strength__item__container">
        {strengthItems.map((strengthItem, index) => {
          return (
            <StrengthItemCard index={index} {...strengthItem} bars={bars} />
          );
        })}
      </div>
    </div>
  );
};

const WeakConditionSelect = () => {
  return (
    <div className="weak__description__alert">
      <img src={Alert} className="weak__description__alert__image" alt="" />
      <div>
        <div className="weak__description__alert__title">
          This is a weak description
        </div>
        <div className="weak__description__alert__details">
          What else do you know about the item? Try to include at least 3 of
          these
        </div>
      </div>
    </div>
  );
};
const ConditionSelect = ({
  setOnQuery,
  onFindSubmit,
  onceClicked,
  setShowBackbtn,
  shouldDisplayConditions,
}) => {
  const [conditions, setConditions] = useState(initialConditions);

  const conditionCardClickHandle = (conditionDetail) => {
    setConditions((prevConditions) => {
      const index = prevConditions.findIndex(
        (prevCondition) => prevCondition.id === conditionDetail.id
      );
      prevConditions = prevConditions.map((prevCondition, id) => {
        prevCondition.selected = index === id;

        return prevCondition;
      });
      return prevConditions;
    });
  };
  const someConditionSelected = () =>
    conditions.findIndex((condition) => condition.selected === true) !== -1;

  const selectedCondition = conditions.find(
    (condition) => condition.selected === true
  );
  useEffect(() => {
    initialConditions.forEach((x) => {
      if (x.selected) {
        x.selected = false;
      }
    });
  }, []);

  ConditionSelect.propTypes = {
    setOnQuery: PropTypes.func.isRequired,
    onFindSubmit: PropTypes.func,
    onceClicked: PropTypes.bool,
    setShowBackbtn: PropTypes.func,
    shouldDisplayConditions: PropTypes.bool,
  };
  return (
    <div className="condition__container">
      <div className="condition__header">
        <div className="dashboard__heading Condition__heading">
          How would you describe its condition?
        </div>
        <div className="dashboard__subHeading  Condition__subHeading">
          Choose a condition from below, which closely matches your item.
        </div>
      </div>
      <div className="condition__cards__container-main-div">
        {shouldDisplayConditions ? (
          <button
            className="arrow__back__icon"
            onClick={() => setOnQuery(true)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setOnQuery(true);
              }
            }}
          >
            <img src={ArrowBack} alt="" />
          </button>
        ) : (
          ""
        )}

        <div className="condition__cards__container">
          {shouldDisplayConditions ? (
            <>
              {" "}
              {conditions.map((conditionDetail) => {
                return (
                  <ConditionCard
                    {...conditionDetail}
                    oneSelected={someConditionSelected()}
                    onClick={() => conditionCardClickHandle(conditionDetail)}
                  />
                );
              })}{" "}
              <SearchCard
                conditionSelected={someConditionSelected()}
                onFindClick={() => {
                  setConditions((prevConditon) => {
                    return prevConditon.map((conditionItem) => {
                      conditionItem.selected = false;
                      return conditionItem;
                    });
                  });
                  onFindSubmit(selectedCondition.id);
                }}
                onceClicked={onceClicked}
                setShowBackbtn={setShowBackbtn}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const SubmitItem = ({
  itemSubmitted,
  setWeakDescription,
  onQuery,
  setOnQuery,
  setShowBackbtn,
}) => {
  const [showExamples, setShowExamples] = useState(false);
  const [query, setQuery] = useState("");
  const [bars, setBars] = useState([]);
  const [barCode, setBarCode] = useState(false);
  const [tipsOpened, setTipsOpened] = useState(false);
  const [onceClicked, setOnceClicked] = useState(false);
  const [shouldDisplayConditions, setShouldDisplayConditions] = useState(true);

  const showhandle = (e) => {
    showExamples
      ? amplitudeLog("Product search", " Hide tips", "", { storeId: storeId })
      : amplitudeLog("Product search", " Show tips", "");
    setShowExamples(!showExamples);
  };

  const requestComplete = () => {
    setQuery("");
    setOnQuery(true);
    setBarCode("");
    itemSubmitted(true);
    setShowExamples(false);
    setOnceClicked(false);
    setShouldDisplayConditions(true);
  };
  const getBarsCount = () => bars.filter((bar) => bar.found === true).length;

  const submitHandle = () => {
    if (barCode) {
      if (query.length > 15 || query.length < 10) {
        toastErrMessage("Length should be 10 to 15 in barcode");
      } else {
        if (getBarsCount() < 2 && !onceClicked && !barCode) {
          setOnceClicked(true);
        } else {
          setOnQuery(false);
        }
      }
    } else {
      if (query.length > 300 || query.length < 3) {
        toastErrMessage("Length should be 3 to 300 in description");
      } else {
        if (getBarsCount() < 2 && !onceClicked && !barCode) {
          setOnceClicked(true);
        } else {
          setOnQuery(false);
        }
      }
    }
  };

  const onFindSubmit = async (conditionId) => {
    amplitudeLog(
      "Product search",
      "Condition search button clicked",
      "Condition search button clicked"
    );
    setShouldDisplayConditions(false);
    if (barCode) {
      const response = await postItemApi({
        barcode: query,
        conditionId: conditionId,
      });
      if (response.success) {
        amplitudeData(response?.data?.id);
        requestComplete();
      } else {
        toastErrMessage(response.message);
      }
    } else {
      const response = await postItemApi({
        itemDescription: query.replace(/  +/g, " ").trim(),
        conditionId: conditionId,
        bars: getBarsCount(),
      });

      if (response.success) {
        amplitudeData(response?.data?.id);

        requestComplete();
      } else {
        toastErrMessage(response.message);
      }
    }
  };

  const amplitudeData = async (id) => {
    const res = await getByIdItemApi(id);
    const storedata = res?.data?.store?.data.find(
      (obj) => obj.id === res?.data?.itemData?.storeId
    );
    const seachDetail = {
      barcode: res?.data?.itemData?.barcode,
      searchId: res?.data?.itemData?.id,
      searchTitle: res?.data?.itemData?.title,
      searchUniqueCode: res?.data?.itemData?.uniqueCode,
      searchStrength: res?.data?.itemData?.strength,
      searchCondition: res?.data?.itemData?.condition?.name,
      serachDisputeStatus: res?.data?.itemData?.disputeStatus,
      storeId: res?.data?.itemData?.storeId,
      storeName: storedata?.name,
      storeCode: storedata?.code,
      organizationName: storedata?.organization?.name,
      searchStatus: getStatusLabel(res.data?.itemData?.status),
    };

    amplitudeLog(
      "Product search",
      "Search for a product (title/barcode)",
      `User Search for Product`,
      { searchTitle: seachDetail.searchTitle, barcode: seachDetail.barcode }
    ); // groupnme/event name/ message
    amplitudeLog(
      "Product search",
      "Product condition searched",
      `User Search for Product Condition`,
      { searchCondition: seachDetail.searchCondition }
    ); // groupnme/event name/ message
    amplitudeLog("Product search", " Title strength ", ` Title strength  `, {
      searchStrength: seachDetail.searchStrength,
    }); // groupnme/event name/ message
  };

  const updateBars = useCallback(
    debounce(async (q) => {
      const wordsStrength = await getQueryStrenght(q);
      if (wordsStrength.success) {
        const strengthBarsData = wordsStrength.data;
        setBars(strengthBarsData);
      }
    }, 600),
    []
  );

  useEffect(() => {
    setWeakDescription(onceClicked && onQuery);
  }, [onceClicked, onQuery]);

  useEffect(() => {
    if (query !== "") setTipsOpened(true);
  }, [query]);

  useEffect(() => {
    if (query !== "") setShowExamples(true);
  }, [tipsOpened]);

  useEffect(() => {
    if (query === "" || barCode) {
      setBars([]);
      updateBars("");
      setOnceClicked(false);
    } else updateBars(query);
  }, [query]);

  useEffect(() => {
    if (getBarsCount() >= 2) {
      setOnceClicked(false);
    }
  }, [bars]);

  return (
    <div>
      {onQuery && (
        <div>
          <div className="search__continer_wrap">
            <div className="search__wrap">
              <div className="dashboard__heading">
                What would you like to sell?
              </div>
              <div className="dashboard__subHeading mr subHeading-wd ">
                Add as many details as you can to ensure you receive the most
                accurate price possible.
              </div>
              <SearchField
                placeholder="eg. Apple iPhone 12 64GB blue"
                value={query}
                setValue={(value) => {
                  const checkingString = /^[\d\s]+$/.test(value);
                  checkingString
                    ? setQuery(value.replaceAll(" ", ""))
                    : setQuery(value);
                  setBarCode(checkingString);
                }}
                submitHandle={submitHandle}
                setOnQuery={setOnQuery}
                onceClicked={onceClicked}
                setShowBackbtn={setShowBackbtn}
                strength={getBarsCount()}
                barCode={barCode}
              />
              <StrengthBar bars={getBarsCount()} />
              {!onceClicked ? (
                <SearchAlert
                  onClickHandle={() => showhandle()}
                  showExamples={showExamples}
                  bars={getBarsCount()}
                  query={query}
                />
              ) : (
                <WeakConditionSelect />
              )}
            </div>
          </div>
          {showExamples && (
            <div
              className={`strength__container__wrap ${
                onceClicked && " strength__container__wrap__weak"
              }`}
            >
              <div className="search__wrap">
                <div
                  className={`strentg__help__container_downArrow ${
                    onceClicked && "strentg__help__container_downArrow_weak"
                  }`}
                ></div>
                <StrengthHelpDetials bars={bars} />
              </div>
            </div>
          )}
        </div>
      )}
      {!onQuery && (
        <div className=" condition__wrap">
          <ConditionSelect
            setOnQuery={setOnQuery}
            onFindSubmit={onFindSubmit}
            onceClicked={onceClicked}
            setShowBackbtn={setShowBackbtn}
            shouldDisplayConditions={shouldDisplayConditions}
          />
        </div>
      )}
    </div>
  );
};
SubmitItem.propTypes = {
  itemSubmitted: PropTypes.bool,
  setWeakDescription: PropTypes.func,
  onQuery: PropTypes.func,
  setOnQuery: PropTypes.func,
  setShowBackbtn: PropTypes.func,
};
export default SubmitItem;
