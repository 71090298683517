import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { isTokenInValid, getFormattedDate } from "../../../utils/utilFunctions";
import {
  getAdminResultsApi,
  getDisputeData,
  postRerunTestData,
} from "../../../api/adminDashboardApi";
import { getConditionsApi } from "../../../api/dashboardApi";

import ebaylogo from "../../../assets/images/ebaylogo.png";
import facebooklogo from "../../../assets/images/facebook.png";
import depoplogo from "../../../assets/images/depoplogo.png";

import AdminDesputeInfo from "../../Dashboard/DesputePopup/AdminDesputeInfo";
import "./AdminResults.scss";

const AdminResults = () => {
  const [conditions, setConditions] = useState([]);
  const [showNotePop, setShowNotePop] = useState(false);
  const [notes, setNotes] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [item, setItem] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [disputeDiscurption, setDisputeDiscurption] = useState([]);
  const [submitResult, setSubmitResult] = useState(false);
  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
    }
    verifyToken();
  }, []);
  const getConditions = async () => {
    const response = await getConditionsApi();
    if (response.success) {
      setConditions(
        response.data.map((c) => {
          return {
            id: c.id,
            value: c.name,
          };
        })
      );
    }
  };
  useEffect(() => {
    getConditions();
  }, []);

  const handleDispute = async (row) => {
    if (row.disputeStatus === 1 || row.disputeStatus === 4) {
      const itemId = row.id;
      const response = await getDisputeData(itemId);
      if (response.success) {
        setDisputeDiscurption(response.data);
      }
      setItem(row.id);
      setStoreId(row.storeId);
      setShowNotePop(true);
    } else {
      setShowNotePop(false);
    }
  };

  const onSubmit = () => {
    setSubmitResult(true);
  };

  const popupProps = {
    title: "Inaccurate result?",
    userMessage: disputeDiscurption,
    buttonText: "REPORT",
    fieldType: "text",
    onChange: (e) => {
      const submitValid = e.length >= 1 && e.length <= 200;

      setSubmitDisabled(!submitValid);
      setNotes(e);
    },
    onClose: () => {
      setNotes("");
      setSubmitDisabled(true);
      setShowNotePop(false);
    },
  };

  const getMostProfitable = (itemData) => {
    return itemData.status === 2
      ? itemData.marketPlaceItems
          .filter((mpi) => mpi.profit)
          .sort((a, b) => b.profit - a.profit)[0]
      : null;
  };

  const getSlowestResponse = (itemData) => {
    return itemData.status === 2 || itemData.status === 3
      ? itemData.marketPlaceItems
          .filter((mpi) => mpi.responseTime)
          .sort((a, b) => (b.responseTime >= a.responseTime ? 1 : -1))[0]
      : null;
  };
  const getSlowestResponseLogo = (itemData) => {
    const slowest = getSlowestResponse(itemData);
    return (itemData.status === 2 || itemData.status === 3) && slowest
      ? getLogo(slowest.marketPlaceName)
      : "";
  };

  const getMostProfitableLogo = (itemData) => {
    const mostProfitable = getMostProfitable(itemData);
    return itemData.status === 2 && mostProfitable
      ? getLogo(mostProfitable.marketPlaceName)
      : "";
  };
  const getLogo = (marketPlaceName) => {
    switch (marketPlaceName) {
      case "Ebay":
      case "Ebay Edinburg University":
        return ebaylogo;
      case "Facebook":
        return facebooklogo;
      case "Depop":
        return depoplogo;
      default:
        return "";
    }
  };

  const getItemsBeforeListing = (row) => {
    return row.marketPlaceItems.reduce(
      (acc, curr) => acc + curr.itemsBeforeFiltering,
      0
    );
  };
  const getItemsAfterListing = (row) => {
    return row.marketPlaceItems.reduce(
      (acc, curr) => acc + curr.itemsAfterFiltering,
      0
    );
  };

  const columns = [
    {
      name: "ItemId",
      selector: "id",
      grow: 1.2,
      wrap: true,
    },
    {
      name: "Store",
      selector: "store",
      grow: 1.2,
      wrap: true,
    },
    {
      name: "OrganisationName",
      selector: "organisationName",
      grow: 1.2,
      wrap: true,
    },
    {
      name: "Disputed",
      selector: "dispute",
      hide: "sm",
      cell: (row) => (
        <div
          className={row.disputeStatus ? "dashboard__handle-dispute" : ""}
          onClick={() => handleDispute(row)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleDispute(row);
            }
          }}
        >
          {`${row.disputeStatus ? "Yes" : "No"}`}
        </div>
      ),
      center: true,
    },
    {
      name: "Barcode",
      selector: "barcode",
      // grow: 3,
      center: true,
      minWidth: "15rem",
    },
    {
      name: "Title",
      selector: "title",
      // grow: 3,
      center: true,
      minWidth: "15rem",
      cell: (row) => <div title={row.title}>{row.title}</div>,
    },
    {
      name: "Strength",
      selector: "strength",
      grow: 1.2,
      wrap: true,
      center: true,
    },
    {
      name: "TriggerBestMatch",
      selector: "triggerBestMatch",
      grow: 1.2,
      wrap: true,
      center: true,
    },
    {
      name: "Condition",
      selector: "conditionName",
      hide: "sm",
      wrap: true,
      center: true,
    },
    {
      name: "Code",
      selector: "uniqueCode",
      hide: "sm",
      wrap: true,
      center: true,
    },
    {
      name: "Profitable",
      selector: "marketPlaceItems",
      hide: "sm",
      wrap: true,
      center: true,
      cell: (row) => (
        <div>
          {getMostProfitable(row) && (
            <img
              style={{ width: "30px" }}
              src={getMostProfitableLogo(row)}
              alt=""
            ></img>
          )}
          <br></br>
          {getMostProfitable(row) ? getMostProfitable(row).meanPrice : ""}
        </div>
      ),
    },
    {
      name: "Slowest Response",
      selector: "responseDateTime",
      hide: "sm",
      wrap: true,
      center: true,
      cell: (row) => (
        <div>
          {getSlowestResponse(row) && (
            <img
              style={{ width: "30px" }}
              src={getSlowestResponseLogo(row)}
              alt=""
            ></img>
          )}
          <br></br>
          {getSlowestResponse(row) ? getSlowestResponse(row).responseTime : ""}
        </div>
      ),
    },
    {
      name: "Search TimeStamp",
      selector: (row) => getFormattedDate(row.createdAt),
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Remove Listing",
      selector: (row) => {
        return row.removeListing ? (
          <a
            href={`/removedListing/${row.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Yes
          </a>
        ) : (
          <span>No</span>
        );
      },
      hide: "sm",
      center: true,
      minWidth: "5rem",
    },
    {
      name: "Luminati Listing",
      selector: (row) => getItemsBeforeListing(row),
      hide: "sm",
      center: true,
    },
    {
      name: "System Listing",
      selector: (row) => getItemsAfterListing(row),
      hide: "sm",
      center: true,
      minWidth: "5rem",
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "unset",
        minHeight: "unset",
        marginBottom: "3rem",
      },
    },
    rows: {
      style: {
        minHeight: "unset",
        marginBottom: "3rem",

        "&:not(:last-of-type)": {
          border: "unset",
        },
        "&:last-of-type": {
          marginBottom: "4rem",
        },
      },
    },
    headCells: {
      style: {
        padding: "unset",
        fontSize: "1.6rem",
        fontWeight: "800",
        color: "#262626",
      },
    },
    cells: {
      style: {
        padding: "unset",
        color: "#262626",
        fontSize: "1.4rem",
      },
    },
    pagination: {
      style: {
        borderTopColor: "#6D86D6",
        padding: "0",
        justifyContent: "unset",
      },
    },
  };

  const conditionalRowStyles = [
    // #fa7c89

    {
      when: (row) => row.status === 2,
      style: {
        backgroundColor: "rgba(177, 222, 241, 1)",
        color: "white",
      },
    },
    {
      when: (row) => row.status === 3,
      style: {
        backgroundColor: "rgba(120, 189, 234, 1)",
        color: "white",
      },
    },
  ];

  const selectorFunctionToSelectorNameMapping = [
    {
      name: "Timestamp",
      selector: "id",
    },
  ];

  const searchByOptions = [
    {
      id: "title",
      value: "Search By Title",
    },
    {
      id: "barcode",
      value: "Search By Barcode",
    },
    {
      id: "condition",
      value: "Search By Condition",
    },
    {
      id: "marketPlace",
      value: "Search By Marketplace",
    },
    {
      id: "dispute",
      value: "Search By Dispute",
    },
    {
      id: "uniqueCode",
      value: "Search by Tracking code",
    },
    {
      id: "strength",
      value: "Search by Strength",
    },
    {
      id: "triggerBestMatch",
      value: "Search by Trigger Best Match",
    },
  ];
  const rowDisabledCriteria = (row) => row.type !== 3;

  return (
    <div className="adminResults dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Results</div>
          <AdminNav />
        </div>

        <ReactDataTable
          viewName="AdminResults"
          columns={columns}
          customStyles={customStyles}
          api={getAdminResultsApi}
          sorting={true}
          defaultSortBy="id"
          sortOrder="desc"
          selectorFunctionToSelectorNameMapping={
            selectorFunctionToSelectorNameMapping
          }
          filtering={true}
          conditionalRowStyles={conditionalRowStyles}
          filterByOptions={searchByOptions}
          showPageSizeChangeOption={true}
          customProps={{
            selectableRows: true,
            selectableRowDisabled: rowDisabledCriteria,
            submitRerunApi: postRerunTestData,
            selectableRowsVisibleOnly: true,
            conditions,
            expandableRows: true,
          }}
          exportData={true}
        />
        <AdminDesputeInfo
          popupProps={popupProps}
          popShow={showNotePop}
          value={notes}
          itemId={item}
          storeId={storeId}
          submitDisabled={submitDisabled}
          onSubmit={() => onSubmit()}
        />
      </section>
    </div>
  );
};

export default AdminResults;
