import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import DataTable from "react-data-table-component";
import FilterExpandableComponent from "../FilterExpandableComponent/FilterExpandableComponent";
import MarketPlaceNameCell from "../MarketPlaceNameCell/MarketPlaceNameCell";
import BrightDataResponseCell from "../BrightDataResponseCell/BrightDataResponseCell";
import ResponseTimeCell from "../ResponseTimeCell/ResponseTimeCell";
import NonFloatedPriceCell from "../NonFloatedPriceCell/NonFloatedPriceCell";
import RequestTimeCell from "../RequestTimeCell/RequestTimeCell";
import ProfitCell from "../ProfitCell/ProfitCell";
import LowPriceCell from "../LowPriceCell/LowPriceCell";
import HighPriceCell from "../HighPriceCell/HighPriceCell";
import { ITEM_DETAIL_STATUS } from "../../utils/Constants";
import { getKeyByValue } from "../../utils/utilFunctions";

const MarketPlaceExpandableComponent = ({ data, customStyles }) => {
  const [marketPlaceCompData, setMarketPlaceCompData] = useState([]);

  const setMarketPlaceComponentData = () => {
    setMarketPlaceCompData(data.marketPlaceItems);
  };

  useEffect(() => {
    setMarketPlaceComponentData();
  }, []);

  const getMeanPriceText = (row) => {
    if (row.meanPrice) {
      return `£${row.meanPrice}`;
    } else if (row.status !== 1) {
      return getKeyByValue(ITEM_DETAIL_STATUS, row.detailStatus);
    } else {
      return "";
    }
  };

  const marketPlaceCompColumns = [
    {
      name: "Mkt Id",
      selector: "marketPlaceItemId",
      wrap: true,
      grow: 1.2,
    },
    {
      name: "MarketPlace Name",
      selector: "marketPlaceItemId",

      wrap: true,
      grow: 1.2,

      cell: (row) => <MarketPlaceNameCell row={row} data={data} />,
    },

    {
      name: "(L)↓ Price",
      hide: "sm",
      sortable: true,
      selector: "lowestPrice",
      center: true,
      cell: (row) => <LowPriceCell row={row} />,
    },
    {
      name: "(H)↑ Price",
      hide: "sm",
      sortable: true,
      selector: "highestPrice",
      center: true,
      cell: (row) => <HighPriceCell row={row} />,
    },
    {
      name: "Mean Price",
      minWidth: "15rem",
      selector: (row) => getMeanPriceText(row),
      center: true,
    },
    {
      name: "Profit",
      hide: "sm",
      grow: 0,
      sortable: true,
      cell: (row) => <ProfitCell row={row} />,
      center: true,
    },
    {
      name: "Non Floated Price",
      hide: "sm",
      selector: (row) => <NonFloatedPriceCell row={row} />,
      center: true,
    },
    {
      name: "Request Time",
      selector: (row) => <RequestTimeCell row={row} />,
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Response Time",
      selector: (row) => <ResponseTimeCell row={row} />,
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Bright Response Time",
      selector: "brightResponseTime",
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Filter Response Time",
      selector: "filterResponseTime",
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Total Response Time",
      selector: "totalResponseTime",
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Bright Data Response",
      selector: (row) => <BrightDataResponseCell row={row} />,
      hide: "sm",
      grow: 1.5,
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: "Api Id",
      selector: "apiId",
      hide: "sm",
      wrap: true,
      center: true,
    },
    {
      name: "Luminati Listing",
      selector: "itemsBeforeFiltering",
      hide: "sm",
      wrap: true,
      center: true,
    },
    {
      name: "System Listing",
      selector: "itemsAfterFiltering",
      hide: "sm",
      wrap: true,
      center: true,
    },
  ];
  MarketPlaceExpandableComponent.propTypes = {
    data: propTypes.shape({
      marketPlaceItems: propTypes.array.isRequired,
      uniqueCode: propTypes.string.isRequired,
    }).isRequired,
    customStyles: propTypes.object.isRequired,
  };
  return (
    <div className="">
      <DataTable
        columns={marketPlaceCompColumns}
        data={marketPlaceCompData}
        onRowExpandToggled={onRowExpandToggled}
        customStyles={customStyles}
        expandableRows={true}
        expandableRowsComponent={FilterExpandableComponent}
      />
    </div>
  );
};
const onRowExpandToggled = (state, a, b) => {
  //  const
};
export default MarketPlaceExpandableComponent;
