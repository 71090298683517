import { Box, Button, Grid, Divider, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Calender } from "../../../../components/Calender";
import { Modal } from "../../../../components/Modal";
import { Select } from "../../../../components/Select";
import { TextField } from "../../../../components/TextField";
import {
  API_ACCESS,
  STORE_TYPE,
  ENABLE_NAV_TYPE,
} from "../../../../utils/Constants";
import "../../AdminStores/AdminStores.scss";
import date from "date-and-time";
import { getOrganizations } from "../../../../api/adminDashboardApi";

const sTypes = { ...STORE_TYPE };
delete sTypes.BHF;

const storeTypes = Object.keys(sTypes).map((key) => {
  return {
    value: STORE_TYPE[key],
    label: key,
  };
});
const enableNavigationType = Object.keys(ENABLE_NAV_TYPE).map((key) => {
  return {
    value: ENABLE_NAV_TYPE[key],
    label: key,
  };
});
const accessTypes = Object.keys(API_ACCESS).map((el) => {
  return {
    value: API_ACCESS[el],
    label: el,
  };
});
const getDate = () => {
  const addedDate = date.addDays(new Date(), 365);
  return date.format(addedDate, "YYYY-MM-DD");
};

const CreateAndEditStore = ({
  open,
  setOpen,
  submit,
  singleStoreData,
  setSingleStore,
  isEditModal = false,
  editSubmit,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const [inputs, setInputs] = useState(() => {
    if (isEditModal) {
      return {
        apiAccess: singleStoreData.apiAccess ? 1 : 0,
        enableNavigation: singleStoreData.isEnableNavigation ? 1 : 0,
        storeType: singleStoreData.type,
        storeName: singleStoreData.name,
        storeCode: singleStoreData.code,
        password: "",
        email: singleStoreData.email,
        navigationLink: singleStoreData.navigationLink,
        organizationId: singleStoreData.organizationId,
        apiToken: singleStoreData.apiToken,
        apiExpiry: singleStoreData.apiExpiry,
        storeId: singleStoreData.id,
      };
    }
    return {
      apiAccess: 0,
      enableNavigation: 0,
      storeType: 3,
      storeName: "",
      storeCode: "",
      password: "",
      email: "",
      navigationLink: "",
      organizationId: 1,
      apiToken: uuidv4(),
      apiExpiry: getDate(),
    };
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const [submitDisabled, setSubmitDisabled] = useState(false); // TODO: change to true and handle

  const handleStoreSubmit = async (e) => {
    e.preventDefault();
    inputs.apiToken = inputs.apiAccess ? inputs.apiToken : "";
    submit(inputs);
  };

  const handleEditStoreSubmit = async (e) => {
    e.preventDefault();
    editSubmit(inputs);
  };

  const DividerCom = ({ label }) => (
    <Divider style={{ padding: "40px 0px" }}>
      <Chip label={label} style={{ fontSize: 12 }} />
    </Divider>
  );
  DividerCom.propTypes = {
    label: PropTypes.string.isRequired,
  };

  const initData = async () => {
    const response = await getOrganizations();

    const allOrganizations = response?.data
      .filter((el) => el.isActive)
      ?.map((key) => {
        return {
          value: key.id,
          label: key.name,
        };
      });
    setOrganizations(allOrganizations);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Modal
      open={open}
      textTitle={isEditModal ? "Edit Store" : "Create Store"}
      onClose={() => {
        setOpen(false);
        if (isEditModal) {
          setSingleStore({ data: {}, showEditModal: false });
        }
      }}
      content={
        <form
          className="storeForm"
          onSubmit={isEditModal ? handleEditStoreSubmit : handleStoreSubmit}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  label={"Organization"}
                  labelId="organizationId"
                  name="organizationId"
                  value={inputs.organizationId}
                  setValue={handleChange}
                  options={organizations}
                />
              </Grid>
              <Grid item xs={8}>
                <Select
                  label={"Select Account Type"}
                  labelId="storeType"
                  value={inputs.storeType}
                  setValue={handleChange}
                  options={storeTypes}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={"Store Name"}
                  name="storeName"
                  placeholder="test"
                  value={inputs.storeName}
                  onChange={handleChange}
                  title="Please enter store name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={"Store Code (Username)"}
                  name="storeCode"
                  placeholder="thrifttest"
                  value={inputs.storeCode}
                  onChange={handleChange}
                  title="Please enter store code (username)"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={"Email (Optional)"}
                  name="email"
                  type="email"
                  required={false}
                  placeholder="example@example.com"
                  value={inputs.email}
                  onChange={handleChange}
                  title="Please enter email"
                />
              </Grid>

              {isEditModal ? (
                ""
              ) : (
                <Grid item xs={6}>
                  <TextField
                    label={"Password"}
                    name="password"
                    type="password"
                    placeholder="thrifttest"
                    value={inputs.password}
                    onChange={handleChange}
                    title="Please enter password"
                  />
                </Grid>
              )}
            </Grid>
            <DividerCom label="Api Controls" />

            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Select
                  label={"Api Access"}
                  labelId="apiAccess"
                  value={inputs.apiAccess}
                  setValue={handleChange}
                  options={accessTypes}
                />
              </Grid>
              {inputs.apiAccess ? (
                <>
                  <Grid item xs={12}>
                    <Calender
                      value={inputs.apiExpiry}
                      setValue={handleChange}
                      label="Api Expiry"
                      name="apiExpiry"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={9}>
                        <TextField
                          disabled={true}
                          label={"Generate Api Token"}
                          name="apiToken"
                          type="text"
                          placeholder=""
                          value={inputs.apiToken}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        item
                        spacing={3}
                        style={{ display: "flex", alignItems: "end" }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() =>
                            setInputs((inputs) => ({
                              ...inputs,
                              apiToken: uuidv4(),
                            }))
                          }
                        >
                          Generate
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
            <DividerCom label="Enable Navigation" />
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Select
                  label={"Enable Navigation"}
                  labelId="enableNavigation"
                  value={inputs.enableNavigation}
                  setValue={handleChange}
                  options={enableNavigationType}
                />
              </Grid>
            </Grid>
            {inputs.enableNavigation ? (
              <Grid container spacing={3}>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <TextField
                    label={"Navigation Link"}
                    name="navigationLink"
                    maxLength="1000"
                    placeholder="https://app.thrift-app.com/log"
                    value={inputs.navigationLink}
                    onChange={handleChange}
                    title="Please enter navigation link"
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
          <div
            style={{
              width: "100%",
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <input
              type="submit"
              value={isEditModal ? "Update Store" : "Create Store"}
              className="storeSubmit"
              disabled={submitDisabled}
              style={{
                padding: "10px 30px",
                border: "1px solid white",
                borderRadius: 5,
                color: "white",
                fontSize: 12,
                backgroundColor: "#1976d2",
              }}
            />
          </div>
        </form>
      }
    />
  );
};

CreateAndEditStore.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  singleStoreData: PropTypes.any,
  setSingleStore: PropTypes.func.isRequired,
  isEditModal: PropTypes.bool,
  editSubmit: PropTypes.func,
};
export default CreateAndEditStore;
