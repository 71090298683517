import React from "react";
import PropTypes from "prop-types";
import { ToggleOff, ToggleOn } from "@mui/icons-material";

const ToggleButton = ({ onToggle, isActive }) => {
  const sx = {
    fontSize: "2em",
    color: isActive ? "#21a0d0" : "black",
    cursor: "pointer",
  };
  return (
    <div
      onClick={onToggle}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onToggle();
        }
      }}
    >
      <div>{isActive ? <ToggleOn sx={sx} /> : <ToggleOff sx={sx} />}</div>
    </div>
  );
};
ToggleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
export default ToggleButton;
