import { get, create, del, update } from "./http.service";

const GET_CONDITIONS_URL = `api/condition/client`;
const GET_RESULTS_URL = (pageNumber, pageSize) =>
  `api/result/${pageNumber}/${pageSize}`;
const GET_SINGLE_RESULT_URL = (itemID) => `api/result/${itemID}`;
const GET_QUERY_STRENGTH_URL = (query) => `api/query-strength/${query}`;
const POST_ITEM_URL = `api/item`;
const GET_ERA_URL = `api/era`;
const DISPUTE_URL = `api/dispute`;
const BEST_MATCH_URL = `api/best-match-price`;
const UPDATE_ITEM_URL = `api/item/update`;
const UPDATE_ITEM_Trigger_Best_Match_URL = `api/item/update/triggerbestmatch`;
const ADD_REMOVED_LISTING = `api/addremovedlisting`;
const GET_RESULTS_UPDATE_URL = (datetime) =>
  `api/storeResultUpdates/${datetime}`;

export const triggerBestMatchFilter = async (itemId) => {
  return create(`${BEST_MATCH_URL}/${itemId}`);
};
export const updateRemoveListFieldFromItemInDb = async (id) => {
  return update(UPDATE_ITEM_URL, id, true);
};
export const updateTriggerBestMatch = async (id) => {
  return update(UPDATE_ITEM_Trigger_Best_Match_URL, id);
};
export const getConditionsApi = async () => {
  return get(GET_CONDITIONS_URL, true);
};

export const getEraApi = async () => {
  return get(GET_ERA_URL, true);
};

export const postItemApi = async (payload) => {
  return create(POST_ITEM_URL, payload, true);
};

export const getByIdItemApi = async (payload) => {
  return get(`${POST_ITEM_URL}/getbyid/${payload}`);
};

export const getResultsApi = async (pageNumber, pageSize) => {
  return get(GET_RESULTS_URL(pageNumber, pageSize), true);
};

export const getQueryStrenght = async (query) => {
  return get(GET_QUERY_STRENGTH_URL(query), true);
};

export const getSingleResultApi = async (itemID) => {
  return get(GET_SINGLE_RESULT_URL(itemID), true);
};

export const postDisputeApi = async (payload) => {
  return create(DISPUTE_URL, payload, true);
};

export const closeDisputeApi = async (itemId) => {
  return del(DISPUTE_URL, itemId, true);
};

export const getResultsUpdatesApi = async (datetime) => {
  return get(GET_RESULTS_UPDATE_URL(datetime), true);
};
export const addRemovedListing = (payload) => {
  return create(ADD_REMOVED_LISTING, payload, true);
};
