import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import { isTokenInValid } from "../../../utils/utilFunctions";
import { getLuminatiErrorsApi } from "../../../api/adminDashboardApi";
import AdminNav from "../../../components/AdminNav/AdminNav";

import "./AdminLuminatiErrors.scss";

const AdminLuminatiErrors = () => {
  const [luminatiResponse, setLuminatiResponse] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatData = (responseArray) => {
    const formattedData = [];
    responseArray.forEach((object) => {
      let { marketPlace, data } = { ...object };
      formattedData.push({ marketPlaceName: marketPlace.name, ...data["0"] });
    });
    return formattedData;
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getLuminatiErrorsApi();
      if (response.success) {
        setLuminatiResponse(formatData(response.data));
        setLoading(false);
      }
    };

    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  const handleCopy = (copy) => {
    navigator.clipboard.writeText(JSON.stringify(copy, null, "\t"));
  };

  return (
    <div className="adminLuminatiErrors dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Luminati Errors</div>
          <AdminNav />
        </div>
        <div
          className="dashboard__copy"
          onClick={() => handleCopy(luminatiResponse)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleCopy(luminatiResponse);
            }
          }}
        >
          <b>Copy Luminati JSON</b>
        </div>

        {loading ? (
          <div>loading...</div>
        ) : !Array.isArray(luminatiResponse) || luminatiResponse.length > 0 ? (
          <pre className={`dashboard__json dashboard__json__show`}>
            {JSON.stringify(luminatiResponse, null, 4)}
          </pre>
        ) : (
          <div>No data found</div>
        )}
      </section>
    </div>
  );
};

export default AdminLuminatiErrors;
