import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import * as amplitude from "@amplitude/analytics-browser";
import { ToastContainer } from "react-toastify";
import Logger from "./logger/logger";
import { useDispatch, useSelector } from "react-redux";
import { initSocket, closeSocket } from "./redux/actions";
import "react-toastify/dist/ReactToastify.min.css";
import "dotenv/config";
let AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY;
amplitude.init(AMPLITUDE_KEY);
function App() {
  const dispatch = useDispatch();
  const io = useSelector((state) => state.io);

  useEffect(() => {
    if (
      !(
        io.status === "connected" &&
        io?.socket?.connected &&
        io.status === "connecting"
      )
    )
      dispatch(initSocket());
    return dispatch(closeSocket());
  }, []);

  useEffect(() => {
    window.onunhandledrejection = function (e) {
      Logger.logError(window.location.href, e.reason.message, e.reason.stack);
    };
  }, []);

  return (
    <div className="App">
      <Routes />
      <ToastContainer
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        autoClose={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
