import { create } from "./http.service";
import Storage from "../utils/WebStorage";

const authUrl = `api/auth`;
const LOGIN_URL = `${authUrl}/login`;

export const loginApi = async (payload) => {
  const res = await create(LOGIN_URL, payload, false);
  if (res.success) Storage.setAuthToken(res.data.token);
  return res;
};
