import React from "react";
import PropTypes from "prop-types";
import "./SearchAlert.scss";
import LightBulb from "../../assets/images/lightbulb.svg";
import LightBulbOff from "../../assets/images/lightbulb-off.svg";

const SearchAlert = ({ onClickHandle, showExamples, bars, query }) => {
  return (
    <>
      <div
        className="search__alert_container"
        onClick={onClickHandle}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickHandle();
          }
        }}
      >
        {" "}
        <div className="alert__icon">
          <img src={showExamples ? LightBulbOff : LightBulb} alt="alert-icon" />
        </div>
        <p className="alert__text">{`${
          showExamples ? "Hide " : "Show "
        }tips`}</p>
        {showExamples && (
          <p className="best__result__info web-view">
            For best results <b>include at least 3</b> of these.
          </p>
        )}
        {query?.length ? (
          <p className="strength__status">
            {bars === 1 || bars === 0
              ? "Weak"
              : bars === 2
              ? "Fair"
              : bars === 3
              ? "Good"
              : "Strong"}
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="search__alert_container-msg">
        {showExamples && (
          <p className="best__result__info mobile-view">
            For best results <b>include at least 3</b> of these.
          </p>
        )}
      </div>
    </>
  );
};
SearchAlert.propTypes = {
  onClickHandle: PropTypes.func.isRequired,
  showExamples: PropTypes.bool,
  bars: PropTypes.array,
  query: PropTypes.string,
};
export default SearchAlert;
