import propTypes from "prop-types";

const getFormattedPrice = (value) => (value ? `£${value}` : "");

const LowPriceCell = ({ row }) => {
  LowPriceCell.propTypes = {
    row: propTypes.shape({
      lowestPrice: propTypes.number,
    }).isRequired,
  };
  return <div>{getFormattedPrice(row.lowestPrice)}</div>;
};
export default LowPriceCell;
