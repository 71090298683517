import { get, create, update, del, getById, updateOrder } from "./http.service";

const POST_ITEMS_RERUN_URL = `api/admin/items/rerun`;
const GET_STORES_URL = `api/admin/store`;
const POST_STORES_URL = `api/admin/store`;
const GET_CALCULATOR_VERSION_URL = `api/calculatorVersion`;
const PAIRS_URL = `api/admin/pair`;
const GET_PAIR_CATEGORIES_URL = `api/pairCategories`;
const GET_CATEGORIES_TYPE_URL = `api/categoriesType`;
const GET_WORD_ANCHOR_BANK_URL = `api/admin/wordAnchorBank`;
const GET_MARKET_PLACES_URL = `api/admin/marketPlaces`;
const BULK_INSERT_PAIRS_URL = `api/admin/pair/bulk-insert`;
const PAIRS_BACK_UP_URL = `api/admin/pair/back-up`;
const GET_SIGNED_URL_TO_DOWNLOAD_PAIRS_FILE = `api/admin/pair/getSignedUrl`;
const GET_LUMINATI_ERRORS_URL = `api/admin/luminatiErrors`;
const GET_S3_URL = `api/aws/generate-s3-post-url/`;
const BULK_UPLOAD_URL = `api/bulkUpload`;
const GET_CONDITIONS_URL = `api/admin/conditions`;
const GET_DISPUTED_ITEMS_URL = `/api/admin/disputes`;
const UPDATE_DISPUTED_ITEMS_URL = `/api/admin/dispute/item`;
const UPDATE_FILTER_ARRAY_DATA_URL = `api/admin/data`;
const UPDATE_FILTER_ARRAY_ADD_DATA_URL = `api/admin/data/add`;
const GET_ALL_REMOVED_LISTING = `api/getremovedlisting`;
const GET_FILTER_JSON_URL = (dataId) => `api/admin/filterData?id=${dataId}`;
const GET_TEST_FILTER_URL = (type) => `api/admin/testFilter/${type}`;
const GET_DISPUTE_DATA = (itemId) => `api/admin/dispute/item/${itemId}`;
const GET_BULKUPLOAD_FAILED_ITEMS = `api/admin/getbulkUploadFailedItems`;
const ORGANIZATION_URL = `api/admin/organization`;

const GET_ITEM_FILTERS_STATS = (itemId) =>
  `api/admin/itemFilterStats/${itemId}`;
const GET_API_STATS_URL = (pageNumber, pageSize) =>
  `api/admin/apiStats?pageNumber=${pageNumber}&pageSize=${pageSize}`;
const GET_FILTER_STATS_URL = (storeId, versionId, marketPlaceId) =>
  `api/admin/filterStats?storeId=${storeId}&versionId=${versionId}&marketPlaceId=${marketPlaceId}`;
const GET_KICKED_ITEM_BAD_WORDS_URL = (storeId) =>
  `api/admin/kickedItemsBadWord/${storeId}`;
const GET_MARKETPLACE_ITEM_JSON_URL = (marketPlaceItemId) =>
  `api/admin/data/${marketPlaceItemId}`;
const GET_ROYAL_MAIL_PRICES_URL = `api/admin/royalMailPrices`;
const GET_Unassinged_Key_Words_URL = `api/admin/unassignedKeyWords`;
const GET_Unassinged_Key_Words_By_Id_URL = (keyWordID) =>
  `api/admin/unassignedKeyWords/${keyWordID}`;
const UPDATE_ITEM_STATUS_TO_REPROCESS = `api/admin/updateItemStatusToReprocess`;
const UPDATE_ITEM_STATUS_TO_REPROCESS_ALL_ITEM = `api/admin/updateItemStatusToReprocessAllItem`;
const GET_RESULTS_URL = (
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  searchBy,
  search,
  storeId,
  versionId,
  storeTypeId,
  startDate,
  endDate,
  organisationId
) =>
  `api/admin/result?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}&searchBy=${searchBy}&search=${search}&storeId=${storeId}&versionId=${versionId}&storeTypeId=${storeTypeId}&startDate=${startDate}&endDate=${endDate}&organisationId=${organisationId}`;
const UPDATE_STORE_URL = `api/admin/store`;

export const getS3UrlApi = async (fileType, storeCode) => {
  return get(`${GET_S3_URL}${fileType}/${storeCode}`, {}, true);
};

export const uploadFileApi = async (url, payload) => {
  const res = await create(url, payload, false);
  if (typeof res.success === "undefined") {
    return {
      success: true,
    };
  }
  return res;
};
export const bulkUploadApi = async (payload) => {
  return create(BULK_UPLOAD_URL, payload, true);
};

export const getBulkUploadApi = async () => {
  return get(BULK_UPLOAD_URL, true);
};

export const getAdminResultsApi = async (
  pageNumber,
  pageSize,
  sortBy = "id",
  sortDirection = "desc",
  searchBy = "",
  search = "",
  storeId = "",
  versionId = "",
  storeTypeId = "",
  startDate = "",
  endDate = "",
  organisationId = ""
) => {
  return get(
    GET_RESULTS_URL(
      pageNumber,
      pageSize,
      sortBy,
      sortDirection,
      searchBy,
      search,
      storeId,
      versionId,
      storeTypeId,
      startDate,
      endDate,
      organisationId
    ),
    true
  );
};

export const getAdminStatsApi = async (pageNumber, pageSize) => {
  return get(GET_API_STATS_URL(pageNumber, pageSize), true);
};

export const getAdminFilterStatsApi = async (
  storeId,
  versionId,
  marketPlaceId
) => {
  return get(GET_FILTER_STATS_URL(storeId, versionId, marketPlaceId), true);
};

export const getStoresApi = async () => {
  return get(GET_STORES_URL, true);
};

export const postStoresApi = async (store) => {
  return create(POST_STORES_URL, store, true);
};

export const getVersionApi = async () => {
  return get(GET_CALCULATOR_VERSION_URL, true);
};
export const postRerunTestData = async (items, conditionId) => {
  return create(POST_ITEMS_RERUN_URL, { items, conditionId }, true);
};

export const getMarketPlaceItemData = async (itemId) => {
  return get(GET_MARKETPLACE_ITEM_JSON_URL(itemId), true);
};

export const getFilterData = async (dataId) => {
  return get(GET_FILTER_JSON_URL(dataId), true);
};

export const getDisputeData = async (itemId) => {
  return get(GET_DISPUTE_DATA(itemId), true);
};

export const getItemFilterData = async (itemId) => {
  return get(GET_ITEM_FILTERS_STATS(itemId), true);
};

export const getPairsApi = async () => {
  return get(PAIRS_URL, true);
};

export const postPairsApi = async (pair) => {
  return create(PAIRS_URL, pair, true);
};

export const editPairsApi = async (pairId, pair) => {
  return update(PAIRS_URL, pairId, pair, true);
};

export const updatePairsOrderApi = async (
  movedRowNumber,
  placedAfterRowNumber
) => {
  return updateOrder(
    `${PAIRS_URL}/updateOrder`,
    movedRowNumber,
    placedAfterRowNumber,
    true
  );
};

export const deletePairsApi = async (pairId) => {
  return del(PAIRS_URL, pairId, true);
};

export const updateStore = async (storeId, storeObj) => {
  return update(UPDATE_STORE_URL, storeId, storeObj, true);
};

export const getKickedItemsBadWordApi = async (storeId = "") => {
  return get(GET_KICKED_ITEM_BAD_WORDS_URL(storeId), true);
};

export const postTestFilterApi = async (type, body) => {
  return create(GET_TEST_FILTER_URL(type), body, true);
};

export const getMarketPlacesApi = async () => {
  return get(GET_MARKET_PLACES_URL, true);
};

export const editMarketPlacesApi = async (marketPlaceId, marketplace) => {
  return update(GET_MARKET_PLACES_URL, marketPlaceId, marketplace, true);
};

export const getPairCategoriesApi = async () => {
  return get(GET_PAIR_CATEGORIES_URL, true);
};

export const getCategoriesTypeApi = async () => {
  return get(GET_CATEGORIES_TYPE_URL, true);
};

export const getWordBankApi = async (pageSize, pageNumber) => {
  return get(
    `${GET_WORD_ANCHOR_BANK_URL}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
    true
  );
};

export const postWordBankApi = async (word) => {
  return create(GET_WORD_ANCHOR_BANK_URL, word, true);
};

export const editWordBankApi = async (wordId, word) => {
  return update(GET_WORD_ANCHOR_BANK_URL, wordId, word, true);
};

export const deleteWordBankApi = async (wordId) => {
  return del(GET_WORD_ANCHOR_BANK_URL, wordId, true);
};

export const getLuminatiErrorsApi = async () => {
  return get(GET_LUMINATI_ERRORS_URL, true);
};

export const getConditionsApi = async () => {
  return get(GET_CONDITIONS_URL, true);
};

export const editConditionsApi = async (conditionId, condition) => {
  return update(GET_CONDITIONS_URL, conditionId, condition, true);
};

export const postConditionsApi = async (condition) => {
  return create(GET_CONDITIONS_URL, condition, true);
};

export const deleteConditionsApi = async (conditionId) => {
  return del(GET_CONDITIONS_URL, conditionId, true);
};

export const getDisputedItemsApi = async () => {
  return get(GET_DISPUTED_ITEMS_URL, true);
};

export const updateDisputedItemsApi = async (disputedItemId, followUp) => {
  return update(UPDATE_DISPUTED_ITEMS_URL, disputedItemId, followUp, true);
};

export const getRoyalMailPricesApi = async () => {
  return get(GET_ROYAL_MAIL_PRICES_URL, true);
};

export const postRoyalMailPricesApi = async (priceData) => {
  return create(GET_ROYAL_MAIL_PRICES_URL, priceData, true);
};

export const updateRoyalMailPricesApi = async (priceID, priceData) => {
  return update(GET_ROYAL_MAIL_PRICES_URL, priceID, priceData, true);
};

export const deleteRoyalMailPricesApi = async (priceID) => {
  return del(GET_ROYAL_MAIL_PRICES_URL, priceID, true);
};

export const getUnassignedKeyWordsApi = async () => {
  return get(GET_Unassinged_Key_Words_URL, true);
};
export const getByIdUnassignedKeyWordsApi = async (KeyWordID) => {
  return get(GET_Unassinged_Key_Words_By_Id_URL(KeyWordID), true);
};
export const updateUnassignedKeyWordsApi = async (KeyWordID, Data) => {
  return update(GET_Unassinged_Key_Words_URL, KeyWordID, Data, true);
};

export const deleteUnassignedKeyWordsApi = async (KeyWordID) => {
  return del(GET_Unassinged_Key_Words_URL, KeyWordID, true);
};

export const postUnassignedKeyWordsApi = async (Data) => {
  return create(GET_Unassinged_Key_Words_URL, Data, true);
};

export const updateFilterArrayData = async (marketParamObject) => {
  return update(
    UPDATE_FILTER_ARRAY_DATA_URL,
    marketParamObject.marketPlaceItemId,
    marketParamObject,
    true
  );
};
export const updateFilterArrayAddData = async (marketParamObject) => {
  return update(
    UPDATE_FILTER_ARRAY_ADD_DATA_URL,
    marketParamObject.marketPlaceItemId,
    marketParamObject,
    true
  );
};

export const getAllRemovedListingFromDb = (id) => {
  return getById(GET_ALL_REMOVED_LISTING, id, true);
};
export const getBulkUploadFailedItems = (bulkUploadId) => {
  return getById(GET_BULKUPLOAD_FAILED_ITEMS, bulkUploadId, true);
};
export const reprocessSingleItem = (id, bulkUploadId) => {
  return update(UPDATE_ITEM_STATUS_TO_REPROCESS, id, {
    bulkUploadId: bulkUploadId,
  });
};
export const reprocessAllItem = (id) => {
  return update(UPDATE_ITEM_STATUS_TO_REPROCESS_ALL_ITEM, id);
};
export const bulkInsertPairs = (pairsData) => {
  return create(BULK_INSERT_PAIRS_URL, pairsData, true);
};
export const pairsBackUpApi = (shouldTruncate = "") => {
  return get(`${PAIRS_BACK_UP_URL}?truncate=${shouldTruncate}`);
};
export const getSignedUrlToDownloadPairsFileApi = () => {
  return get(GET_SIGNED_URL_TO_DOWNLOAD_PAIRS_FILE);
};

export const addOrganization = (payload) => {
  return create(ORGANIZATION_URL, payload, true);
};
export const getOrganizations = async () => {
  return get(ORGANIZATION_URL, true);
};
export const updateOrganization = async (organizationiD, payload) => {
  return update(ORGANIZATION_URL, organizationiD, payload);
};
export const deleteOrganization = async (organizationiD) => {
  return del(ORGANIZATION_URL, organizationiD, true);
};
