// PairForm.jsx
import React from "react";
import PropTypes from "prop-types";
import PairFormInput from "./PairFormInput";
import { Switch } from "@mui/material";
import "../AdminPairs/AdminPairs.scss";
import { Button } from "react-bootstrap";
import MinimizeIcon from "@mui/icons-material/RemoveSharp";
const PairForm = ({
  selectedPairTypeText,
  pairInput,
  setPairInput,
  handlePairTypeChange,
  pairTypes,
  selectedPairTypeNum,
  handleLogicTypeChange,
  logicTypes,
  selectedLogicType,
  setSelectedPairCategory,
  selectedPairCategory,
  PairCategories,
  isRegexLogic,
  replaceWithInput,
  setReplaceWithInput,
  selectedCategoryType,
  setSelectedCategoryType,
  CategoryType,
  pairDescripition,
  setPairDescripition,
  shouldRelax,
  setShouldRelax,
  handlePairSubmit,
  isEditing,
  submitDisabled,
  handleCancel,
  handleMinimize,
}) => {
  return (
    <div className="innerTable pairWrapper">
      <div className="pairForm">
        <div className="min-btn-parent">
          <Button
            variant="outline-primary"
            className="min-btn"
            onClick={handleMinimize}
          >
            <MinimizeIcon />
          </Button>{" "}
        </div>
        <form onSubmit={handlePairSubmit}>
          <span>
            <label>{`${selectedPairTypeText} Pair`}</label>
            <textarea
              name="pairText"
              type="text"
              placeholder={
                selectedPairTypeText === "SANITISER"
                  ? "ADD DOUBLE COMMAS IN BETWEEN WORDS AND SYMBOLS like: green,,blue,,red,,yellow,,orange,,purple,,pink,,brown,,(,,),,-,,?,,"
                  : "handbag,hand bag,hand-bag"
              }
              value={pairInput}
              onChange={(e) => setPairInput(e.target.value)}
              required={true}
              title="Please enter pair"
              minLength="3"
              maxLength="1000"
            />
          </span>
          <div className="pairFormInput">
            <PairFormInput
              label="Pair Type"
              inputType="select"
              selectedValue={selectedPairTypeNum}
              onSelectChange={handlePairTypeChange}
              options={pairTypes}
            />
            <PairFormInput
              label="Logic Type"
              inputType="select"
              selectedValue={selectedLogicType}
              onSelectChange={handleLogicTypeChange}
              options={logicTypes}
            />
          </div>
          <div className="pairFormInput">
            <PairFormInput
              label="Pair Category"
              inputType="select"
              selectedValue={selectedPairCategory}
              onSelectChange={(e) => setSelectedPairCategory(e.target.value)}
              options={PairCategories}
            />
            {isRegexLogic && (
              <PairFormInput
                label="Replace With"
                value={replaceWithInput}
                onChange={(e) => setReplaceWithInput(e.target.value)}
                required={isRegexLogic}
                minLength="1"
                maxLength="100"
              />
            )}
          </div>
          <div className="pairFormInput">
            <PairFormInput
              label="Category Type"
              inputType="select"
              selectedValue={selectedCategoryType}
              onSelectChange={(e) => setSelectedCategoryType(e.target.value)}
              options={CategoryType}
            />
          </div>
          <div className="pairFormInput" style={{ display: "block" }}>
            <div>
              <label htmlFor="description">Description</label>
              <textarea
                name="Description"
                type="text"
                placeholder="Enter Regex Descripition"
                value={pairDescripition}
                onChange={(e) => setPairDescripition(e.target.value)}
                required={true}
                title="Please enter Description"
                minLength="1"
                maxLength="8000"
              />
            </div>
          </div>
          <div className="pairFormInput">
            <div>
              <label htmlFor="shouldRelax">Should Relax</label>
              <Switch
                checked={shouldRelax}
                onClick={() => setShouldRelax(!shouldRelax)}
                inputProps={{ "aria-label": "controlled" }}
                size="large"
              />
            </div>
          </div>
          <div className="pairFormInput">
            <input
              type="submit"
              value={isEditing ? "Save changes" : "Add Pair"}
              className="pairSubmit"
              disabled={submitDisabled}
            />
            <input
              type="button"
              className="pairSubmit"
              value="Cancel"
              onClick={handleCancel}
              disabled={submitDisabled}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
PairForm.propTypes = {
  selectedPairTypeText: PropTypes.string.isRequired,
  pairInput: PropTypes.string.isRequired,
  setPairInput: PropTypes.func.isRequired,
  handlePairTypeChange: PropTypes.func.isRequired,
  pairTypes: PropTypes.array.isRequired,
  selectedPairTypeNum: PropTypes.number.isRequired,
  handleLogicTypeChange: PropTypes.func.isRequired,
  logicTypes: PropTypes.array.isRequired,
  selectedLogicType: PropTypes.string.isRequired,
  setSelectedPairCategory: PropTypes.func.isRequired,
  selectedPairCategory: PropTypes.string.isRequired,
  PairCategories: PropTypes.array.isRequired,
  isRegexLogic: PropTypes.bool.isRequired,
  replaceWithInput: PropTypes.string.isRequired,
  setReplaceWithInput: PropTypes.func.isRequired,
  selectedCategoryType: PropTypes.string.isRequired,
  setSelectedCategoryType: PropTypes.func.isRequired,
  CategoryType: PropTypes.string.isRequired,
  pairDescripition: PropTypes.string.isRequired,
  setPairDescripition: PropTypes.func.isRequired,
  shouldRelax: PropTypes.bool.isRequired,
  setShouldRelax: PropTypes.func.isRequired,
  handlePairSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleMinimize: PropTypes.func.isRequired,
};

export default PairForm;
