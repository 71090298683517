import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import {
  isTokenInValid,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";
import {
  getDisputedItemsApi,
  updateDisputedItemsApi,
} from "../../../api/adminDashboardApi";
import "../../../components/ReactDataTable/ReactDataTable.scss";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import RouteNames from "../../../routes/RouteNames";

const AdminDisputedItems = () => {
  const [loading, setLoading] = useState(true);
  const [disputedItemsData, setDisputedItemsData] = useState([]);
  const [followUp, setFollowUp] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);

    const disputedItemsResponse = await getDisputedItemsApi();
    if (disputedItemsResponse.success) {
      setDisputedItemsData(disputedItemsResponse);
      setTotalItems(disputedItemsResponse.data.length);
    }
    setLoading(false);
  };

  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleCheckboxChange = async (itemId, followUp) => {
    try {
      const resp = await updateDisputedItemsApi(itemId, {
        followUp: followUp ? 0 : 1,
      });
      if (resp.success) {
        toastSuccessMessage("Success!");
        setFollowUp((prev) => !prev);
      }
    } catch (error) {}
  };

  const handleNavigate = (itemId) => {
    navigate(`${RouteNames.UserSearchResult.route}/${itemId}`, {
      state: { from: RouteNames.Admin.DisputedItems.route },
    });
  };

  const renderTableRows = () => {
    return disputedItemsData?.data.slice(startIndex, endIndex).map((item) => (
      <tr key={item.id}>
        <td className="dashboard__tableBorder">
          <h3
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => handleNavigate(item.itemId)}
          >
            {item.itemId}
          </h3>
        </td>
        <td className="dashboard__tableBorder">
          <h3>{item.disputedBy}</h3>
        </td>
        <td className="dashboard__tableBorder">
          <h3>{item.disputeReason}</h3>
        </td>
        <td className="dashboard__tableBorder">
          <h3 style={{ wordBreak: "normal" }}>{item.report}</h3>
        </td>
        <td className="dashboard__tableBorder">
          <input
            type="checkbox"
            checked={item.followUp}
            onChange={() => handleCheckboxChange(item.id, item.followUp)}
          />
        </td>
      </tr>
    ));
  };

  const renderTableContent = () => {
    if (loading) {
      return <div>loading...</div>;
    }

    if (disputedItemsData && disputedItemsData.data.length > 0) {
      return (
        <div className="innerTable conditionWrapper">
          <table className="dashboard__tableBorder">
            <thead>
              <tr>
                <th className="dashboard__tableBorder" width="10%">
                  <h1>Item ID</h1>
                </th>
                <th className="dashboard__tableBorder" width="25%">
                  <h1>EMAIL</h1>
                </th>
                <th className="dashboard__tableBorder" width="20%">
                  <h1>Disputed Text</h1>
                </th>
                <th className="dashboard__tableBorder" width="25%">
                  <h1>Description</h1>
                </th>
                <th className="dashboard__tableBorder" width="20%">
                  <h1>Follow Up</h1>
                </th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
          <div className="pagination">
            <CustomPagination
              perPage={itemsPerPage}
              totalPosts={totalItems}
              currentPage={currentPage}
              nextPage={handleNextbtn}
              prevPage={handlePrevbtn}
            />
          </div>
        </div>
      );
    } else {
      return <div>No items found</div>;
    }
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) {
        window.location.href = "/login";
      } else {
        await fetchData();
      }
    }
    verifyToken();
  }, [followUp]);

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Disputed Items</div>
          <AdminNav />
        </div>
        <div className="table-container">{renderTableContent()}</div>
      </section>
    </div>
  );
};

export default AdminDisputedItems;
