import propTypes from "prop-types";

const ProfitCell = ({ row }) => {
  ProfitCell.propTypes = {
    row: propTypes.shape({
      profit: propTypes.number,
    }).isRequired,
  };
  return <div>{row.profit ? `£${row.profit}` : ""}</div>;
};
export default ProfitCell;
