import axios from "axios";
import { BASE_URL } from "../utils/Constants";
import { parseErrors, JWTConfig } from "../utils/utilFunctions";

axios.defaults.baseURL = `${BASE_URL}/`;

export const get = async (url, isAuthenticated = true) => {
  try {
    let res = null;
    if (isAuthenticated) {
      res = await axios.get(url, JWTConfig());
    } else {
      res = await axios.get(url);
    }
    return {
      success: res.data.success,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    return parseErrors(error.response);
  }
};

export const getById = async (url, id, isAuthenticated = true) => {
  try {
    let res = null;
    if (isAuthenticated) {
      res = await axios.get(`${url}/${id}`, JWTConfig());
    } else {
      res = await axios.get(`${url}/${id}`);
    }
    return {
      success: res.data.success,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    return parseErrors(error.response);
  }
};

export const create = async (
  url,
  data,
  isAuthenticated = true,
  contentType = "application/json"
) => {
  try {
    let res = null;
    if (isAuthenticated) {
      res = await axios.post(url, data, JWTConfig(contentType));
    } else {
      res = await axios.post(url, data);
    }
    return {
      success: res.data.success,
      data: res.data.data,
      storeName: data?.code,
      message: res.data.message,
    };
  } catch (error) {
    return parseErrors(error.response);
  }
};

export const update = async (url, id, data, isAuthenticated = true) => {
  try {
    let res = null;
    if (isAuthenticated) {
      res = await axios.put(`${url}/${id}`, data, JWTConfig());
    } else {
      res = await axios.put(`${url}/${id}`, data);
    }
    return {
      success: res.data.success,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    return parseErrors(error.response);
  }
};

export const del = async (url, id, isAuthenticated = true) => {
  try {
    let res = null;
    if (isAuthenticated) {
      res = await axios.delete(`${url}/${id}`, JWTConfig());
    } else {
      res = await axios.delete(`${url}/${id}`);
    }
    return {
      success: res.data.success,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    return parseErrors(error.response);
  }
};

export const updateOrder = async (
  url,
  movedRowNumber,
  placedAfterRowNumber,
  isAuthenticated = true
) => {
  try {
    let res = null;

    if (isAuthenticated) {
      res = await axios.put(
        url,
        { movedRowNumber, placedAfterRowNumber },
        JWTConfig()
      );
    } else {
      res = await axios.put(url, { movedRowNumber, placedAfterRowNumber });
    }
    return {
      success: res.data.success,
      data: res.data.data,
      message: res.data.message,
    };
  } catch (error) {
    return parseErrors(error.response);
  }
};
