import React from "react";
import PropTypes from "prop-types";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./CustomPagination.scss";
import { useScrollBy } from "react-use-window-scroll";
const CustomPagination = ({
  perPage,
  totalPosts,
  currentPage,
  nextPage,
  prevPage,
}) => {
  const scrollBy = useScrollBy();
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / perPage); i++) {
    pageNumbers.push(i);
  }
  return totalPosts > 0 ? (
    <div className="searchCardPagination">
      <span className="numberOfPages">
        {" "}
        {currentPage * perPage + 1 - perPage} -{" "}
        {totalPosts > currentPage * perPage
          ? currentPage * perPage
          : totalPosts}{" "}
        <i>of {totalPosts}</i>{" "}
      </span>
      <span className="PaginationButtons">
        <button
          onClick={() => {
            scrollBy({
              top:
                document.body.scrollHeight ||
                document.documentElement.scrollHeight,
              behavior: "smooth",
            });
            prevPage(currentPage);
          }}
          disabled={totalPosts === 0 || currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={() => nextPage(currentPage)}
          disabled={
            totalPosts === 0 || Math.ceil(totalPosts / perPage) === currentPage
          }
        >
          <FaChevronRight />
        </button>
      </span>
    </div>
  ) : (
    ""
  );
};
CustomPagination.propTypes = {
  perPage: PropTypes.number.isRequired,
  totalPosts: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
};
export default CustomPagination;
