import propTypes from "prop-types";
import RouteNames from "../../routes/RouteNames";

const MarketPlaceNameCell = ({ row, data }) => (
  <div>
    <h3>
      <a
        className="row_marketPlaceName"
        rel="noopener noreferrer"
        target="_blank"
        href={`${RouteNames.Admin.MarketPlaceItemData.route.replace(
          ":marketPlaceItemId",
          row.marketPlaceItemId
        )}?name=${row.marketPlaceName}&code=${data.uniqueCode}`}
      >
        {row.marketPlaceName}
      </a>
    </h3>
  </div>
);

MarketPlaceNameCell.propTypes = {
  row: propTypes.shape({
    marketPlaceItemId: propTypes.string.isRequired,
    marketPlaceName: propTypes.string.isRequired,
  }).isRequired,
  data: propTypes.shape({
    uniqueCode: propTypes.string.isRequired,
  }).isRequired,
};

export default MarketPlaceNameCell;
