import propTypes from "prop-types";

const NonFloatedPriceCell = ({ row }) => {
  NonFloatedPriceCell.propTypes = {
    row: propTypes.shape({
      nonAdjustedPrice: propTypes.number, // Adjust the prop type accordingly
    }).isRequired,
  };
  return <div>{row.nonAdjustedPrice ? `£${row.nonAdjustedPrice}` : ""}</div>;
};

export default NonFloatedPriceCell;
