import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Box, Button, SvgIcon, Input } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AdminNav from "../../../components/AdminNav/AdminNav";
import Header from "../../../components/Header/Header";
import {
  isTokenInValid,
  toastErrMessage,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";
import "./AdminOrganization.scss";
import { Modal } from "../../../components/Modal";
import { PointerBox } from "../../../components";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";

import {
  getOrganizations,
  deleteOrganization,
  updateOrganization,
  addOrganization,
} from "../../../api/adminDashboardApi";

import { AI_DEFAULT_VALUES } from "../../../utils/Constants";

const AdminOrganization = () => {
  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
    }
    verifyToken();
  }, []);

  return (
    <Box className="adminApiStats dashboard">
      <Box className="dashboard__container">
        <Header isAuthenticated={true} />
      </Box>
      <section className="dashboard__results">
        <Box className="dashboard__nav">
          <Box className="dashboard__headingSec">Organization</Box>
          <AdminNav />
        </Box>
        <OrganizationDataGrid />
      </section>
    </Box>
  );
};

export default AdminOrganization;

function OrganizationDataGrid() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const initData = async () => {
    const response = await getOrganizations();
    setData(response.data || []);
  };

  useEffect(() => {
    initData();
  }, []);

  const deleteOrganizationHandle = async (organizationId) => {
    const response = await deleteOrganization(organizationId);
    if (response.success) {
      await initData();
      toastSuccessMessage("Organization access updated successfully");
    } else {
      toastErrMessage(`Error: ${response.message}`);
    }
  };
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Organization Name",
      width: 150,
    },
    {
      field: "marketplaceSettings",
      headerName: "Marketplace Settings",
      width: 200,
      renderCell: (params) => (
        <TableCellWrapper>
          <MarketplaceItemWrapper name="Ebay" value={params.value.ebay} />
          <MarketplaceItemWrapper
            name="Facebook"
            value={params.value.facebook}
          />
          <MarketplaceItemWrapper name="Depop" value={params.value.depop} />
        </TableCellWrapper>
      ),
    },
    {
      field: "postageFeeSettings",
      headerName: "Postage Fee",
      width: 700,
      renderCell: (params) => (
        <TableCellWrapper>
          <FeeItemWrapper
            name="Paypal % of mean"
            value={params.value.paypal.percentageOfMean}
          />
          <FeeItemWrapper
            name="Paypal offset"
            value={params.value.paypal.offset}
          />
          <FeeItemWrapper name="Insertion" value={params.value.insertion} />
          {params.value.delivery.fixed ? (
            <FeeItemWrapper
              name="Delivery Fee"
              value={params.value.delivery.value}
            />
          ) : null}
        </TableCellWrapper>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 110,
      renderCell: (params) => (
        <PointerBox>
          <Box
            onClick={() => {
              setOrganizationData(params.row);
              setAdd(false);
              setOpen(true);
            }}
          >
            <SvgIcon>
              <EditIcon />
            </SvgIcon>
          </Box>
        </PointerBox>
      ),
    },
    {
      field: "activate",
      headerName: "Activate",
      width: 110,
      renderCell: (params) => {
        const organizationId = params.row.id;
        // Check if organizationId is equal to 1, and conditionally render the button
        if (organizationId === 1) {
          return (
            <PointerBox>
              <ToggleButton />
            </PointerBox>
          );
        } else {
          return (
            <PointerBox>
              <ToggleButton
                onToggle={() => deleteOrganizationHandle(organizationId)}
                isActive={params.row.isActive}
              />
            </PointerBox>
          );
        }
      },
    },
  ];
  const [add, setAdd] = useState(true);
  const orgInitData = {
    id: 0,
    name: "",
    marketplaceSettings: {
      ebay: 1,
      facebook: 1,
      depop: 1,
    },
    postageFeeSettings: {
      delivery: {
        fixed: false,
        value: 4,
      },
      insertion: 0,
      paypal: {
        percentageOfMean: 0.034,
        offset: 0.2,
      },
    },
    isAiEnabled: 0,
    aiSettings: {
      searchAI: { gptModel: "", systemPrompts: "", isSearchAIEnable: false },
      filterAI: { gptModel: "", systemPrompts: "", isFilterAIEnable: false },
    },
  };
  const [organizationData, setOrganizationData] = useState(orgInitData);

  return (
    <Box sx={{ width: "100%" }}>
      <ActionWrapper>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setOrganizationData(orgInitData);
            setAdd(true);
            setOpen(true);
          }}
          variant="contained"
        >
          Add Organization
        </Button>
      </ActionWrapper>

      <DataGrid
        rows={data}
        columns={columns}
        autoHeight={true}
        hideFooterPagination={true}
        disableRowSelectionOnClick
      />
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        title={`${add ? "Add" : "Edit"} Organization`}
        content={
          <AddAndEditModal
            add={add}
            data={organizationData}
            setData={setOrganizationData}
            refreshData={initData}
            setOpen={setOpen}
          />
        }
      />
    </Box>
  );
}

const AddAndEditModal = ({
  add = true,
  data,
  setData,
  refreshData,
  setOpen = { setOpen },
}) => {
  // Initialize the state with default values for both searchAI and filterAI fields
  const [aiSettings, setAISettings] = useState({
    searchAI: {
      gptModel: data.aiSettings.searchAI.gptModel || "gpt-4o-mini",
      systemPrompts:
        data.aiSettings.searchAI.systemPrompts ||
        "The GPT is designed to enhance search terms for items on online marketplaces like eBay. When given a search term, it will spell-check the input and enrich it with additional helpful data, such as the brand, to improve the accuracy of the search results. If the search includes a model number, the model number should be moved in the returned result to the optimum position within the search query. If the search term includes a quantity that is less than two, this should be removed. The GPT will not ask for clarification or further details, or ask 'did you mean' but will directly return what it believes is the improved search term.",
    },
    filterAI: {
      gptModel: data.aiSettings.filterAI.gptModel || "gpt-4o",
      systemPrompts:
        data.aiSettings.filterAI.systemPrompts ||
        "When given a 'Search' term and a list of 'Results', which are product listing titles from an online marketplace, the GPT's task is to analyze the list and return a subset of that list it determines best matches the 'Search' term. The process involves understanding the intent behind the search term, parsing the context of each result, and applying relevance criteria to filter the results accordingly, focusing on accuracy, relevance, and discerning nuances in language. The GPT should handle variations in product descriptions, deal with ambiguity in search terms, and ensure a broad yet relevant match. If a model number is provided, only results that match the search should be returned, similar models should not be included. For clothing items, the GPT will ignore color and size in its filtering process. For example, a search for 'Black XL Helly Hansen T-shirt' should include results like 'White M Helly Hansen T-shirt', as color and size are not considered reasons to exclude a listing. The GPT is not to perform external searches or provide opinions on the products but strictly to filter the given list based on the search term provided. The output format is text, listing only the titles of the products that match the search term without including additional items or accessories not specified by the user.",
    },
  });

  const handleChange = (type, field, value) => {
    setAISettings((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [field]: value,
      },
    }));
    aiSettingsHandle(type, field, value); // Call handler function with updated value
  };

  const handleAiToggle = () => {
    setData((prevData) => {
      const newAiEnabledValue = prevData.isAiEnabled === 1 ? 0 : 1;
      return {
        ...prevData,
        isAiEnabled: newAiEnabledValue,
      };
    });
  };

  const saveDataHandle = async () => {
    const newData = { ...data };
    const orgId = newData.id;
    delete newData.id;
    if (add) {
      const response = await addOrganization(newData);
      if (response.success) {
        await refreshData();
        toastSuccessMessage("Organization Added successfully");
        setOpen(false);
      } else {
        toastErrMessage(`Error: ${response.message}`);
      }
    } else {
      const response = await updateOrganization(orgId, newData);
      if (response.success) {
        await refreshData();
        toastSuccessMessage("Organization updated successfully");
        setOpen(false);
      } else {
        toastErrMessage(`Error: ${response.message}`);
      }
    }
  };

  const marketplaceSettingsHandle = (marketplace) => {
    setData((prevData) => {
      return {
        ...prevData,
        marketplaceSettings: {
          ...prevData.marketplaceSettings,
          [marketplace]:
            prevData.marketplaceSettings[marketplace] === 1 ? 0 : 1,
        },
      };
    });
  };

  const postageSettingsHandle = (firstObj, secondObj, value) => {
    const obj = (prevData) =>
      secondObj
        ? {
            [firstObj]: {
              ...prevData.postageFeeSettings[firstObj],
              [secondObj]: Number(value),
            },
          }
        : {
            [firstObj]: Number(value),
          };
    setData((prevData) => {
      return {
        ...prevData,
        postageFeeSettings: {
          ...prevData.postageFeeSettings,
          ...obj(prevData),
        },
      };
    });
  };

  const aiSettingsHandle = (mainObj, subObj, value) => {
    setData((prevData) => {
      return {
        ...prevData,
        aiSettings: {
          ...prevData.aiSettings,
          [mainObj]: {
            ...prevData.aiSettings[mainObj],
            [subObj]: value,
          },
        },
      };
    });
  };

  const MktButton = ({ marketplace }) => {
    const mkt = marketplace.toLowerCase();
    MktButton.propTypes = {
      marketplace: PropTypes.string.isRequired,
    };
    return (
      <Button
        onClick={() => marketplaceSettingsHandle(mkt)}
        variant="contained"
        sx={[
          {
            backgroundColor:
              data.marketplaceSettings[mkt] === 1 ? "green" : "#ef5a5a",
            "&:hover": {
              backgroundColor:
                data.marketplaceSettings[mkt] === 1 ? "green" : "#ef5a5a",
            },
          },
        ]}
      >
        {marketplace}
      </Button>
    );
  };

  AddAndEditModal.propTypes = {
    add: PropTypes.bool,
    data: PropTypes.any,
    setData: PropTypes.func,
    refreshData: PropTypes.func,
    setOpen: PropTypes.func,
  };

  useEffect(() => {
    aiSettingsHandle(
      AI_DEFAULT_VALUES.SEARCH_AI,
      AI_DEFAULT_VALUES.GPT_MODEL,
      aiSettings.searchAI.gptModel
    );
    aiSettingsHandle(
      AI_DEFAULT_VALUES.SEARCH_AI,
      AI_DEFAULT_VALUES.SYSTEM_PROMPTS,
      aiSettings.searchAI.systemPrompts
    );
    aiSettingsHandle(
      AI_DEFAULT_VALUES.FILTER_AI,
      AI_DEFAULT_VALUES.GPT_MODEL,
      aiSettings.filterAI.gptModel
    );
    aiSettingsHandle(
      AI_DEFAULT_VALUES.SEARCH_AI,
      AI_DEFAULT_VALUES.SYSTEM_PROMPTS,
      aiSettings.filterAI.systemPrompts
    );
  }, []);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ paddingTop: "20px" }}>Organization Name</Box>
      <Input
        type="string"
        placeholder="Organization Name"
        value={data.name}
        onChange={(e) =>
          setData((prevData) => {
            return {
              ...prevData,
              name: e.target.value,
            };
          })
        }
      />
      <Box sx={{ paddingTop: "20px" }}>Marketplace Settings</Box>
      <TableCellWrapper>
        <MktButton marketplace={"Ebay"} />
        <MktButton marketplace={"Facebook"} />
        <MktButton marketplace={"depop"} />
      </TableCellWrapper>

      <Box sx={{ paddingTop: "20px" }}>Postage Settings</Box>
      <Box sx={{ display: "flex", gridGap: "40px", flexDirection: "column" }}>
        <TableCellWrapper>
          <FeeItemActionWrapper
            name="Paypal Percentage of Mean"
            value={data.postageFeeSettings.paypal.percentageOfMean}
            onChange={(value) =>
              postageSettingsHandle("paypal", "percentageOfMean", value)
            }
            setData={setData}
          />
          <FeeItemActionWrapper
            name="Paypal Offset"
            value={data.postageFeeSettings.paypal.offset}
            onChange={(value) =>
              postageSettingsHandle("paypal", "offset", value)
            }
            setData={setData}
          />
          <FeeItemActionWrapper
            name="Insertion"
            value={data.postageFeeSettings.insertion}
            onChange={(value) =>
              postageSettingsHandle("insertion", null, value)
            }
          />
        </TableCellWrapper>
        <TableCellWrapper>
          <Box>
            <Box>Delivery fixed? otherwise calculated through royal mail.</Box>
            <ToggleButton
              onToggle={() =>
                postageSettingsHandle(
                  "delivery",
                  "fixed",
                  !data.postageFeeSettings.delivery.fixed
                )
              }
              isActive={data.postageFeeSettings.delivery.fixed}
            />
          </Box>

          {data.postageFeeSettings.delivery.fixed ? (
            <FeeItemActionWrapper
              name="Delivery Fee"
              value={data.postageFeeSettings.delivery.value}
              onChange={(value) =>
                postageSettingsHandle("delivery", "value", value)
              }
            />
          ) : null}
        </TableCellWrapper>
        <TableCellWrapper>
          <Box>
            <Box>Enable Thrift AI</Box>
            <ToggleButton
              onToggle={handleAiToggle}
              isActive={data.isAiEnabled === 1}
            />
          </Box>
        </TableCellWrapper>
        {data.isAiEnabled === 1 && (
          <div>
            {/* SearchAI Section */}
            <TableCellWrapper>
              <Box>
                <Box>Search Improver Filter</Box>
                <Box>Enable Thrift search Improvement AI</Box>
                <ToggleButton
                  onToggle={() =>
                    aiSettingsHandle(
                      "searchAI",
                      "isSearchAIEnable",
                      !data.aiSettings.searchAI.isSearchAIEnable
                    )
                  }
                  isActive={data.isAiEnabled}
                />
                {data.isAiEnabled && (
                  <div>
                    <Box>GPT Model</Box>
                    <input
                      value={aiSettings.searchAI.gptModel}
                      onChange={(e) =>
                        handleChange(
                          AI_DEFAULT_VALUES.SEARCH_AI,
                          AI_DEFAULT_VALUES.GPT_MODEL,
                          e.target.value
                        )
                      }
                      type="text"
                      style={{ padding: "3px" }}
                    />
                    <Box>System Prompt</Box>
                    <textarea
                      value={aiSettings.searchAI.systemPrompts}
                      onChange={(e) =>
                        handleChange(
                          AI_DEFAULT_VALUES.SEARCH_AI,
                          AI_DEFAULT_VALUES.SYSTEM_PROMPTS,
                          e.target.value
                        )
                      }
                      style={{ padding: "3px" }}
                    />
                  </div>
                )}
              </Box>
            </TableCellWrapper>

            {/* FilterAI Section */}
            <TableCellWrapper>
              <Box style={{ marginTop: "20px" }}>
                <Box>Item Filter</Box>
                <Box>Enable Thrift Filter Improvement AI</Box>
                <ToggleButton
                  onToggle={() =>
                    aiSettingsHandle(
                      AI_DEFAULT_VALUES.FILTER_AI,
                      "isFilterAIEnable",
                      !data.aiSettings.filterAI.isFilterAIEnable
                    )
                  }
                  isActive={data.isAiEnabled}
                />
                {data.isAiEnabled && (
                  <Box>
                    <Box>GPT Model</Box>
                    <input
                      value={aiSettings.filterAI.gptModel}
                      onChange={(e) =>
                        handleChange(
                          AI_DEFAULT_VALUES.FILTER_AI,
                          AI_DEFAULT_VALUES.GPT_MODEL,
                          e.target.value
                        )
                      }
                      type="text"
                      style={{ padding: "3px" }}
                    />
                    <Box>System Prompt</Box>
                    <textarea
                      value={aiSettings.filterAI.systemPrompts}
                      onChange={(e) =>
                        handleChange(
                          AI_DEFAULT_VALUES.FILTER_AI,
                          AI_DEFAULT_VALUES.SYSTEM_PROMPTS,
                          e.target.value
                        )
                      }
                      style={{ padding: "3px" }}
                    />
                  </Box>
                )}
              </Box>
            </TableCellWrapper>
          </div>
        )}
      </Box>
      <Button
        onClick={saveDataHandle}
        variant="contained"
        color="primary"
        sx={{ width: "100%", marginTop: "10px" }}
      >
        Save
      </Button>
    </Box>
  );
};

const ActionWrapper = ({ children }) => (
  <Box sx={{ paddingBottom: "10px" }}>{children}</Box>
);

ActionWrapper.propTypes = {
  children: PropTypes.node.isRequired, // Use the appropriate PropTypes validation
};
const MarketplaceItemWrapper = ({ name, value }) => {
  MarketplaceItemWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  };
  return (
    <Box
      sx={{
        backgroundColor: value === 1 ? "green" : "#ef5a5a",
        color: "white",
        borderRadius: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ padding: "6px 10px" }}>{name}</Box>
    </Box>
  );
};

const FeeItemWrapper = ({ name, value }) => {
  FeeItemWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
  };
  return (
    <Box
      sx={{
        borderRadius: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #0F66FF",
      }}
    >
      <Box
        sx={{ padding: "5px 15px", backgroundColor: "#0F66FF", color: "white" }}
      >
        {name}
      </Box>
      <Box sx={{ padding: "5px" }}>
        {value} {` £`}
      </Box>
    </Box>
  );
};

const TableCellWrapper = ({ children }) => {
  TableCellWrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gridGap: "10px",
      }}
    >
      {children}
    </Box>
  );
};

const FeeItemActionWrapper = ({ name, value, onChange }) => {
  FeeItemActionWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
  };
  return (
    <Box
      sx={{
        borderRadius: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          padding: "5px 15px",
          backgroundColor: "#1565c0",
          color: "white",
          borderRadius: "3px 0px 0px 3px",
        }}
      >
        {name}
      </Box>
      <Box>
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type="number"
          style={{ padding: "3px" }}
        />
        {` £`}
      </Box>
    </Box>
  );
};
