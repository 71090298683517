import { socketActions } from "./actionTypes";

function initSocket() {
  return {
    type: socketActions.INIT_SOCKET,
  };
}

function closeSocket() {
  return {
    type: socketActions.CLOSE_SOCKET,
  };
}

function addSocket(socket) {
  return {
    type: socketActions.ADD_SOCKET,
    payload: {
      socket,
    },
  };
}

export { initSocket, closeSocket, addSocket };
