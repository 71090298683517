import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import Routes from "./RouteNames";
import { isTokenInValid, isInRole } from "../utils/utilFunctions";

const AuthenticateRoute = ({ defaultProps, Component, Roles }) => {
  return isTokenInValid() ? (
    <Navigate to={Routes.Login.route} />
  ) : isInRole(Roles) ? (
    <Component {...defaultProps} />
  ) : (
    <Navigate to={Routes.NotFound.route} />
  );
};
AuthenticateRoute.propTypes = {
  defaultProps: PropTypes.object,
  Component: PropTypes.elementType.isRequired,
  Roles: PropTypes.arrayOf(PropTypes.string),
};
export default AuthenticateRoute;
