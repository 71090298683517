import React, { useEffect } from "react";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { isTokenInValid, amplitudeLog } from "../../../utils/utilFunctions";
import { getAdminStatsApi } from "../../../api/adminDashboardApi";
import "./AdminApiStats.scss";
const AdminApiStats = () => {
  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
    }
    verifyToken();
    amplitudeLog(
      "AdminDashboard",
      "Check Admin api stats",
      `User check the Admin Api stats`
    );
  }, []);

  const columns = [
    {
      name: "Date",
      selector: "date",
      grow: 1.2,
      wrap: true,
    },
    {
      name: "Marketplace",
      selector: "name",
      grow: 1.2,
      wrap: true,
    },
    {
      name: "Total Requests",
      selector: "totalRequest",
      center: true,
    },
    {
      name: "Total Responses",
      selector: "totalResponses",
      center: true,
    },
    {
      name: "Completed",
      selector: "completed",
      hide: "sm",
      grow: 1.5,
      center: true,
    },
    {
      name: "No Result",
      selector: "noResult",
      hide: "sm",
      wrap: true,
      center: true,
    },
    {
      name: "Unknown Errors",
      selector: "unknownError",
      hide: "sm",
      wrap: true,
      center: true,
    },
    {
      name: "Pending",
      selector: "pending",
      center: true,
      hide: "sm",
    },
    {
      name: "Failed Submission",
      selector: "failed",
      center: true,
      hide: "sm",
    },
    {
      name: "Failed Response",
      selector: "failedResponse",
      center: true,
      hide: "sm",
    },
    {
      name: "Avg Time Per Request",
      selector: (row) =>
        row.avgTimePerRequest ? row.avgTimePerRequest.slice(0, 8) : "",
      hide: "sm",
      wrap: true,
      center: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "unset",
        minHeight: "unset",
        marginBottom: "3rem",
      },
    },
    rows: {
      style: {
        minHeight: "unset",
        marginBottom: "3rem",

        "&:not(:last-of-type)": {
          border: "unset",
        },
        ":nth-child(even)": {
          background: "#efefef",
        },
        "&:last-of-type": {
          marginBottom: "4rem",
        },
      },
    },
    headCells: {
      style: {
        padding: "unset",
        fontSize: "1.6rem",
        fontWeight: "800",
        color: "#262626",
      },
    },
    cells: {
      style: {
        padding: "unset",
        color: "#262626",
        fontSize: "1.4rem",
      },
    },
    pagination: {
      style: {
        borderTopColor: "#6D86D6",
        padding: "0",
        justifyContent: "unset",
      },
    },
  };
  return (
    <div className="adminApiStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">API Stats</div>
          <AdminNav />
        </div>
        <div className="apiStatsWrapepr">
          <ReactDataTable
            viewName={"AdminApiStats"}
            columns={columns}
            customStyles={customStyles}
            api={getAdminStatsApi}
            sorting={false}
            defaultSortBy="id"
            sortOrder="desc"
            filtering={false}
          />
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default AdminApiStats;
