import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";
import {
  toastErrMessage,
  isTokenInValid,
  isInRole,
  amplitudeLog,
} from "../../utils/utilFunctions";
import { STORE_TYPE } from "../../utils/Constants";
import { loginApi } from "../../api/authApi";

import InputField from "../../components/InputField/InputField";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import Header from "../../components/Header/Header";

import "./Login.scss";

const Login = (props) => {
  let navigate = useNavigate();
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [loggingIn, setLoggingIn] = useState(false);
  const [success, setSuccess] = useState(false);

  const onLoginSubmit = async (e) => {
    e.preventDefault();

    if (code && password) {
      setSuccess(false);
      setLoggingIn(true);

      const response = await loginApi({ code, password });
      setLoggingIn(false);
      if (response.success) {
        if (window.FS) {
          window.FS.identify(window.btoa(code + response.data.id), {
            displayName: code,
          });
        }

        localStorage.setItem(
          "isEnableNavigation",
          response.data.isEnableNavigation
        );
        localStorage.setItem("navigationLink", response.data.navigationLink);
        localStorage.setItem("storeId", response.data.id);
        localStorage.setItem("storeName", response.storeName);

        amplitudeLog("Login", "Successful login  ", `User login Successfully`); // groupnme/event name/ message
        setSuccess(true);
      } else {
        amplitudeLog("Login", "Failed  login  ", `User login failed`); // groupnme/event name/ message
        toastErrMessage(response.message);
      }
    } else {
      const errorMessage =
        !code && !password
          ? "Please enter code & password." // if  both missing
          : code
          ? "Please enter password."
          : "Please enter code.";
      toastErrMessage(errorMessage);
    }
  };

  const onCodeChange = (value) => {
    setCode(value);
    const submitValid =
      value.length >= 3 &&
      value.length <= 50 &&
      password &&
      password.length >= 4 &&
      password.length <= 20;
    setSubmitDisabled(!submitValid);
  };

  const onPasswordChange = (value) => {
    setPassword(value);
    const submitValid =
      value.length >= 4 &&
      value.length <= 20 &&
      code &&
      code.length >= 3 &&
      code.length <= 50;
    setSubmitDisabled(!submitValid);
  };

  const redirectTo = () => {
    if (isInRole([STORE_TYPE.ADMIN])) {
      return RouteNames.Admin.Results.route;
    } else {
      return RouteNames.Dashboard.route;
    }
  };
  useEffect(() => {
    amplitudeLog(
      "login",
      "View Login page ",
      `The Login page loads for the user`
    ); // groupnme/event name/ message
    async function verifyToken() {
      const res = await isTokenInValid();
      if (!res) {
        navigate(redirectTo());
      }
    }
    verifyToken();
  }, []);

  return success ? (
    <Navigate to={redirectTo()} />
  ) : (
    <div className="login">
      <Header />
      <form className="login__form" onSubmit={onLoginSubmit}>
        <div className="login__heading">Welcome back</div>
        <InputField
          placeholder="Store code"
          value={code}
          type="text"
          onChange={onCodeChange}
          required={true}
          className="login__code"
          title="Please enter store code"
          customProps={{
            autoComplete: "username",
            minLength: "3",
            maxLength: "50",
          }}
        />
        <InputField
          placeholder="Password"
          value={password}
          type="password"
          onChange={onPasswordChange}
          required={true}
          className="login__password"
          title="Please enter password"
          customProps={{
            autoComplete: "current-password",
            minLength: "4",
            maxLength: "20",
          }}
        />
        {/* <a href="" className="login__forgot">
            Forgot password
        </a> */}
        <SubmitButton
          text={loggingIn ? "Logging..." : "LOGIN"}
          type="submit"
          disabled={submitDisabled}
          className={`login__submit ${
            !submitDisabled ? "login__submit--enabled" : ""
          }`}
        />
      </form>
    </div>
  );
};

export default Login;
