import React from "react";
import PropTypes from "prop-types";
import Storage from "../../utils/WebStorage";
import { useNavigate, Link } from "react-router-dom";
import ArrowBack from "../../assets/images/unionblack.svg";
import LogoBlack from "../../assets/images/logoBlack.svg";
import LogoSecondary from "../../assets/images/logoSecondary.png";
import Union from "../../assets/images/union.svg";
import LogoXS from "../../assets/images/logo-xs.png";
import Routes from "../../routes/RouteNames";
import { amplitudeLog } from "../../utils/utilFunctions";
import "./Header.scss";

const Header = ({
  isAuthenticated = false,
  black_bg,
  setOnQuery,
  showBackBtn,
  setShowBackbtn,
}) => {
  const navigate = useNavigate(); // Renamed the variable to avoid conflicts with 'history'

  const logout = () => {
    amplitudeLog("Logging out", "Logging out successfully", "");
    localStorage.clear();
    Storage.removeAuthToken();
  };

  const abouthandle = (e) => {
    e.preventDefault();
    navigate(Routes.AboutUs.route);
    amplitudeLog(
      "Product search ",
      "Selecting about",
      "User Click on About us button"
    );
  };

  return (
    <div className={`header ${isAuthenticated ? "header__xs" : ""}`}>
      {isAuthenticated && showBackBtn ? (
        <div>
          <div
            className="arrow-back_mobile"
            onClick={() => setOnQuery(true)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setOnQuery(true);
              }
            }}
          >
            <Link to="/dashboard">
              <img src={ArrowBack} alt="THRIFT" />
            </Link>
          </div>

          <Link to="/" className="header__icon hide-mobile">
            <picture>
              <source
                media="(min-width: 425px)"
                srcSet={black_bg ? LogoBlack : LogoSecondary}
              />
              <img src={black_bg ? Union : LogoXS} alt="THRIFT" />
            </picture>
          </Link>
        </div>
      ) : isAuthenticated && !showBackBtn ? (
        <Link to="/" className="header__icon">
          <picture>
            <source
              media="(min-width: 640px)"
              srcSet={black_bg ? LogoBlack : LogoSecondary}
            />
            <img src={black_bg ? Union : LogoXS} alt="THRIFT" />
          </picture>
        </Link>
      ) : (
        <Link to="/">
          <img
            src={LogoSecondary}
            alt="THRIFT"
            style={{ marginBottom: black_bg ? "-15px" : 0 }}
          />
        </Link>
      )}
      <div style={{ marginTop: "auto" }}>
        {isAuthenticated && (
          <span className={black_bg ? "toRightNav_black" : "toRightNav"}>
            <Link to="#" onClick={abouthandle}>
              About
            </Link>
            <Link to="/login" onClick={() => logout()}>
              Logout
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};
Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  black_bg: PropTypes.any,
  setOnQuery: PropTypes.func,
  showBackBtn: PropTypes.any,
  setShowBackbtn: PropTypes.func,
};
export default Header;
