import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { isTokenInValid } from "../../../utils/utilFunctions";
import { getFilterData } from "../../../api/adminDashboardApi";
import CopyContent from "../../../components/copyContent/copyContent";
import "./AdminFilterJson.scss";

const AdminFilterJson = () => {
  const { dataId } = useParams();
  const [filterData, setFilterData] = useState({});
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);

  const fetchData = async () => {
    const response = await getFilterData(dataId);
    if (response.success) {
      setFilterData(response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  useEffect(() => {
    if (loading) {
      setContent(<div>loading...</div>);
    } else if (filterData) {
      setContent(
        <pre className="dashboard__json dashboard__json__show">
          {JSON.stringify(filterData, null, 4)}
        </pre>
      );
    } else {
      setContent(<div>No data found</div>);
    }
  }, [loading, filterData]);

  return (
    <div className="adminItemJson dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Filtered Data</div>

          <span className="copy-filter-data-class">
            <CopyContent variant="primary" content={filterData.object} />
          </span>
        </div>

        <div className="dashboard__collapsible"></div>
        {content}
      </section>
      <Footer />
    </div>
  );
};

export default AdminFilterJson;
