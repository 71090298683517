import React from "react";
import PropTypes from "prop-types";
import InputField from "../../../components/InputField/InputField";
import { postDisputeApi } from "../../../api/dashboardApi";

import { Close } from "@mui/icons-material";

import {
  toastErrMessage,
  toastSuccessMessage,
  getFormattedDate,
} from "../../../utils/utilFunctions";
import "./AdminDesputeInfo.scss";

const AdminDesputeInfo = ({
  popupProps: {
    title,
    content,
    extraContent,
    buttonText,
    onChange,
    fieldType,
    onClose,
    userMessage,
  },

  popShow,

  itemId,
  submitDisabled,
  value,
  storeId,
}) => {
  const submitAdditionalNotes = async (_e) => {
    const payload = {
      itemId: itemId,
      description: value,
      storeId: storeId,
    };
    const response = await postDisputeApi(payload);
    if (response.success) {
      toastSuccessMessage("Note added successfully");
      onClose();
    } else {
      toastErrMessage(response.message);
    }
  };

  return (
    <div>
      {popShow && (
        <div className="despute">
          <form className="desputeForm">
            <button className="cancelIconWrapper" onClick={onClose}>
              <div>
                <Close sx={{ color: "gray", fontSize: "2em" }} />
              </div>
            </button>
            <span>
              <h1>{title}</h1>
              {content ? <p>{content}</p> : <br />}
              {/* both comming undefined*/}
              {extraContent ? <p>{extraContent}</p> : <br />}
              <div className="despute__chating-area">
                {userMessage.map((message, i) => {
                  return (
                    <div className="despute__message" id="messageList" key={i}>
                      {message.isUser ? (
                        <>
                          <div className="despute__usermessage">
                            {message.description}
                          </div>
                          <div className="despute__usertime">
                            <h6 className="despute__usertime__report">
                              Report time :
                            </h6>
                            {getFormattedDate(message.createdAt)}
                          </div>
                          <br />
                          <div className="despute__report">
                            {message.report}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="despute__adminHeading">
                            {`Admin Response`}
                          </div>
                          <div className="despute__admintime">
                            <h6 className="despute__admintime__response">
                              Response time :
                            </h6>
                            {getFormattedDate(message.createdAt)}
                          </div>
                          <br />
                          <div className="despute__adminmessage">
                            {message.description}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </span>
            {fieldType === "text" && (
              <InputField
                type="text"
                placeholder="Description"
                name="Description"
                onChange={(e) => onChange(e)}
                value={value}
                className="despute__description"
                customProps={{
                  maxLength: 200,
                }}
              />
            )}
            <input
              type="button"
              className={
                !submitDisabled ? "desputeReport" : "desputeReport--enabled"
              }
              value={buttonText}
              onClick={submitAdditionalNotes}
              disabled={submitDisabled}
            />
          </form>
        </div>
      )}
    </div>
  );
};
AdminDesputeInfo.propTypes = {
  popupProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    extraContent: PropTypes.node,
    buttonText: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fieldType: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    userMessage: PropTypes.string,
  }).isRequired,
  popShow: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  storeId: PropTypes.string.isRequired,
};
export default AdminDesputeInfo;
