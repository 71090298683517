import React from "react";
import PropTypes from "prop-types";
import "./SearchCard.scss";
import Search from "../../assets/images/search.svg";

const SearchCard = ({
  conditionSelected,
  onFindClick,
  onceClicked,
  setShowBackbtn,
}) => {
  return (
    <div
      className={
        "search__card__default " +
        `${conditionSelected && "condition__selected "} ${
          onceClicked && "weak__condition__selected"
        }`
      }
      onClick={() => conditionSelected && onFindClick()}
      onKeyDown={(e) => {
        if (conditionSelected && e.key === "Enter") {
          onFindClick();
        }
      }}
      onChange={() => setShowBackbtn(true)}
    >
      <div className="search__card__default_button_image">
        <img src={Search} width="30px" alt="search" />
      </div>
      <div className="search__card__default_button_text">Search</div>
    </div>
  );
};
SearchCard.propTypes = {
  conditionSelected: PropTypes.any,
  onFindClick: PropTypes.func,
  onceClicked: PropTypes.bool,
  setShowBackbtn: PropTypes.func,
};
export default SearchCard;
