import React from "react";
import PropTypes from "prop-types";
import "./Footer.scss";

import LogoBlack from "../../assets/images/logoBlack.svg";
import LogoSecondary from "../../assets/images/logoSecondary.png";
import Union from "../../assets/images/union.svg";
import LogoXS from "../../assets/images/logo-xs.png";

const Footer = ({ black_bg }) => {
  const fullYear = new Date().getFullYear();
  return (
    <div className="footer">
      <div className="footer__container">
        <picture>
          <source
            media="(min-width: 641px)"
            srcSet={black_bg ? LogoBlack : LogoSecondary}
          />
          <img src={black_bg ? Union : LogoXS} alt="THRIFT" />
        </picture>
        <div className="footer__content">
          <div>
            <a
              href="https://www.wearenova.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cofounded with <b>Nova</b>
            </a>
          </div>
          <div>Thrift {fullYear}</div>
          <div>
            <a
              href="https://thrift-app.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Privacy Statement</b>
            </a>
          </div>
          <div>
            <a
              href="https://thrift-app.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Terms and Conditions</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
Footer.propTypes = {
  black_bg: PropTypes.bool.isRequired,
};
export default Footer;
