import propTypes from "prop-types";

const BrightDataResponseCell = ({ row }) => {
  BrightDataResponseCell.propTypes = {
    row: propTypes.shape({
      brightDataResponse: propTypes.bool,
      responseDateTime: propTypes.string,
    }).isRequired,
  };
  return (
    <div>{row.brightDataResponse && row.responseDateTime ? "Yes" : "No"}</div>
  );
};

export default BrightDataResponseCell;
