import { put, takeEvery } from "redux-saga/effects";
import { io } from "socket.io-client";
import { BASE_URL } from "../../utils/Constants";
import { socketActions } from "../actionTypes";
import { addSocket } from "../actions";

function* initSocketAsync() {
  const storeId = localStorage.getItem("storeId");
  const socket = io(`${BASE_URL}?storeId=${storeId}`, {
    rejectUnauthorized: false,
  });
  yield new Promise((resolve, reject) => {
    socket.on("connect", () => {
      resolve(socket);
    });
  });
  yield put(addSocket(socket));
}

export function* watchInitSocket() {
  yield takeEvery(socketActions.INIT_SOCKET, initSocketAsync);
}
