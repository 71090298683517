import React from "react";
import PropTypes from "prop-types";

const Calender = ({ label, value, setValue, name, ...props }) => {
  return (
    <div>
      <p style={{ fontSize: 12 }}>{label}</p>
      <input
        type="date"
        name={name}
        onChange={setValue}
        value={value}
        {...props}
        style={{
          paddingTop: 12,
          paddingRight: 12,
          paddingBottom: 8,
          paddingLeft: 12,
          width: "100%",
          fontSize: 14,
          border: "1px solid whitesmoke",
          borderRadius: "5px 5px 0px 0px",
        }}
      />
    </div>
  );
};
Calender.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
export default Calender;
