import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Header from "../../../components/Header/Header";
import { isTokenInValid } from "../../../utils/utilFunctions";
import { getMarketPlaceItemData } from "../../../api/adminDashboardApi";
import "./AdminMarketPlaceItemJson.scss";
import CopyContent from "../../../components/copyContent/copyContent";
const AdminMarketPlaceItemJson = () => {
  const search = useLocation().pathname;
  const lastItem = search.substring(search.lastIndexOf("/") + 1);
  const finalUrl = lastItem.slice(5, lastItem.lastIndexOf("&"));

  const { marketPlaceItemId } = useParams();
  const [luminatiResponse, setLuminatiResponse] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hideLuminatiJson, setHideLuminatiJson] = useState(false);
  const [hideFilterJson, setHideFilterJson] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getMarketPlaceItemData(marketPlaceItemId);
      if (response.success) {
        setLuminatiResponse(response.data.luminati);
        setFilteredData(response.data.filtered);
        setLoading(false);
      }
    };

    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, [marketPlaceItemId]);

  const onHideLuminatiJson = () => {
    setHideLuminatiJson(!hideLuminatiJson);
  };

  const onHideFilterJson = () => {
    setHideFilterJson(!hideFilterJson);
  };
  return (
    <div className="adminItemJson dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="marketPlaceName dashboard__headingSec">{finalUrl} </div>

        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Luminati Data</div>
        </div>

        <div className="dashboard__collapsible">
          <b
            role="button"
            onClick={() => onHideLuminatiJson()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onHideLuminatiJson();
              }
            }}
          >
            {hideLuminatiJson ? "Show" : "Hide"} Luminati JSON
          </b>

          <span className="copy-content-class">
            {loading || hideLuminatiJson || luminatiResponse.length <= 0 ? (
              ""
            ) : (
              <CopyContent variant="primary" content={luminatiResponse} />
            )}
          </span>
        </div>

        {loading ? (
          <div>loading...</div>
        ) : !Array.isArray(luminatiResponse) || luminatiResponse.length > 0 ? (
          <pre
            className={`dashboard__json ${
              hideLuminatiJson
                ? "dashboard__json__hide"
                : "dashboard__json__show"
            }`}
          >
            {JSON.stringify(luminatiResponse, null, 4)}
          </pre>
        ) : (
          <div>No data found</div>
        )}
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Filtered Data</div>
        </div>

        <div className="dashboard__collapsible">
          <b
            onClick={() => onHideFilterJson()}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                onHideFilterJson();
              }
            }}
          >
            {hideFilterJson ? "Show" : "Hide"} Filtered JSON
          </b>

          <span className="copy-content-class">
            {loading || hideFilterJson || filteredData.length <= 0 ? (
              ""
            ) : (
              <CopyContent variant="primary" content={filteredData} />
            )}
          </span>
        </div>
        {loading ? (
          <div>loading...</div>
        ) : filteredData && filteredData.length > 0 ? (
          <pre
            className={`dashboard__json ${
              hideFilterJson ? "dashboard__json__hide" : "dashboard__json__show"
            }`}
          >
            {JSON.stringify(filteredData, null, 4)}
          </pre>
        ) : (
          <div>No data found</div>
        )}
      </section>
    </div>
  );
};

export default AdminMarketPlaceItemJson;
