import React from "react";
import Header from "../../components/Header/Header";

import "./WhyThrift.scss";

const WhyThriftPopup = () => {
  const Head = ({ text }) => {
    return <div className="about-us__head">{text}</div>;
  };
  return (
    <div className="about-us">
      <Header
        isAuthenticated={true}
        setOnQuery={() => console.log("")}
        showBackBtn={true}
        setShowBackbtn={() => console.log("")}
      />
      <Head text={"How does THRIFT work?"} />
      <div className="about-us__content">
        <div>
          <p>Thrift always priortises sold pricing before active listings.</p>
        </div>
        <div>
          <p>Thrift reviews hundreds of listings in seconds.</p>
        </div>
        <div>
          <p>Kicks out irrelevant listings and adjusts pricing such as:</p>
          <p className="spacing">- Listing with inflated postage or pricing</p>
          <p>- Sellers who ask for offers</p>
        </div>
        <div>
          <p>
            Thrift works in real time , so we collect up to date data as
            priority
          </p>
          <p className="bold">We do the hard work so you don't have to</p>
        </div>
      </div>
    </div>
  );
};
export default WhyThriftPopup;
