import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export default function Modal({
  open,
  onClose,
  title = null,
  textTitle = null,
  content = null,
  textContent = null,
  action = null,
  closeAction = null,
  maxWidth = "lg",
  closeButton = true,
}) {
  Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    textTitle: PropTypes.string,
    content: PropTypes.node,
    textContent: PropTypes.string,
    action: PropTypes.node,
    closeAction: PropTypes.func,
    maxWidth: PropTypes.string,
    closeButton: PropTypes.bool,
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title">
          {textTitle}
          {title}
          {closeButton && (
            <IconButton
              aria-label="delete"
              onClick={onClose}
              style={{ float: "right" }}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          {textContent && (
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: 12 }}
            >
              {textContent}
            </DialogContentText>
          )}
          {content}
        </DialogContent>

        <DialogActions>
          {closeAction && (
            <Button
              variant="contained"
              style={{ borderRadius: 2, minWidth: 50, padding: "6px 10px" }}
              onClick={onClose}
            >
              Close
            </Button>
          )}
          {action}
        </DialogActions>
      </Dialog>
    </div>
  );
}
