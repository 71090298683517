import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import StandardImageList from "../../../components/StandardImageList/StandardImageList";
import "./UserSearchResult.scss";
import { FullStory } from "@fullstory/browser";
import {
  infoAboutLoggedInUser,
  amplitudeLog,
} from "../../../utils/utilFunctions";
import { Link } from "@mui/material";
import BasicCard from "../../../components/BasicCard/BasicCard";
import BasicMarketSelect from "../../../components/BasicSelect/BasicMarketSelect";
import BasicPriceSelect from "../../../components/BasicSelect/BasicPriceSelect";
import ItemsResultList from "../../../components/ItemsResultList/ItemsResultList";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import AddADispute from "./AddADispute";
import { priceFilters } from "../../../utils/Constants";
import BackIcon from "../../../assets/images/backIcon.svg";

import {
  updateFilterArrayData,
  updateFilterArrayAddData,
} from "../../../api/adminDashboardApi";

import { useMarketplaceData } from "../../../hooks";
import { CopyText } from "../../../components/copyContent/copyContent";
import Routes from "../../../routes/RouteNames";

const UserSearchResult = () => {
  const [render, setRender] = useState(false);

  const history = useNavigate();
  const { itemID } = useParams();
  const [showDisputeModal, setShowDisputeModal] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const loggedInUser = infoAboutLoggedInUser();
  const isEnableNavigation = localStorage.getItem("isEnableNavigation");
  const navigationLink = localStorage.getItem("navigationLink");

  const [priceFilter, setPriceFilter] = useState("default");
  const [marketplaceFilter, setMarketplaceFilter] = useState("all");
  const [updateData, setUpdateData] = useState(false);
  const [updateDropDown, setUpdateDropDown] = useState([
    { name: "All", value: "All" },
  ]);

  const location = useLocation();
  const navigateBack = location.state?.from || "/dashboard";

  const [
    data,
    excludedMarketplaces,
    count,
    maxPrice,
    maxPriceMarketplace,
    dropDownData,
  ] = useMarketplaceData(
    itemID,
    currentPage,
    perPage,
    priceFilter,
    marketplaceFilter,
    updateData,
    setUpdateData
  );

  const images = data?.itemImages || [];
  const uniqueCode = data?.uniqueCode?.toLowerCase() || "";
  const disputeStatus = data?.disputeStatus || 0;
  const marketplaces = data?.marketPlaceItems || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        amplitudeLog(
          "Item Result",
          "View Item Result page ",
          `User view item result pages ${itemID} `,
          {
            itemListing: marketplaces[0]?.itemsBeforeFiltering,
            title: data?.title,
            marketplace: marketplaces[0]?.marketPlace?.name,
            suggestedprice: marketplaces[0]?.meanPrice,
            profit: marketplaces[0]?.profit,
          }
        ); // groupnme/event name/ message
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [data]);

  const title = data?.title;
  const isAiSearch = data?.isAiSearch;

  const setPriceFilterValue = (price) => {
    amplitudeLog(
      "Item results ",
      `Pricing filter `,
      `User select pricing filter `,
      { selectedPrice: price }
    );
    setPriceFilter(price);
    setCurrentPage(1);
  };

  const setMarketplaceFilterValue = (filterValue) => {
    amplitudeLog(
      "Item results ",
      `Marketplace filter `,
      `User select Marketplace filter `,
      { selectedFilter: filterValue }
    );
    setMarketplaceFilter(filterValue);
    setCurrentPage(1);
  };

  const nextPageHandle = (num) => {
    setCurrentPage(num + 1);
    amplitudeLog("Item results ", "Show next page");
  };
  const prevPageHandle = (num) => {
    setCurrentPage(num - 1);
    amplitudeLog("Item results ", "Show previous page");
  };

  const reDirectToAboutUs = () => {
    amplitudeLog("Item results ", "Click here");
    loggedInUser.detail =
      "User has clicked on CLick Here button to view about us page";
    FullStory.event("View About Us", loggedInUser);
    history(Routes.AboutUs.route);
  };
  const removeListingsHandle = async (marketParamObject, removedListItem) => {
    amplitudeLog("Item results ", "Remove listing", "", {
      title: removedListItem?.title,
      marketPlace: removedListItem?.marketName,
      price: removedListItem?.price,
    });
    removedListItem.itemId = itemID;
    loggedInUser.detail =
      "User has clicked on Removed Listing button to remove the item from listing";
    FullStory.event("Removed Listing", {
      loggedInUserDetail: loggedInUser,
      removedItem: removedListItem,
    });
    await updateFilterArrayData(marketParamObject);
    setUpdateData(true);
  };

  const addListingsHandle = async (marketParamObject, removedListItem) => {
    amplitudeLog("Item results ", "Add this listing again (undo)", "", {
      title: removedListItem?.title,
      marketPlace: removedListItem?.marketName,
      price: removedListItem?.price,
    });
    await updateFilterArrayAddData(marketParamObject);
    setUpdateData(true);
  };

  useEffect(() => {
    setRender(true);
    if (updateDropDownItems() !== undefined) {
      setUpdateDropDown(updateDropDownItems());
    }
  }, [data, maxPriceMarketplace, currentPage]);

  const marketPlaceDropDown = dropDownData?.listings?.map((item) => {
    const marketplacesFilters = {
      name: item.marketName,
      value: item.marketName,
    };
    return marketplacesFilters;
  });
  const updateDropDownItems = () => {
    marketPlaceDropDown?.unshift({ name: "All", value: "All" });
    const newArr = marketPlaceDropDown?.filter((object) => {
      return object.name !== undefined;
    });
    const unique = [...new Map(newArr?.map((m) => [m?.name, m])).values()];
    return unique;
  };
  const handleNavLinkClick = () => {
    amplitudeLog(
      "Item results ",
      "Back to product search",
      "User click Back button "
    );
  };
  const closeDispute = () => {
    amplitudeLog("Feedback form ", "Cancel feedback view");
    setShowDisputeModal(false);
  };
  const showDisputeModalhandle = (e) => {
    amplitudeLog("feedback form", "View Feeback form");
    setShowDisputeModal(true);
  };

  const PriceCard = ({ mobile = false }) => (
    <div className={mobile ? "card-price-mb" : "card-item-price"}>
      {marketplaces.map((mktPlace) => {
        const marketplaceName = mktPlace?.marketPlace?.name || "";

        const price = excludedMarketplaces
          .map((el) => el.toLowerCase())
          .includes(marketplaceName.toLowerCase())
          ? null
          : mktPlace.meanPrice;

        return (
          <BasicCard
            key={price}
            marketPlace={marketplaceName}
            listAt="Suggested selling price"
            price={price}
            maxMarket={maxPriceMarketplace}
            totalProfit={mktPlace.profit}
            recentListings={mktPlace.itemsBeforeFiltering}
            mbl={mobile}
          />
        );
      })}
    </div>
  );
  PriceCard.propTypes = {
    mobile: PropTypes.bool,
  };

  return (
    render && (
      <div>
        <div className="userSearchResultModal">
          <AddADispute
            open={showDisputeModal}
            onClose={() => closeDispute()}
            itemId={itemID}
            description={data?.title}
            setUpdateData={setUpdateData}
            data={data}
          />
        </div>
        <div className="headerIn">
          <Header isAuthenticated={true} black_bg={true} />
        </div>
        <section className="dashboard__results_upper">
          <div className="std-imgs-mbl">
            <StandardImageList imgList={images} mbl={true} />
          </div>
        </section>

        <section className="dashboard__results dashboard--result">
          <div className="mobile-sc">
            <div className="underline-back-examplePics">
              <NavLink
                to={navigateBack}
                style={{ textDecoration: "none" }}
                onClick={handleNavLinkClick}
              >
                <picture>
                  <source media="(max-width: 102.4rem)" srcSet={BackIcon} />
                  <img src={BackIcon} alt="backIcon" />
                </picture>
                <span className="back-text">Back</span>
              </NavLink>

              {images.length === 0 ? (
                <div> No image found. </div>
              ) : (
                <div style={{ marginTop: "-2rem" }}>
                  Examples image taken from various markets
                </div>
              )}
            </div>
            <div className="item-result">
              <div className="item-description">
                <div className="item-description__name">
                  {data?.title ? (
                    isEnableNavigation === "true" ? (
                      <div className="navigationLinkClass_parent">
                        <Tooltip
                          title={
                            <div style={{ display: "flex" }}>
                              <CopyText content={data?.title} />
                              <Typography fontSize={20}>
                                {data?.title}
                              </Typography>
                            </div>
                          }
                          placement="top-start"
                        >
                          <a
                            href={navigationLink}
                            className="navigationLinkClass"
                          >
                            {title}
                          </a>
                        </Tooltip>
                      </div>
                    ) : (
                      <Tooltip
                        title={
                          <div style={{ display: "flex" }}>
                            <CopyText content={data?.title} />
                            <Typography fontSize={20}>{data?.title}</Typography>
                          </div>
                        }
                        placement="top-start"
                      >
                        <p>{title}</p>
                      </Tooltip>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="item-description__condition">
                  {" "}
                  {data?.condition?.name}
                </div>
                <div className="bottom-space" />
                <div className="mark-incorrect">
                  Tracking code: <span className="mark-as">{uniqueCode}</span>
                  {isAiSearch && (
                    <span className="mark-as-AI">
                      &#x2728; {" Search Title Optimized by Thrift AI "}
                    </span>
                  )}
                </div>
              </div>
              <PriceCard mobile={false} />
              <PriceCard mobile={true} />
            </div>
            <div className="mobile-search-new-item">
              <NavLink
                to="/dashboard"
                className="search-new-item-link"
                color="inherit"
              >
                SEARCH FOR A NEW ITEM
              </NavLink>
            </div>
            <div className="suggest-price-box">
              <div className="suggest-price">
                THRIFT suggests listing your item on
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {maxPriceMarketplace}
                </span>{" "}
                for
                <span style={{ fontWeight: "bold" }}> £{maxPrice}</span>
              </div>
              <div className="search-new-item">
                <NavLink
                  to="/dashboard"
                  className="search-new-item-link"
                  color="inherit"
                >
                  Search for a new item
                </NavLink>
              </div>
            </div>
            <div
              style={{
                marginTop: "2.5rem",
                borderBottom: "1px solid #E2E7F6",
                marginBottom: "3rem",
              }}
            >
              {disputeStatus === 1 ? (
                <div className="mark-incorrect-text">
                  <span
                    className="mark-as-text"
                    style={{
                      color: "#fa7c89",
                    }}
                  >
                    Item Disputed
                  </span>
                </div>
              ) : (
                <div className="mark-incorrect-text">
                  Think something looks wrong?
                  <span
                    className="mark-as-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => showDisputeModalhandle(true)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        showDisputeModalhandle(true);
                      }
                    }}
                  >
                    Click here to let us know
                  </span>
                </div>
              )}
            </div>
            <div className="all-items-listings">
              <div className="listings-heading">
                Recently sold similar items
                <span className="listings-heading-price"> {count}</span>
                <div className="listing-matching">
                  We've searched all known listings across your chosen
                  marketplaces and have prioritised those that have sold.{" "}
                  <Link
                    to={Routes.AboutUs.route}
                    onClick={reDirectToAboutUs}
                    style={{ fontWeight: "bold" }}
                  >
                    Click here
                  </Link>{" "}
                  to see how THRIFT works in action.
                </div>
                <div style={{ marginTop: "2.5rem" }} />
              </div>
              <div className="basic-selects">
                <div className="BasicPriceSelect">
                  <BasicPriceSelect
                    arrayData={priceFilters}
                    getValueBestMatch={setPriceFilterValue}
                  />
                </div>
                <div className="BasicMarketSelect">
                  <BasicMarketSelect
                    arrayData={updateDropDown}
                    setMarketplaceFilterValue={setMarketplaceFilterValue}
                  />
                </div>
              </div>
            </div>
            <div style={{ marginTop: "3rem" }} />
            <div className="all-market-listings">
              {data.listings.map((item) => {
                const { marketplaceItemId, marketName, marketplaceIndex } =
                  item;
                let marketParamObject = {
                  marketPlaceItemId: marketplaceItemId,
                  index: marketplaceIndex,
                };
                const itemPrice = Number(item.price);

                return (
                  <ItemsResultList
                    key={item.id}
                    price={`${"£"}${itemPrice.toFixed(2)}`}
                    img={item.imageUrl ? item.imageUrl : ""}
                    itemName={item.originalTitle}
                    marketName={marketName}
                    listRemove={() =>
                      removeListingsHandle(marketParamObject, item)
                    }
                    removelist={item?.removelist || "0"}
                    listadd={() => addListingsHandle(marketParamObject, item)}
                    updateData={updateData}
                  />
                );
              })}
            </div>
            <hr className="below-line" />
            <div style={{ marginTop: "3rem" }} />
            <div className="pagination">
              {count > 0 && (
                <CustomPagination
                  perPage={perPage}
                  totalPosts={count}
                  currentPage={currentPage}
                  nextPage={nextPageHandle}
                  prevPage={prevPageHandle}
                />
              )}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    )
  );
};
export default UserSearchResult;
