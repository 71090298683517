import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import Button from "react-bootstrap/Button";
import { Modal } from "../../../components/Modal";
import SubmitButton from "../../../components/SubmitButton/SubmitButton";
import Table from "react-bootstrap/Table";
import Logger from "../../../logger/logger";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  isTokenInValid,
  toastErrMessage,
  findInArray,
  toastSuccessMessage,
  getFormattedDate,
  calculatePercentageFunction,
} from "../../../utils/utilFunctions";
import { getConditionsApi } from "../../../api/dashboardApi";
import {
  getStoresApi,
  getBulkUploadApi,
  getS3UrlApi,
  uploadFileApi,
  bulkUploadApi,
  getBulkUploadFailedItems,
  reprocessSingleItem,
  reprocessAllItem,
} from "../../../api/adminDashboardApi";
import { read, utils } from "xlsx";

import "./AdminBulkUpload.scss";

const AdminBulkUpload = () => {
  const [submitDisabled, setSubmitDisabled] = useState(true); // TODO: change to true and handle
  const [systemConditions, setSystemConditions] = useState([]);
  const [systemStores, setSystemStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [excelData, setExcelData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(0);
  const [originalFileName, setOriginalFileName] = useState("");
  const [bulkUploadFiles, setBulkUploadFiles] = useState([]);
  const [invalidFile, setInvalidFile] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [failedResult, setFailedResult] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    const conditionsResponse = await getConditionsApi();
    if (conditionsResponse.success) {
      setSystemConditions(
        conditionsResponse.data.map((c) => {
          return {
            id: c.id,
            value: c.name.toLowerCase(),
          };
        })
      );
    }

    const storesResponse = await getStoresApi();
    if (storesResponse.success) {
      setSystemStores(storesResponse.data);
    }

    const bulkFilesResponse = await getBulkUploadApi();
    if (bulkFilesResponse.success) {
      setBulkUploadFiles(bulkFilesResponse.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  const validateExcelData = (data) => {
    for (let row of data) {
      if (!row.Title || !row.Condition) {
        window.alert("Only Title and Condition columns are allowed!");
        return false;
      } else {
        if (typeof row.Title !== "string") {
          window.alert(`Title: "${row.Title}" is not a string!`);
          return false;
        }

        if (row.Title.length === 0 || row.Title.length > 300) {
          window.alert(
            `Title: "${row.Title}" length should be 1-300 characters!`
          );
          return false;
        }
        if (
          !findInArray(
            systemConditions,
            row.Condition.toLowerCase().trim(),
            "value",
            "id"
          )
        ) {
          window.alert(`Condition: "${row.Condition}" is not correct!`);
          return false;
        }
      }
    }
    return true;
  };

  const formatData = (data) => {
    const formattedData = data.map((row) => {
      return {
        title: row.Title,
        condition: findInArray(
          systemConditions,
          row.Condition.toLowerCase().trim(),
          "value",
          "id"
        ),
      };
    });
    setExcelData(formattedData);
  };

  const uploadFile = async () => {
    setSubmitDisabled(true);
    const resS3Url = await getS3UrlApi("json", selectedStore.toLowerCase());
    if (resS3Url.success) {
      const data = resS3Url.data;
      const formData = new FormData();
      Object.keys(data.fields).forEach((key) => {
        formData.append(key, data.fields[key]);
      });
      formData.append("file", JSON.stringify(excelData));
      const resUploadFile = await uploadFileApi(data.url, formData);
      if (!resUploadFile.success) {
        setSubmitDisabled(false);
        toastErrMessage(resUploadFile.message);
        return {
          success: false,
        };
      } else {
        const store = systemStores.find((x) => x.code === selectedStore);
        const bulkUploadResponse = await bulkUploadApi({
          originalFileName,
          fileUrl: data.fields.Key,
          storeId: store.id,
        });
        if (bulkUploadResponse.success) {
          reset();
          toastSuccessMessage("File uploaded successfully");
          await fetchData();
        } else {
          toastErrMessage("File not uploaded please try again");
        }
      }
    } else {
      setSubmitDisabled(false);
      toastErrMessage("File upload failed try again please.");
      return {
        success: false,
      };
    }
  };

  // for submit button enable disabled
  useEffect(() => {
    setSubmitDisabled(!excelData.length > 0 || !selectedStore);
  }, [excelData, selectedStore]);

  const chooseXLSXFile = (event) => {
    try {
      const file = event.target.files[0];
      setSelectedFile(file);

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        const bufferArray = e.target.result;
        const workbook = read(bufferArray, { type: "buffer" });
        const sheetName = workbook.SheetNames[0];
        const data = utils.sheet_to_json(workbook.Sheets[sheetName]);
        const isValid = validateExcelData(data);
        if (isValid) {
          formatData(data);
          setOriginalFileName(file.name);
        }
      };
    } catch (error) {
      toastErrMessage(error);
    }
  };

  const reset = () => {
    setSubmitDisabled(true);
    setSelectedStore("");
    setSelectedFile(0);
    setInvalidFile(true);
    setExcelData([]);
    return false;
  };
  const handleViewAndReprocess = async (bulkUploadId) => {
    setOpen(true);
    try {
      const allBulkUploadFailedItems = await getBulkUploadFailedItems(
        bulkUploadId
      );
      if (allBulkUploadFailedItems.success) {
        if (allBulkUploadFailedItems.data.length <= 0) {
          toastErrMessage("No item to view and reprocess");
        }
        setFailedResult(allBulkUploadFailedItems.data);
      }
    } catch (error) {
      Logger.logError(
        ` Error while fetching failed bulkupload items: ${error.message}`
      );
    }
  };
  const handleReprocessAll = async (id) => {
    try {
      const dbResponse = await reprocessAllItem(id);
      if (dbResponse.success) {
        toastSuccessMessage(dbResponse.message);
        fetchData();
      } else {
        toastErrMessage(dbResponse.message);
      }
    } catch (error) {
      Logger.logError(
        `Bulkupload Items could not reprocess due to this error ${error.message}`
      );
      toastSuccessMessage("Reprocessing could not start due to server error");
    }
  };

  const singleItemReprocess = async (itemId, bulkUploadId) => {
    try {
      const dbResponse = await reprocessSingleItem(itemId, bulkUploadId);
      if (dbResponse.data[0] && dbResponse.success) {
        const allBulkUploadFailedItems = await getBulkUploadFailedItems(
          bulkUploadId
        );
        setFailedResult(allBulkUploadFailedItems.data);
        fetchData();
        toastSuccessMessage("Reprocessing is starting");
      } else {
        toastSuccessMessage("An error occured while processing your request.");
      }
    } catch (error) {
      Logger.logError(
        `Bulkupload Item could not reprocess due to this error ${error.message}`
      );
      toastSuccessMessage("An error occured while processing your request.");
    }
  };
  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Bulk Upload</div>
          <AdminNav />
        </div>
        <div className="bulkUploadWrapper">
          <h1 className="title">Upload New File</h1>
          <form className="bulkUploadForm">
            <p>
              Choose an excel file and click upload. <br />
              1. File should have two columns: Title and Condition. <br />
              2. Any entry in Title column should be a string. <br />
              3. Condition should <b>exactly</b> match a system condition.
            </p>
            <label htmlFor="store">Store</label>
            <select
              required={true}
              value={selectedStore}
              onChange={(e) => {
                setSelectedStore(e.target.value);
              }}
            >
              <option value="">Select Store</option>
              {systemStores.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
            <span>
              <label className="fileSelectLabel" htmlFor="fileSelect">
                {selectedFile
                  ? `${selectedFile.name} selected`
                  : `Choose a file`}
              </label>
              <input
                type="file"
                className="fileSelect"
                id="fileSelect"
                name="fileSelect"
                required={true}
                onChange={chooseXLSXFile}
                accept=".xls,.xlsx"
              />
            </span>
            <span>
              <SubmitButton
                className={"fileSubmit"}
                type={"button"}
                onClick={uploadFile}
                text={"Upload to server"}
                disabled={submitDisabled}
              />
              <button className="fileSubmit" type="reset" onClick={reset}>
                Cancel
              </button>
            </span>
          </form>
          <div className="innerTable">
            <h1 className="title">Uploaded Files</h1>
            <table>
              <thead>
                <tr>
                  <th className="dashboard__tableBorder" width="10%">
                    <h1>Store</h1>
                  </th>
                  <th className="dashboard__tableBorder">
                    <h1>File Name</h1>
                  </th>
                  <th className="dashboard__tableBorder" width="20%">
                    <h1>Status</h1>
                  </th>
                  <th className="dashboard__tableBorder" width="15%">
                    <h1>Uploaded At</h1>
                  </th>
                  <th className="dashboard__tableBorder" width="15%">
                    <h1>Last Updated</h1>
                  </th>
                  <th className="dashboard__tableBorder" width="15%">
                    <h1>Download Url</h1>
                  </th>
                  <th className="dashboard__tableBorder" width="15%">
                    <h1>Reprocess</h1>
                  </th>
                  <th className="dashboard__tableBorder" width="15%">
                    <h1>Processed/Total</h1>
                  </th>
                </tr>
              </thead>
              <tbody>
                {bulkUploadFiles.map((file, index) => (
                  <tr key={index}>
                    <td className="dashboard__tableBorder" align="center">
                      <h3>{file.store.name}</h3>
                    </td>
                    <td className="dashboard__tableBorder" align="center">
                      <h3>{file.originalFileName}</h3>
                    </td>
                    <td className="dashboard__tableBorder" align="center">
                      <h3>{file.statusDescription}</h3>
                    </td>
                    <td className="dashboard__tableBorder" align="center">
                      <h3>{getFormattedDate(file.createdAt)}</h3>
                    </td>
                    <td className="dashboard__tableBorder" align="center">
                      <h3>{getFormattedDate(file.updatedAt)}</h3>
                    </td>
                    <td className="dashboard__tableBorder">
                      <h3>
                        {file.signedDownloadUrl && !file.isFailedAnyItem ? (
                          <a href={file.signedDownloadUrl}>Download</a>
                        ) : (
                          ""
                        )}
                      </h3>
                    </td>

                    {file.status === 6 ? (
                      file.isFailedAnyItem && file.failedItem > 0 ? (
                        <td className="dashboard__tableBorder">
                          <div>
                            <Button
                              variant="outline-primary"
                              className="bulk-btn-class"
                              onClick={() => handleViewAndReprocess(file.id)}
                            >
                              View
                            </Button>{" "}
                            <Button
                              variant="primary"
                              className="bulk-btn-class"
                              onClick={() => handleReprocessAll(file.id)}
                            >
                              Reprocess
                            </Button>{" "}
                          </div>
                        </td>
                      ) : (
                        <td></td>
                      )
                    ) : (
                      <td></td>
                    )}
                    <td className="dashboard__tableBorder">
                      {file.status === 6 && file.totalItem > 0 ? (
                        <h3>
                          <div style={{ display: "inline" }}>
                            <div className="progress-content">
                              {file.failedItem + file.completeItem}/
                              {file.totalItem}
                            </div>{" "}
                            <div>
                              {" "}
                              <ProgressBar
                                animated
                                now={calculatePercentageFunction(
                                  file.failedItem + file.completeItem,
                                  file.totalItem
                                )}
                                className="progress-bar-class"
                              />
                            </div>
                          </div>
                        </h3>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {open && failedResult.length > 0 ? (
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              title=""
              content={
                failedResult.length > 0 ? (
                  <div>
                    <h4 className="failed-item-count-class">
                      Total Failed Items: {failedResult.length}
                    </h4>
                    <Table striped bordered hover>
                      <thead className="bulk-failed-result-table-heading">
                        <tr>
                          <th>Item Id</th>
                          <th>Title</th>

                          <th>Unique Code</th>
                          <th>Reprocess</th>
                        </tr>
                      </thead>
                      <tbody className="bulk-failed-result-table-body">
                        {failedResult.map((row) => (
                          <tr>
                            <td>{row.id}</td>
                            <td>{row.title}</td>

                            <td>{row.uniqueCode}</td>
                            <td>
                              {" "}
                              <Button
                                variant="primary"
                                className="bulk-btn-class"
                                onClick={() =>
                                  singleItemReprocess(row.id, row.bulkUploadId)
                                }
                              >
                                Reprocess
                              </Button>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  "No Data"
                )
              }
              textContent=""
              action=""
              closeAction=""
              maxWidth=""
              closeButton="true"
            />
          ) : (
            ""
          )}
        </div>
      </section>
    </div>
  );
};

export default AdminBulkUpload;
