import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import WordAnchorBankForm from "./WordAnchorBankForm";
import WordAnchorBankTable from "./WordAnchorBankTable";
import { isTokenInValid } from "../../../utils/utilFunctions";
import { getMarketPlacesApi } from "../../../api/adminDashboardApi";
import { Add } from "@mui/icons-material";
import "./AdminWordAnchorBank.scss";

//TODO: Page is slow on load, on add/edit/cancel click. introduce pagination

const AdminWordAnchorBank = () => {
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [wordObject, setWordObject] = useState({});
  const [refreshData, setRefreshData] = useState(false);

  const fetchData = async () => {
    const marketPlacesResponse = await getMarketPlacesApi();
    if (marketPlacesResponse.success) {
      setMarketPlaces(marketPlacesResponse.data);
    }
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  function handleEditClick(event) {
    setDisplayForm(true);
    setIsEditing(true);
    setWordObject({
      id: event.currentTarget.dataset.id,
      word: event.currentTarget.dataset.word,
      marketPlaceId: event.currentTarget.dataset.marketplaceid,
    });
  }

  const handleAddClick = () => {
    setDisplayForm(true);
  };

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Word Anchor Bank</div>
          <AdminNav />
        </div>
        <div className="innerTable wordWrapper">
          <WordAnchorBankTable
            refreshData={refreshData}
            handleEditClick={handleEditClick}
            marketPlaces={marketPlaces}
            displayForm={displayForm}
          />

          {displayForm && (
            <WordAnchorBankForm
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setDisplayForm={setDisplayForm}
              setRefreshData={setRefreshData}
              wordObject={wordObject}
              marketPlaces={marketPlaces}
            />
          )}

          <div className="wordAdd">
            <Add sx={{ fontSize: "5em" }} onClick={handleAddClick} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdminWordAnchorBank;
