import { useState, useEffect } from "react";
import { getSingleResultApi } from "../api/dashboardApi";
import { ExtremeChopPercentage } from "../utils/Constants";
import {
  calculateUpperEbayCap,
  calculateLowerEbayCap,
} from "../utils/utilFunctions";
import isEmpty from "lodash.isempty";
import cloneDeep from "lodash.clonedeep";
const prepareData = (data) => {
  let allListings = [];
  data?.marketPlaceItems.forEach((mktplace, index) => {
    allListings = [
      ...allListings,
      ...mktplace.listings.map((listItem, index) => {
        listItem = {
          ...listItem,
          marketplaceIndex: index,
          marketplaceItemId: mktplace.id,
          marketName: mktplace.marketPlace.name,
        };
        return listItem;
      }),
    ];
    delete data.marketPlaceItems[index].listings;
  });
  data.listings = allListings;
  return data;
};

const marketplaceFilter = (data, marketName = "all") => {
  if (marketName === "all") return data;
  data.listings = data.listings.filter(
    (list) => list.marketName.toLowerCase() === marketName.toLowerCase()
  );
  return data;
};

const priceFilter = (data, price = "default") => {
  if (price === "default") return data;
  else if (price === "high") {
    data.listings =
      data?.listings?.sort((a, b) => {
        return b.price - a.price;
      }) || [];
  } else {
    data.listings =
      data?.listings?.sort((a, b) => {
        return a.price - b.price;
      }) || [];
  }
  return data;
};

const paginationFilter = (data, page, perPage) => {
  const indexOfLastPost = page * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  data.listings =
    data?.listings?.slice(indexOfFirstPost, indexOfLastPost) || [];
  return data;
};

const getMaxPriceAndMarketplace = (data, excludeMarketplacesNames) => {
  const maxMarketplace =
    data?.marketPlaceItems?.reduce((prev, current) => {
      const marketPlace = current?.marketPlace?.name?.toLowerCase() || "";

      const exclude = excludeMarketplacesNames
        .map((el) => el.toLowerCase())
        .includes(marketPlace.toLowerCase());

      return (Number(current.meanPrice) > prev?.meanPrice && !exclude) ||
        marketPlace === "ebay"
        ? current
        : prev;
    }, {}) || {};

  return [
    maxMarketplace?.meanPrice || 0,
    maxMarketplace?.marketPlace?.name || "",
  ];
};

const extremeChopFilter = (data) => {
  const marketplcesData = data?.marketPlaceItems;
  const excludeMarketplaces = [];

  const ebayMarketplace =
    marketplcesData?.find(
      (mkt) => mkt?.marketPlace?.name?.toLowerCase() === "ebay"
    ) || {};

  const isEbayEmpty =
    isEmpty(ebayMarketplace) || isEmpty(ebayMarketplace?.meanPrice);
  if (isEbayEmpty) {
    return [data, excludeMarketplaces];
  }

  const ebayMean = Number(ebayMarketplace.meanPrice) || 0;
  const upperCap = calculateUpperEbayCap(ebayMean, ExtremeChopPercentage);
  const lowerCap = calculateLowerEbayCap(ebayMean, ExtremeChopPercentage);

  let filteredListings = data.listings;
  marketplcesData.forEach((mktPlace) => {
    const marketplaceMean = Number(mktPlace?.meanPrice || 0);
    const shouldExcludeMarketplace =
      marketplaceMean >= upperCap || marketplaceMean <= lowerCap;
    if (shouldExcludeMarketplace) {
      const mktPlaceName = mktPlace?.marketPlace?.name?.toLowerCase() || "";
      ebayMean &&
        mktPlaceName !== "ebay" &&
        mktPlaceName !== "depop" &&
        excludeMarketplaces.push(mktPlaceName);
      filteredListings = filteredListings.filter((list) => {
        const listMarketname = list?.marketName?.toLowerCase() || "";
        return !(
          listMarketname === mktPlaceName &&
          mktPlaceName !== "ebay" &&
          mktPlaceName !== "depop"
        );
      });
    }
  });

  data.listings = filteredListings;
  return [data, excludeMarketplaces];
};

const useMarketplaceData = (
  itemId,
  page,
  perPage,
  priceToFilter,
  marketplaceToFilter,
  updateData,
  setUpdateData
) => {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [maxPrice, setMaxPrice] = useState(0);
  const [maxPriceMarketplace, setMaxPriceMarketplace] = useState("");
  const [count, setCount] = useState(0);
  const [excludeMarketplaces, setExcludeMarketplaces] = useState([]);
  const [dropDownData, setDropDownData] = useState({});
  const getData = async () => {
    if (itemId) {
      const item = await getSingleResultApi(itemId);
      let itemData = item.data.results;
      itemData = prepareData(itemData);
      setData(itemData);
    }
  };

  const applyFilters = () => {
    const dataDeepCopy = cloneDeep(data);
    const [listingAfterExtremeChop, excludeMarketplacesNames] =
      extremeChopFilter(dataDeepCopy);
    setExcludeMarketplaces(excludeMarketplacesNames);
    const listingsAfterPriceFilter = priceFilter(
      listingAfterExtremeChop,
      priceToFilter
    );
    const listingsAfterMarketplaceFilter = marketplaceFilter(
      listingsAfterPriceFilter,
      marketplaceToFilter
    );
    const filteredArray = listingsAfterMarketplaceFilter?.listings?.filter(
      (obj) => obj?.removelist !== "1"
    );
    setCount(filteredArray?.length || 0);
    const listingAfterPagination = paginationFilter(
      listingsAfterMarketplaceFilter,
      page,
      perPage
    );
    setDropDownData(data);
    setFilteredData(listingAfterPagination);
    const [price, mktPlace] = getMaxPriceAndMarketplace(
      listingAfterPagination,
      excludeMarketplacesNames
    );
    setMaxPrice(price);
    setMaxPriceMarketplace(mktPlace);
  };

  useEffect(() => {
    getData();
  }, [itemId]);

  useEffect(() => {
    if (updateData) {
      getData();
      setUpdateData(false);
    }
  }, [updateData]);

  useEffect(() => {
    applyFilters();
  }, [data, page, perPage, priceToFilter, marketplaceToFilter]);

  return [
    filteredData,
    excludeMarketplaces,
    count,
    maxPrice,
    maxPriceMarketplace,
    dropDownData,
  ];
};

export default useMarketplaceData;
