import React from "react";
import PropTypes from "prop-types";
import "./SearchField.scss";
import ArrowForward from "../../assets/images/arrow-forward.svg";
import ArrowForwardBlack from "../../assets/images/arrow-forward-black.svg";
import { STRENGTH_MIN_LIMIT } from "../../utils/Constants";

const SearchField = ({
  value,
  setValue,
  _required = true,
  _name,
  _minLength = "3",
  _maxLength = "30",
  _type = "text",
  placeholder,
  submitHandle,
  onceClicked,
  strength,
  barCode,
}) => {
  return (
    <div className="search__continer">
      <input
        className={`input__field ${onceClicked && " once__clicked"}`}
        type="text"
        spellCheck="true"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div
        className={`submit__buttom ${
          (!value || strength < STRENGTH_MIN_LIMIT) && !barCode
            ? "submit__disable"
            : ""
        } ${onceClicked ? "once__clicked_button" : ""}`}
        onClick={
          (value && strength >= STRENGTH_MIN_LIMIT) || barCode
            ? submitHandle
            : null
        }
        onKeyDown={(e) => {
          if (
            (e.key === "Enter" || e.key === " ") &&
            ((value && strength >= STRENGTH_MIN_LIMIT) || barCode)
          ) {
            submitHandle();
          }
        }}
      >
        <img
          src={onceClicked ? ArrowForwardBlack : ArrowForward}
          alt="arrow-forward"
        />
      </div>
    </div>
  );
};
SearchField.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  _required: PropTypes.bool,
  _name: PropTypes.string,
  _minLength: PropTypes.string,
  _maxLength: PropTypes.string,
  _type: PropTypes.string,
  placeholder: PropTypes.string,
  submitHandle: PropTypes.func,
  onceClicked: PropTypes.bool,
  strength: PropTypes.any,
  barCode: PropTypes.any,
};
export default SearchField;
