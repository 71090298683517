import React from "react";
import PropTypes from "prop-types";
import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material";

export default function SingleSelect({
  label,
  labelId = "",
  value,
  setValue,
  options,
  variant = "filled",
}) {
  const handleChange = (event) => {
    setValue(event);
  };
  SingleSelect.propTypes = {
    label: PropTypes.string.isRequired,
    labelId: PropTypes.string,
    value: PropTypes.any,
    setValue: PropTypes.func,
    options: PropTypes.array.isRequired,
    variant: PropTypes.string,
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <InputLabel id={"single-select"} style={{ color: "black", fontSize: 12 }}>
        {label}
      </InputLabel>
      <FormControl fullWidth>
        <Select
          labelId={"single-select"}
          id="single-select"
          name={labelId}
          value={value}
          label={label}
          onChange={handleChange}
          variant={variant}
          style={{
            backgroundColor: "white",
            border: "1px solid whitesmoke",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
