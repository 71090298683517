import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  postWordBankApi,
  editWordBankApi,
} from "../../../api/adminDashboardApi";
import {
  toastErrMessage,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";

function WordAnchorBankForm({
  isEditing,
  setIsEditing,
  setDisplayForm,
  setRefreshData,
  wordObject,
  marketPlaces,
}) {
  const [submitDisabled, setSubmitDisabled] = useState(false); //TODO: disable submit button accordingly
  const [wordId, setWordId] = useState();
  const [wordInput, setWordInput] = useState("");
  const [selectedMarketplace, setSelectedMarketplace] = useState(1);

  useEffect(() => {
    if (isEditing) {
      setWordId(wordObject.id);
      setWordInput(wordObject.word);
      setSelectedMarketplace(wordObject.marketPlaceId);
    }
  }, [isEditing, wordObject]);

  const handleWordChange = (event) => setWordInput(event.target.value);
  const handleMarketPlaceChange = (event) =>
    setSelectedMarketplace(event.target.value);

  const handleCancel = useCallback(() => {
    setDisplayForm(false);
    isEditing && setIsEditing(false);
    resetFormValues();
  }, [isEditing, setDisplayForm, setIsEditing]);

  const resetFormValues = () => {
    setWordId(0);
    setWordInput("");
    setSelectedMarketplace(1);
  };

  const handleWordSubmit = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    let response;

    if (isEditing) {
      response = await editWordBankApi(wordId, {
        word: wordInput.trim(),
        marketPlaceId: Number(selectedMarketplace),
      });
    } else {
      response = await postWordBankApi({
        word: wordInput.trim(),
        marketPlaceId: Number(selectedMarketplace),
      });
    }

    if (response.success) {
      toastSuccessMessage(
        isEditing ? "Changes saved successfully" : "Word added successfully"
      );
      isEditing && setIsEditing(false);
      resetFormValues();
      setSubmitDisabled(false);
      setRefreshData((refreshData) => !refreshData);
      setDisplayForm(false);
    } else {
      toastErrMessage(response.message);
      setSubmitDisabled(false);
    }
  };
  WordAnchorBankForm.propTypes = {
    isEditing: PropTypes.bool.isRequired,
    setIsEditing: PropTypes.func.isRequired,
    setDisplayForm: PropTypes.func.isRequired,
    setRefreshData: PropTypes.func.isRequired,
    wordObject: PropTypes.object.isRequired,
    marketPlaces: PropTypes.array.isRequired,
  };
  return (
    <div className="wordAnchorForm">
      <form onSubmit={handleWordSubmit}>
        <span>
          <label htmlFor="word">Word</label>
          <input
            name="wordText"
            type="text"
            placeholder="Woolworth"
            value={wordInput}
            onChange={handleWordChange}
            required={true}
            title="Please enter word"
            minLength="3"
            maxLength="50"
          />
        </span>
        <div className="wordFormInput">
          <span>
            <label htmlFor="marketPlace">Marketplace</label>
            <select
              value={selectedMarketplace}
              onChange={handleMarketPlaceChange}
            >
              {marketPlaces.map((marketPlace) => (
                <option key={marketPlace.id} value={marketPlace.id}>
                  {marketPlace.name}
                </option>
              ))}
            </select>
          </span>
        </div>
        <div className="wordFormInput">
          <input
            type="submit"
            value={isEditing ? "Save changes" : "Add woRD"}
            className="wordSubmit"
            disabled={submitDisabled}
          />
          <input
            type="button"
            className="wordSubmit"
            value="Cancel"
            onClick={handleCancel}
            disabled={submitDisabled}
          />
        </div>
      </form>
    </div>
  );
}

export default WordAnchorBankForm;
