export const BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;

export const AUTH_TOKEN = {
  Type: "Bearer",
  Name: "thrift-token",
};

export const ITEM_STATUS = {
  1: "Pending",
  2: "Completed",
  3: "Not found",
  4: "Partial Results",
};

export const API_STATUS_CODES = {
  INVALID_REQUEST: "invalid_request",
  PROCESSING: "request_processing",
  SUCCESS: "success",
  THRIFT_ALGO_FAILED: "request_failed_to_process",
  PARTIAL_RESULT: "partial_success",
  REQUEST_TIMEOUT: "request_timeout",
  BRIGHT_DATA_FAILED: "service_failed",
  ITEM_NOT_FOUND: "item_not_found",
};

export const AI_DEFAULT_VALUES = {
  SEARCH_AI: "searchAI",
  FILTER_AI: "filterAI",
  GPT_MODEL: "gptModel",
  SYSTEM_PROMPTS: "systemPrompts",
};
export const WEB_SOCKET_PORT = 8080;

export const STRENGTH_MIN_LIMIT = 1;

export const HOST_NAME = window.location.hostname;
export const ITEM_STATUS_CODES = {
  PENDING: 1,
  COMPLETED: 2,
  NOT_FOUND: 3,
  PARTIAL_RESULTS: 4,
};
export const isActive = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "In Active",
    value: 0,
  },
];

export const STORE_TYPE = {
  ADMIN: 1,
  BHF: 2,
  TEST: 3,
  STANDARD: 4,
};

export const STORE_TYPES = [
  {
    label: "Admin",
    value: 1,
  },
  {
    label: "Standard",
    value: 4,
  },
  {
    label: "Test",
    value: 3,
  },
  {
    label: "Bhf",
    value: 2,
  },
];

export const CATEGORY_TYPE = {
  BRAND: 1,
  MODEL: 2,
  ITEM_CATEGORY: 3,
  SIZE: 4,
  MATERIAL: 5,
  QUANTITY: 6,
  NA: 7,
};
export const JS_DATA_TYPE = {
  STRING: "string",
  NUMBER: "number",
  BOOLEAN: "boolean",
  OBJECT: "object",
};
export const PAIR_TYPE = {
  SANITISER: 1,
  BAD_WORD: 2,
  GHOST_WORD: 3,
  WORD_ANCHOR: 4,
  CATEGORY: 5,
};
export const pairsBackUpState = {
  backUp: "Pairs Back Up",
  backedUp: "Pairs Backed Up",
  backingUp: "Pairs Backing Up",
  backUpFailed: "Pairs Back Up Failed",
};
export const PAIR_LOGIC_TYPE = {
  STANDARD: 1,
  REGEX: 2,
  ERA: 3,
};

export const API_ACCESS = {
  Yes: 1,
  No: 0,
};
export const ENABLE_NAV_TYPE = {
  Yes: 1,
  No: 0,
};
export const PAGE_SIZE = {
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
  HUNDRED: 100,
  TWO_HUNDRED: 200,
  FIVE_HUNDRED: 500,
  ONE_THOUSANDS: 1000,
  FIVE_THOUSANDS: 5000,
};

export const ITEM_DETAIL_STATUS = {
  // SIMPLE STATUS FROM 1 -10
  // FLAG STATUS FROM 11-59
  // API STATUS FROM 60-99
  // ERROR STATUS FROM 100-127
  PENDING: 1, // stored in database
  SUBMITTED: 2, // Submitted to api
  PROCESSING: 3,
  COMPLETED: 4,
  LISTED: 5,
  NO_RESULT: 6, // api return no result
  LT_1_RESULT: 7, // api has less than 1 result
  CURRENCY_FILTER: 11,
  CONDITION_FILTER: 12, // condition filter
  BAD_WORD_FILTER: 13,
  POSTAGE_CAP_FILTER: 14,
  BEST_OFFER_OFF_FILTER: 15,
  WORD_ANCHOR_FILTER: 16,
  EXTREME_CHOP_FILTER: 17,
  PHISH_PRICE_FILTER: 18,
  IGNORE_WORDS_FILTER: 19,
  DEAD_STOCK_FILTER: 20,
  API_SUBMISSION_FAILED: 60, // when we do retry but still API response failed.
  API_ERROR_RESPONSE: 61,
  UNKNOWN_ERROR: 100,
  LOCATION_FILTER: 28,
};
export const ExtremeChopPercentage = 75;

export const INFO_TYPE = {
  25: "All",
  3: "Currency",
  4: "Condition",
  9: "Remove Parts Only",
  10: "Floating Buoys",
  11: "Bad Word",
  12: "Postage Cap",
  13: "Best Offer",
  14: "Word Anchor",
  15: "Extreme Chop Average",
  16: "Ghost Word",
  17: "Postage Cap Extreme Chop",
  18: "Sanitiser",
  19: "Kicked By Bad Word",
  20: "Phish Price",
  21: "Ignore Words",
  22: "Dead Stock",
  23: "Barter",
  24: "Parsed Data",
  26: "Calculate Postage",
  27: "With And",
  28: "Location",
};

export const marketName = {
  ALL_MARKET_PLACES: "All",
  EBAY_EDINBURG_UNIVERSITY: "Ebay Edinburg University",
  FACEBOOK: "Facebook",
  EBAY: "Ebay",
  DEPOP: "Depop",
};

export const marketNameValue = {
  ALL_VAL: 0,
  EBAY_VAL: 1,
  FB_VAL: 2,
  DEPOP_VAL: 3,
};

export const marketplacesFilters = [
  { name: marketName.ALL_MARKET_PLACES, value: marketName.ALL_MARKET_PLACES },
  { name: marketName.EBAY, value: marketName.EBAY },
  { name: marketName.FACEBOOK, value: marketName.FACEBOOK },
  { name: marketName.DEPOP, value: marketName.DEPOP },
];

export const priceFilters = [
  { name: "Default", value: "default" },
  { name: "£ high - low", value: "high" },
  { name: "£ low - high", value: "low" },
];
export const regExBarCode = /^\d{10,14}$/;
