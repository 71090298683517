import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const PointerBox = ({ children, ...props }) => {
  return (
    <Box
      sx={[
        {
          "& :hover": {
            cursor: "pointer",
          },
        },
      ]}
      {...props}
    >
      {children}
    </Box>
  );
};
PointerBox.propTypes = {
  children: PropTypes.node,
};
export default PointerBox;
