import React from "react";
import PropTypes from "prop-types";
const TextFieldComponent = ({
  value,
  setValue,
  label,
  _required = true,
  name,
  minLength = "3",
  maxLength = "30",
  type = "text",
  placeholder,
  ...props
}) => {
  return (
    <div>
      <p style={{ fontSize: 12 }}>{label}</p>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        required={true}
        minLength={minLength}
        maxLength={maxLength}
        {...props}
        style={{
          paddingTop: 12,
          paddingRight: 12,
          paddingBottom: 8,
          paddingLeft: 12,
          width: "100%",
          fontSize: 14,
          border: "1px solid whitesmoke",
          borderRadius: "5px 5px 0px 0px",
        }}
      />
    </div>
  );
};
TextFieldComponent.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func,
  label: PropTypes.string,
  _required: PropTypes.bool,
  name: PropTypes.string,
  minLength: PropTypes.string,
  maxLength: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};
export default TextFieldComponent;
