import * as React from "react";
import PropTypes from "prop-types";
import "./StandardImageList.scss";

function StandardImageList({ imgList, _mbl }) {
  return (
    <div className={`wrapper${imgList.length}`}>
      {imgList.map((img, index) => (
        <div key={img.marketPlaceUrl} className={`image-div${index}`}>
          <img
            src={`${img.marketPlaceUrl}`}
            srcSet={`${img.marketPlaceUrl}`}
            loading="lazy"
            className="image-list-item"
            alt=""
          />
        </div>
      ))}
    </div>
  );
}
StandardImageList.propTypes = {
  imgList: PropTypes.array.isRequired,
  _mbl: PropTypes.bool,
};
export default StandardImageList;
