import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import { isTokenInValid } from "../../../utils/utilFunctions";
import {
  getKickedItemsBadWordApi,
  getStoresApi,
} from "../../../api/adminDashboardApi";
import "./AdminBadWordStats.scss";
import AdminNav from "../../../components/AdminNav/AdminNav";

const AdminBadWordStats = () => {
  const [stores, setStores] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [badWordData, setBadWordData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    const response = await getKickedItemsBadWordApi(storeId);
    if (response.success) {
      setBadWordData(Object.entries(response.data).sort((a, b) => b[1] - a[1]));
    }
    setLoading(false);
  };

  const fetchDefaultData = async () => {
    setLoading(true);
    const storeResponse = await getStoresApi();
    if (storeResponse.success) {
      setStores(storeResponse.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchDefaultData();
      }
    }
    verifyToken();
  }, []);

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">BadWord Stats</div>
          <AdminNav />
        </div>

        <div className="dashboard__filters">
          <select
            name="storeDropDown"
            onChange={(e) => setStoreId(e.target.value)}
            value={storeId}
            required={true}
          >
            <option value="">Select Store</option>
            {stores.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              fetchData();
            }}
          >
            Load
          </button>
        </div>
        <div className="dashboard__collapsible"></div>
        {loading ? (
          <div>loading...</div>
        ) : badWordData ? (
          <div className="badWordsWrapper">
            {badWordData.length > 0 ? (
              <table className="dashboard__tableBorder">
                <thead>
                  <tr>
                    <th className="dashboard__tableBorder">
                      <h1>BadWord</h1>
                    </th>

                    <th className="dashboard__tableBorder">
                      <h1>Items Kicked</h1>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {badWordData.map((x, i) => (
                    <tr key={i}>
                      <td className="dashboard__tableBorder">
                        <h3>{x[0]}</h3>
                      </td>
                      <td className="dashboard__tableBorder">
                        <h3>{x[1]}</h3>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="noDataFound">No data found</div>
            )}
          </div>
        ) : (
          <div className="noDataFound">No data found</div>
        )}
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default AdminBadWordStats;
