import React from "react";
import PropTypes from "prop-types";
import "./ConditionCard.scss";

const ConditionCard = ({
  title,
  details,
  selected,
  oneSelected = false,
  onClick,
}) => {
  return (
    <div
      className={`card__default ${
        selected || !oneSelected ? "card__selected" : "card__non__selected"
      }`}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      <div>{title}</div>
      <div>{details}</div>
    </div>
  );
};

ConditionCard.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  oneSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ConditionCard;
