import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import {
  getRoyalMailPricesApi,
  postRoyalMailPricesApi,
  updateRoyalMailPricesApi,
  deleteRoyalMailPricesApi,
} from "../../../api/adminDashboardApi";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import "./AdminPostageSettings.scss";

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = useCallback(() => {
    const id = Date.now();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        size: "",
        weight: "",
        price_first_class: 0,
        price_second_class: 0,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "size" },
    }));
  }, []);

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
        sx={{ fontSize: "20px", fontWeight: "700" }}
      >
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const getData = async () => {
    const fetchedData = await getRoyalMailPricesApi();
    setRows(fetchedData.data);
  };

  const handleRowEditStart = useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleRowEditStop = useCallback((params, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handleEditClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    const newRow = rows.find((row) => row.id === id);
    newRow.price_first_class = Number(newRow.price_first_class) || 0;
    newRow.price_second_class = Number(newRow.price_second_class) || 0;
    await postRoyalMailPricesApi(newRow);
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    newRow.price_first_class = Number(newRow.price_first_class) || 0;
    newRow.price_second_class = Number(newRow.price_second_class) || 0;
    await updateRoyalMailPricesApi(newRow.id, newRow);
    await getData();
    return updatedRow;
  };

  const handleDeleteClick = (id) => async () => {
    setRows(rows.filter((row) => row.id !== id));
    await deleteRoyalMailPricesApi(id);
    await getData();
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const columns = [
    {
      headerName: "Size",
      field: "size",
      editable: true,
      flex: 1,
      renderHeader: () => <strong>Size</strong>,
    },
    {
      headerName: "Weight upto and including",
      field: "weight",
      editable: true,
      flex: 1,
      renderHeader: () => <strong>Weight upto and including</strong>,
    },
    {
      headerName: "Price (1st Class)",
      field: "price_first_class",
      type: "number",
      editable: true,
      flex: 1,
      renderHeader: () => <strong>Price (1st Class)</strong>,
    },
    {
      headerName: "Price (2nd Class)",
      field: "price_second_class",
      type: "number",
      editable: true,
      flex: 1,
      renderHeader: () => <strong>Price (2nd Class)</strong>,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      flex: 1,
      renderHeader: () => <strong>Actions</strong>,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon sx={{ fontSize: "25px" }} />}
              label="Save"
              onClick={handleSaveClick(id)}
              key={id}
            />,
            <GridActionsCellItem
              icon={<CancelIcon sx={{ fontSize: "25px" }} />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              key={id}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon sx={{ fontSize: "25px" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            key={id}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ fontSize: "25px" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            key={id}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Royal Mail Price Settings</div>
          <AdminNav />
        </div>
        <Box
          sx={{
            height: 800,
            width: "100%",
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
          }}
        >
          <DataGrid
            sx={{
              fontSize: 18,
            }}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={useCallback(
              (newModel) => setRowModesModel(newModel),
              []
            )}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            components={{
              Toolbar: EditToolbar,
            }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </section>
    </div>
  );
}
