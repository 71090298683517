import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
export default function BasicMarketSelect({
  arrayData,
  setMarketplaceFilterValue,
}) {
  const [val, setVal] = useState("all");
  const [marketPlaces, setMarketPlaces] = useState([]);
  useEffect(() => {
    if (arrayData !== undefined) {
      setMarketPlaces(arrayData);
    }
  });
  const handleChange = (event) => {
    const x = event.target.value;
    setVal(x);
    setMarketplaceFilterValue(x);
  };
  const useStyles = makeStyles({
    select: {
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      fontWeight: "bold",
      fontSize: "14px",
    },
    dropDownText: {
      fontSize: "14px",
      fontWeight: "bold",
    },
  });
  const classes = useStyles();

  BasicMarketSelect.propTypes = {
    arrayData: PropTypes.array.isRequired,
    setMarketplaceFilterValue: PropTypes.func.isRequired,
  };

  return (
    <div>
      <FormControl
        variant="standard"
        sx={{
          m: 1,
          minWidth: 120,
          borderBottom: "1px solid black",
          fontWeight: "bold",
        }}
      >
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={val}
          onChange={handleChange}
          className={classes.select}
          MenuProps={{
            disableScrollLock: true,
          }}
        >
          {marketPlaces.map((data) => (
            <MenuItem
              key={data.value}
              className={classes.dropDownText}
              value={data.value.toLowerCase()}
            >
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
