import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import { useParams } from "react-router-dom";
import { getQueryStrenght } from "../../../api/dashboardApi";
import "./AdminStrengthResult.scss";
import difference from "lodash.difference";

const AdminStrengthResult = () => {
  const { strengthId, query } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [strengthId]);

  const fetchData = async () => {
    const response = await getQueryStrenght(query);
    if (response.success) {
      setData(response.data);
      setLoading(false);
    }
  };

  let array = [];

  let objectArray = Object.entries(data);
  objectArray.forEach(([_categoryTypeId, found, _word]) => {
    array.push(found.word);
  });

  const objArray = array.filter((element) => {
    return element !== undefined;
  });

  let newString = query.split(" ").join(",").split(",");
  let diff = difference(newString, objArray);

  return (
    <div className="adminStrength dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="marketPlaceName dashboard__headingSec">{query} </div>
        {loading ? (
          <div>loading...</div>
        ) : (
          <div style={{ fontSize: "16px" }}>
            {data[0].found !== true ? (
              <div>
                <span className="span_bold">BRAND :</span> 0 words entered
                <br /> No word found
              </div>
            ) : (
              <div>
                <span className="span_bold">BRAND :</span> 1 words entered{" "}
                <br />
                {data[0].word}
              </div>
            )}

            {data[1].found !== true ? (
              <div>
                <span className="span_bold">SIZE :</span> 0 words entered
                <br /> no word found{" "}
              </div>
            ) : (
              <div>
                <span className="span_bold">SIZE :</span> 1 words entered <br />
                {data[1].word}
              </div>
            )}

            {data[2].found !== true ? (
              <div>
                <span className="span_bold">ITEM_CATEGORY :</span> 0 words
                entered <br /> no word found
              </div>
            ) : (
              <div>
                <span className="span_bold">ITEM_CATEGORY :</span> 1 words
                entered <br />
                {data[2].word}
              </div>
            )}

            {data[3].found !== true ? (
              <div>
                <span className="span_bold">MATERIAL :</span> 0 words entered
                <br /> no word found
              </div>
            ) : (
              <div>
                <span className="span_bold">MATERIAL :</span> 1 words entered{" "}
                <br />
                {data[3].word}
              </div>
            )}
            {data[4].found !== true ? (
              <div>
                <span className="span_bold">QUANTITY :</span> 0 words entered
                <br /> no word found
              </div>
            ) : (
              <div>
                <span className="span_bold">QUANTITY :</span> 1 words entered{" "}
                <br />
                {data[4].word}
              </div>
            )}
            {data[5].found !== true ? (
              <div>
                <span className="span_bold">MODEL :</span> 0 words entered
                <br /> no word found
              </div>
            ) : (
              <div>
                <span className="span_bold">MODEL :</span> 1 words entered{" "}
                <br />
                {data[5].word}
              </div>
            )}
            {diff === 0 ? (
              <div>
                <span className="span_bold"> Words not assigned to type</span>
                <br /> no matche found{" "}
              </div>
            ) : (
              <div>
                <span className="span_bold"> Words not assigned to type</span>{" "}
                <br />
                {diff.join(", ")}
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};
export default AdminStrengthResult;
