import propTypes from "prop-types";
import { getFormattedDate } from "../../utils/utilFunctions";
const ResponseTimeCell = ({ row }) => {
  ResponseTimeCell.propTypes = {
    row: propTypes.shape({
      responseDateTime: propTypes.string, // Adjust the prop type accordingly
    }).isRequired,
  };
  return (
    <div>
      {row.responseDateTime ? getFormattedDate(row.responseDateTime) : ""}
    </div>
  );
};

export default ResponseTimeCell;
