import React from "react";
import PropTypes from "prop-types";
import "./SubmitButton.scss";

const SubmitButton = ({
  className = "",
  type,
  disabled = false,
  text,
  onClick = null,
}) => {
  return (
    <button
      className={`submit-btn ${className}`}
      type={type}
      disabled={disabled}
      {...(onClick ? { onClick: onClick } : {})}
    >
      {text}
    </button>
  );
};
SubmitButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func,
};
export default SubmitButton;
