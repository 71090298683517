import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAllRemovedListingFromDb } from "../../../api/adminDashboardApi";
import "./AdminViewRemovedListing.scss";
const AdminViewRemovedListing = () => {
  const [removedlistingAllData, setRemovedListingAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const getRemovedListingById = async (itemId) => {
    const allRemovedListing = await getAllRemovedListingFromDb(itemId);
    if (allRemovedListing.success) {
      setRemovedListingAllData(allRemovedListing.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    getRemovedListingById(id);
  }, []);
  return (
    <>
      <div className="removedlisting-class">
        <p className="removedlisting-heading">
          All Removed Listing Of Specific Item:
        </p>
        {loading ? (
          <h3>Loading...</h3>
        ) : (
          <div className="json-class">
            <pre>{JSON.stringify(removedlistingAllData, null, 2)}</pre>{" "}
          </div>
        )}
      </div>
    </>
  );
};

export default AdminViewRemovedListing;
