import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyDataIntoClipBoard, copyData } from "../../utils/utilFunctions";
import IconButton from "@mui/material/IconButton";
import "./copyContent.scss";
const CopyContent = ({ content, variant }) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <Button
      variant={variant}
      className="copy-content-btn-class"
      onClick={() => {
        copyDataIntoClipBoard(content).then(() => setIsCopied(true));
      }}
    >
      {!isCopied ? <span>Copy Json</span> : <span>Copied!</span>}{" "}
      <ContentCopyIcon />
    </Button>
  );
};
export const CopyText = ({ content }) => {
  const onClick = useCallback((event) => {
    event.stopPropagation();
    copyData(content);
  }, []);
  return (
    <IconButton onClick={onClick}>
      <ContentCopyIcon style={{ color: "white" }} />
    </IconButton>
  );
};

CopyText.propTypes = {
  content: PropTypes.string.isRequired,
};

CopyContent.propTypes = {
  content: PropTypes.any,
  variant: PropTypes.any,
};
export default CopyContent;
