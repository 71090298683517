import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { getItemFilterData } from "../../api/adminDashboardApi";
import { INFO_TYPE } from "../../utils/Constants";

const FilterExpandableComponent = ({ data }) => {
  const [filterData, setFilterData] = useState([]);
  const getItemsFilterData = async (itemId) => {
    const filterResponse = await getItemFilterData(itemId);
    if (filterResponse.success) {
      setFilterData(filterResponse.data);
    }
  };
  useEffect(() => {
    getItemsFilterData(data.marketPlaceItemId);
  }, []);

  FilterExpandableComponent.propTypes = {
    data: propTypes.shape({
      marketPlaceItemId: propTypes.string.isRequired,
    }).isRequired,
  };

  return (
    <div className="innerTable">
      {filterData.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>
                <h1>Filter Name</h1>
              </th>
              <th>
                <h1>Items Before Filter</h1>
              </th>
              <th>
                <h1>Items After Filter</h1>
              </th>
              <th>
                <h1>Listing Type</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            {filterData.map((x) => (
              <tr key={x.id}>
                <td>
                  <h3>
                    <a
                      className="filter_a"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`/admin/filterJson/${x?.id}`}
                    >
                      {INFO_TYPE[x.infoType]}
                    </a>
                  </h3>
                </td>
                <td>
                  <h3>{x.itemsBeforeFilter}</h3>
                </td>
                <td>
                  <h3>{x.itemsAfterFilter}</h3>
                </td>
                <td>
                  <h3>{x.isActiveListing ? "Active" : "Sold"}</h3>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="noDataFound">No data found</div>
      )}
    </div>
  );
};

export default FilterExpandableComponent;
