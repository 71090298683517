// PairFormInput.jsx
import React from "react";
import PropTypes from "prop-types";

const PairFormInput = ({
  label,
  value,
  onChange,
  options,
  selectedValue,
  onSelectChange,
  inputType = "text",
  placeholder,
  required = false,
  title,
  minLength,
  maxLength,
}) => {
  return (
    <span>
      <label>{label}</label>
      {inputType === "select" ? (
        <select value={selectedValue} onChange={onSelectChange}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      ) : (
        <input
          name={label}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
          title={title}
          minLength={minLength}
          maxLength={maxLength}
        />
      )}
    </span>
  );
};
PairFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  selectedValue: PropTypes.any,
  onSelectChange: PropTypes.func,
  inputType: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};
export default PairFormInput;
