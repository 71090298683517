import React, { useState, useEffect } from "react";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import {
  isTokenInValid,
  toastErrMessage,
  toastSuccessMessage,
  findInArray,
} from "../../../utils/utilFunctions";

import { Add, Edit } from "@mui/icons-material";
import "./AdminConditions.scss";
import {
  getConditionsApi,
  getMarketPlacesApi,
  editConditionsApi,
  postConditionsApi,
} from "../../../api/adminDashboardApi";
const AdminConditions = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false); //TODO: disable submit button accordingly
  const [loading, setLoading] = useState(true);
  const [displayForm, setDisplayForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  //arrays of data objects
  const [conditionsData, setConditionsData] = useState([]);
  const [marketplacesData, setMarketplacesData] = useState([]);
  const [systemConditions, setSystemConditions] = useState([]);
  const [defaults, setDefaults] = useState([]);
  //used in form submit api requests
  const [conditionId, setConditionId] = useState();
  const [marketplaceConditionInput, setMarketplaceConditionInput] =
    useState("");
  const [marketplaceName, setMarketplaceName] = useState("Ebay");
  const [marketPlaceId, setMarketPlaceId] = useState(1);
  const [systemConditionInput, setSystemConditionInput] = useState(1);
  const [isActiveInput, setIsActiveInput] = useState(true);

  const fetchData = async () => {
    setLoading(true);

    const conditionsResponse = await getConditionsApi();
    if (conditionsResponse.success) {
      reshapeConditionsData(conditionsResponse.data);
    }

    const marketplacesResponse = await getMarketPlacesApi();
    if (marketplacesResponse.success) {
      setMarketplacesData(marketplacesResponse.data);
    }
    setLoading(false);
  };

  const reshapeConditionsData = (rawData) => {
    //1: create array without null system conditions
    //2: create array of system conditions
    let withoutNull = [];
    let systemConditionsOptions = [];
    let defaults = [];
    rawData.forEach((object) => {
      (!object.conditionParentId ? systemConditionsOptions : withoutNull).push(
        object
      );
      if (object.name.match(/default/gi)) {
        defaults.push(object);
      }
    });
    setConditionsData(withoutNull);
    setSystemConditions(systemConditionsOptions);
    setDefaults(defaults);
  };
  const checkDefaults = (id) => {
    if (marketplaceConditionInput.match(/default/gi)) {
      if (isDefault) return false; // if we are editing marketplace default
      return defaults.find((item) => item.marketPlaceId === id) !== undefined;
    }
  };

  const resetFormValues = () => {
    setConditionId(0);
    setSystemConditionInput(1);
    setMarketplaceName("Ebay");
    setMarketPlaceId(1);
    setMarketplaceConditionInput("");
    setIsActiveInput(true);
    setIsDefault(false);
  };

  const handleEditClick = (editCondition) => {
    setDisplayForm(true);
    setIsEditing(true);
    setConditionId(editCondition.id);
    setMarketPlaceId(editCondition.marketPlaceId);
    setMarketplaceName(
      findInArray(marketplacesData, editCondition.marketPlaceId, "id", "name")
    );
    setMarketplaceConditionInput(editCondition.name);
    setSystemConditionInput(editCondition.conditionParentId);
    setIsActiveInput(editCondition.isActive);
    editCondition.name.match(/default/gi)
      ? setIsDefault(true)
      : setIsDefault(false);
  };

  const handleCancel = () => {
    setDisplayForm(false);
    isEditing && setIsEditing(false);
    resetFormValues();
  };

  const handleAddClick = () => {
    setDisplayForm(true);
    setIsDefault(false);
  };

  const handleConditionSubmit = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);

    if (checkDefaults(marketPlaceId)) {
      toastErrMessage("Default for this marketplace already exists!");
      setSubmitDisabled(false);
      return;
    }

    let response;

    if (isEditing) {
      response = await editConditionsApi(conditionId, {
        name: marketplaceConditionInput,
        conditionParentId: systemConditionInput,
        isActive: isActiveInput,
      });
    } else {
      response = await postConditionsApi({
        name: marketplaceConditionInput,
        marketPlaceId: marketPlaceId,
        conditionParentId: systemConditionInput,
        isActive: isActiveInput,
      });
    }

    if (response.success) {
      toastSuccessMessage(
        isEditing
          ? "Changes saved successfully"
          : "Condition added successfully"
      );
      setDisplayForm(false);
      isEditing && setIsEditing(false);
      resetFormValues();
      await fetchData();
    } else {
      toastErrMessage(response.message);
    }
    setSubmitDisabled(false);
  };

  useEffect(() => {
    async function verifyToken() {
      const res = await isTokenInValid();
      if (res) window.location.href = "/login";
      else {
        await fetchData();
      }
    }
    verifyToken();
  }, []);

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">Conditions</div>
          <AdminNav />
        </div>

        {loading ? (
          <div>loading...</div>
        ) : conditionsData ? (
          <div className="innerTable conditionWrapper">
            {conditionsData.length > 0 ? (
              <table className="dashboard__tableBorder">
                <thead>
                  <tr>
                    <th className="dashboard__tableBorder">
                      <h1>Marketplace Name</h1>
                    </th>

                    <th className="dashboard__tableBorder">
                      <h1>Marketplace Conditions</h1>
                    </th>

                    <th className="dashboard__tableBorder">
                      <h1>System Conditions</h1>
                    </th>

                    <th className="dashboard__tableBorder">
                      <h1>Is Active</h1>
                    </th>

                    <th className="dashboard__tableBorder">
                      <h1>Edit</h1>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {conditionsData.map((condition, index) => (
                    <tr key={index}>
                      <td className="dashboard__tableBorder">
                        <h3>
                          {findInArray(
                            marketplacesData,
                            condition.marketPlaceId,
                            "id",
                            "name"
                          )}
                        </h3>
                      </td>

                      <td className="dashboard__tableBorder">
                        <h3>{condition.name}</h3>
                      </td>

                      <td className="dashboard__tableBorder">
                        <h3>
                          {" "}
                          {findInArray(
                            systemConditions,
                            condition.conditionParentId,
                            "id",
                            "name"
                          )}
                        </h3>
                      </td>

                      <td className="dashboard__tableBorder">
                        <h3> {condition.isActive === true ? "Yes" : "No"}</h3>
                      </td>

                      <td>
                        <button
                          className="pairIconWrapper"
                          disabled={displayForm}
                          onClick={() => handleEditClick(condition)}
                        >
                          <Edit sx={{ fontSize: "1.5em" }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No conditions found</div>
            )}

            {displayForm && (
              <div className="conditionForm">
                {isEditing ? (
                  <h1 className="formHeading">Edit Condition</h1>
                ) : (
                  <h1 className="formHeading">Add Condition</h1>
                )}
                <form onSubmit={handleConditionSubmit}>
                  <span>
                    <label htmlFor="marketplaceName">Marketplace Name</label>
                    {isEditing ? (
                      <p>{marketplaceName}</p>
                    ) : (
                      <select
                        value={marketPlaceId}
                        onChange={(e) => setMarketPlaceId(e.target.value)}
                      >
                        {marketplacesData.map((market) => (
                          <option key={market.id} value={market.id}>
                            {market.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </span>
                  <span>
                    <label>
                      Marketplace Condition
                      <input
                        name="marketplaceConditionText"
                        type="text"
                        required={true}
                        value={marketplaceConditionInput}
                        readOnly={isDefault}
                        onChange={(e) =>
                          setMarketplaceConditionInput(e.target.value)
                        }
                      />
                    </label>
                  </span>
                  <div className="pairFormInput">
                    <span>
                      <label htmlFor="systemConditions">
                        System Conditions
                      </label>
                      <select
                        value={systemConditionInput}
                        onChange={(e) =>
                          setSystemConditionInput(e.target.value)
                        }
                      >
                        {systemConditions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </span>
                    <span>
                      <label htmlFor="isActive">isActive</label>
                      <select
                        value={isActiveInput}
                        disabled={isDefault}
                        onChange={(e) => setIsActiveInput(e.target.value)}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </span>
                  </div>

                  <div className="pairFormInput">
                    <input
                      type="submit"
                      value={isEditing ? "Save changes" : "Add Condition"}
                      className="pairSubmit"
                      disabled={submitDisabled}
                    />
                    <input
                      type="button"
                      className="pairSubmit"
                      value="Cancel"
                      onClick={handleCancel}
                      disabled={submitDisabled}
                    />
                  </div>
                </form>
              </div>
            )}

            <div className="pairAdd">
              <Add sx={{ fontSize: "5em" }} onClick={handleAddClick} />
            </div>
          </div>
        ) : (
          <div>No conditions found</div>
        )}
      </section>
    </div>
  );
};

export default AdminConditions;
