import React, { Component, Suspense, lazy } from "react";
import { Route, Routes as RoutesDom, BrowserRouter } from "react-router-dom";
import RouteNames from "./RouteNames";
import RemoveList from "../views/AdminDashboard/AdminViewRemovedListing/AdminViewRemovedListing";
import Login from "../views/login/Login";

import Dashboard from "../views/Dashboard/Dashboard";
import AdminResults from "../views/AdminDashboard/AdminResults/AdminResults";
import AdminApiStats from "../views/AdminDashboard/AdminApiStats/AdminApiStats";
import AdminFilterStats from "../views/AdminDashboard/AdminFilterStats/AdminFilterStats";

import AdminMarketPlaceItemJson from "../views/AdminDashboard/AdminMarketPlaceItemJson/AdminMarketPlaceItemJson";
import AdminFilterJson from "../views/AdminDashboard/AdminFilterJson/AdminFilterJson";

import AdminBadWordStats from "../views/AdminDashboard/AdminBadWordStats/AdminBadWordStats";
import AdminTestFilter from "../views/AdminDashboard/AdminTestFilter/AdminTestFilter";
import AdminStores from "../views/AdminDashboard/AdminStores/AdminStores";
import AdminOrganization from "../views/AdminDashboard/AdminOrganization/AdminOrganization";

import AdminWordAnchorBank from "../views/AdminDashboard/AdminWordAnchorBank/AdminWordAnchorBank";
import AdminMarketPlaces from "../views/AdminDashboard/AdminMarketPlaces/AdminMarketPlaces";
import AdminBulkUpload from "../views/AdminDashboard/AdminBulkUpload/AdminBulkUpload";
import AdminLuminatiErrors from "../views/AdminDashboard/AdminLuminatiErrors/AdminLuminatiErrors";
import AdminConditions from "../views/AdminDashboard/AdminConditions/AdminConditions";
import AdminDisputedItems from "../views/AdminDashboard/AdminDisputedItems/AdminDisputedItems";
import NoPageFound from "../views/NoPageFound/NoPageFound";
import PostageSettings from "../views/AdminDashboard/AdminPostageSettings/AdminPostageSettings";
import UnassignedKeyWords from "../views/AdminDashboard/UnassignedKeyWords/UnassignedKeyWords";
import AdminStrengthResult from "../views/AdminDashboard/AdminStrength/AdminStrengthResult";
import AboutUs from "../views/WhyThrift/WhyThrift";
import AuthenticateRoute from "./AuthenticateRoute";
import UserSearchResult from "../views/Dashboard/UserSearchResult/UserSearchResult";

const ExternalApi = lazy(() => import("../views/Documentation/ExternalApi"));

const AdminPairs = lazy(() =>
  import("../views/AdminDashboard/AdminPairs/AdminPairs")
);

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <RoutesDom>
            <Route exact path={RouteNames.Default.route} element={<Login />} />
            <Route
              path={RouteNames.Documentation.route}
              element={<ExternalApi />}
            />
            <Route
              exact
              path={RouteNames.AboutUs.route}
              element={<AboutUs />}
            />
            <Route path={RouteNames.Login.route} element={<Login />} />
            <Route
              path={RouteNames.Dashboard.route}
              element={
                <AuthenticateRoute
                  Component={Dashboard}
                  Roles={RouteNames.Dashboard.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.Results.route}
              element={
                <AuthenticateRoute
                  Component={AdminResults}
                  Roles={RouteNames.Admin.Results.roles}
                />
              }
            />
            <Route
              path={`${RouteNames.UserSearchResult.route}/:itemID`}
              element={
                <AuthenticateRoute
                  Component={UserSearchResult}
                  Roles={RouteNames.UserSearchResult.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.ApiStats.route}
              element={
                <AuthenticateRoute
                  Component={AdminApiStats}
                  Roles={RouteNames.Admin.ApiStats.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.ApiFilterStats.route}
              element={
                <AuthenticateRoute
                  Component={AdminFilterStats}
                  Roles={RouteNames.Admin.ApiFilterStats.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.MarketPlaceItemData.route}
              element={
                <AuthenticateRoute
                  Component={AdminMarketPlaceItemJson}
                  Roles={RouteNames.Admin.MarketPlaceItemData.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.FilterData.route}
              element={
                <AuthenticateRoute
                  Component={AdminFilterJson}
                  Roles={RouteNames.Admin.FilterData.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.PAIRS.route}
              element={
                <AuthenticateRoute
                  Component={AdminPairs}
                  Roles={RouteNames.Admin.PAIRS.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.BAD_WORD_STATS.route}
              element={
                <AuthenticateRoute
                  Component={AdminBadWordStats}
                  Roles={RouteNames.Admin.BAD_WORD_STATS.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.TestFilter.route}
              element={
                <AuthenticateRoute
                  Component={AdminTestFilter}
                  Roles={RouteNames.Admin.TestFilter.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.Stores.route}
              element={
                <AuthenticateRoute
                  Component={AdminStores}
                  Roles={RouteNames.Admin.Stores.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.Organization.route}
              element={
                <AuthenticateRoute
                  Component={AdminOrganization}
                  Roles={RouteNames.Admin.Organization.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.WordAnchorBank.route}
              element={
                <AuthenticateRoute
                  Component={AdminWordAnchorBank}
                  Roles={RouteNames.Admin.WordAnchorBank.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.MarketPlaces.route}
              element={
                <AuthenticateRoute
                  Component={AdminMarketPlaces}
                  Roles={RouteNames.Admin.MarketPlaces.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.BulkUpload.route}
              element={
                <AuthenticateRoute
                  Component={AdminBulkUpload}
                  Roles={RouteNames.Admin.BulkUpload.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.LuminatiErrors.route}
              element={
                <AuthenticateRoute
                  Component={AdminLuminatiErrors}
                  Roles={RouteNames.Admin.LuminatiErrors.roles}
                />
              }
            />

            <Route
              path={RouteNames.Admin.Conditions.route}
              element={
                <AuthenticateRoute
                  Component={AdminConditions}
                  Roles={RouteNames.Admin.Conditions.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.DisputedItems.route}
              element={
                <AuthenticateRoute
                  Component={AdminDisputedItems}
                  Roles={RouteNames.Admin.DisputedItems.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.UnassignedKeyWords.route}
              element={
                <AuthenticateRoute
                  Component={UnassignedKeyWords}
                  Roles={RouteNames.Admin.UnassignedKeyWords.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.PostageSettings.route}
              element={
                <AuthenticateRoute
                  Component={PostageSettings}
                  Roles={RouteNames.Admin.PostageSettings.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.Strength.route}
              element={
                <AuthenticateRoute
                  Component={AdminStrengthResult}
                  Roles={RouteNames.Admin.Strength.roles}
                />
              }
            />
            <Route
              path={RouteNames.Admin.RemoveList.route}
              element={<RemoveList />}
              exact
            />
            <Route path="*" exact element={<NoPageFound />} />
            <Route
              exact
              path={RouteNames.Health.route}
              element={<p>App is running...</p>}
            />
          </RoutesDom>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default Routes;
