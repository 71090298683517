import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  toastSuccessMessage,
  toastErrMessage,
  amplitudeLog,
} from "../../../utils/utilFunctions";
import { postDisputeApi } from "../../../api/dashboardApi";
import FormControl from "@mui/material/FormControl";

export default function UserSearchResultModal({
  open,
  onClose,
  title = "Inaccurate result?",
  maxWidth = "xs",
  itemId,
  description,
  setUpdateData,
  noIssuesFound,
}) {
  let textInput = React.createRef();
  const [isDescriptionCompulsory, setIsDescriptionCompulsory] = useState(false);
  const [reportDescription, setReportDescription] = useState("");
  const [issue, setIssue] = useState("");
  const [email, setEmail] = useState("");

  const issues = [
    "Wrong brand",
    "Wrong product",
    "Wrong model",
    "Wrong condition",
    "Looks underpriced",
    "No results but I think there should be",
    "Other",
  ];

  //In case of no  item found in search (Dashboard)
  const noResultFeedbackIssues = [
    "No results but I think there should be",
    "Other",
  ];

  useEffect(() => {
    if (noIssuesFound) {
      setIssue(noResultFeedbackIssues[0]);
    }
  }, [noIssuesFound]);

  const handleIssueChange = (event) => {
    const {
      target: { value },
    } = event;
    setIssue(value);
    setIsDescriptionCompulsory(value === "Other");
  };
  const handleDescriptionChange = (event) => {
    if (event.target.value.trim().length > 255) {
      toastErrMessage("Description should be less than 255 characters.");
    } else {
      setReportDescription(event.target.value);
    }
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleClick = async (event) => {
    if (
      !issue ||
      (isDescriptionCompulsory && reportDescription.trim() === "")
    ) {
      return;
    }
    event.preventDefault();
    const payload = {
      itemId: itemId,
      description: description?.trim(),
      report: reportDescription,
      disputeReason: issue,
      disputedBy: email,
    };
    const response = await postDisputeApi(payload);

    if (response.success) {
      amplitudeLog(
        "Feedback form ",
        "Successfully send feedback",
        "user submit feedback",
        payload
      );
      setUpdateData(true);
      toastSuccessMessage("Marked as disputed successfully");
      onClose(false);
    } else toastErrMessage(response.message);
  };
  const shouldBtnDisable = () => {
    const descLength = reportDescription.trim().length;
    return !(
      issue &&
      (!isDescriptionCompulsory || (descLength > 0 && descLength <= 255))
    );
  };

  UserSearchResultModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    maxWidth: PropTypes.string,
    itemId: PropTypes.string.isRequired,
    description: PropTypes.string,
    setUpdateData: PropTypes.func.isRequired,
  };
  return (
    <div>
      {" "}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "30px",
            maxWidth: "592px",
            maxheight: "668px",
          },
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ModalHeader"
        maxWidth={maxWidth}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            textAlign: "left",
            fontWeight: "900",
            fontSize: "36px",
          }}
          className="ModalTitle"
        >
          {title}
          <DialogContent
            id="alert-dialog-description"
            style={{
              fontSize: "16px",
              color: "#262626",
              padding: "5px",
              fontWeight: "bold",
              lineHeight: "20px",
              textAlign: "left",
            }}
            className="DialogContent"
          >
            We’re constantly collecting data to provide you with the most
            accurate results we can. Your feedback will help to make Thrift
            better for everyone!
          </DialogContent>
          <IconButton
            aria-label="delete"
            onClick={onClose}
            style={{
              float: "right",
              marginTop: "-170px",
              marginRight: "-30px",
            }}
            className="muiButton"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          id="alert-dialog-description"
          style={{
            color: "#262626",
            padding: "40px",
          }}
        >
          <FormControl sx={{ width: "100%" }} size="small">
            <Select
              displayEmpty
              value={issue}
              onChange={handleIssueChange}
              style={{ borderBottom: "2px solid #636362", fontWeight: "bold" }}
              variant="standard"
              disableUnderline
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <span
                      style={{
                        fontWeight: "bold",
                        opacity: 0.5,
                      }}
                    >
                      Choose an issue
                    </span>
                  );
                }
                return selected;
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {noIssuesFound
                ? noResultFeedbackIssues.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={{ marginLeft: "-10px", fontWeight: "bold" }}
                    >
                      {name}
                    </MenuItem>
                  ))
                : issues.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={{ marginLeft: "-10px", fontWeight: "bold" }}
                    >
                      {name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
          {isDescriptionCompulsory ? (
            <TextField
              autoFocus
              style={{
                paddingBottom: "5px",
                marginTop: "35px",
              }}
              sx={{
                borderBottom: "2px solid #636362",
              }}
              margin="dense"
              id="reportDescription"
              placeholder="Description"
              type="text"
              fullWidth
              variant="standard"
              ref={textInput}
              required
              multiline
              value={reportDescription}
              onChange={handleDescriptionChange}
              InputProps={{
                style: { fontWeight: "bold", color: "black" },
                disableUnderline: true,
              }}
            />
          ) : (
            <TextField
              autoFocus
              style={{
                paddingBottom: "5px",
                marginTop: "35px",
              }}
              sx={{
                borderBottom: "2px solid #636362",
              }}
              margin="dense"
              id="reportDescription"
              placeholder="Description (optional)"
              type="text"
              fullWidth
              variant="standard"
              ref={textInput}
              multiline
              value={reportDescription}
              onChange={handleDescriptionChange}
              InputProps={{
                style: { fontWeight: "bold", color: "black" },
                disableUnderline: true,
              }}
            />
          )}
          <br />
          <br />
          <DialogContent
            id="alert-dialog-description"
            style={{
              fontSize: "20px",
              color: "#262626",
              padding: "20px 0px 20px 0px",
              fontWeight: "500",
              lineHeight: "20px",
              textAlign: "left",
            }}
            className="DialogContent"
          >
            If you would like us to follow up on your dispute please provide a
            contact email.
          </DialogContent>

          <TextField
            autoFocus
            margin="dense"
            id="email"
            placeholder="Email address (optional)"
            type="email"
            fullWidth
            variant="standard"
            ref={textInput}
            value={email}
            sx={{
              borderBottom: "2px solid #636362",
              paddingBottom: "5px",
            }}
            onChange={handleEmailChange}
            InputProps={{
              style: { fontWeight: "bold", color: "black", opacity: 1 },
              disableUnderline: true,
            }}
          />
        </DialogContent>
        <DialogActions className="DialogAction">
          <Button
            variant="contained"
            disabled={shouldBtnDisable()}
            style={{
              borderRadius: 50,
              minWidth: 205,
              maxWidth: 208,
              height: "45px",
              fontSize: "16px",
              color: "#262626",
              fontWeight: 800,
              backgroundColor: "#FA7C89",
              boxShadow: "6px 6px 12px #FA7C897A ",
              opacity: shouldBtnDisable() ? "0.3" : "1",
            }}
            onClick={handleClick}
          >
            REPORT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
