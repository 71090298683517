import React from "react";
import PropTypes from "prop-types";
import "./cardFooterContent.scss";
import CircularProgress from "@mui/material/CircularProgress";
const CardFooterContent = ({
  logo,
  alt,
  loaderSize,
  meanPrice,
  loaderColor,
}) => {
  return (
    <div className="cardFooterContent ">
      <div>
        <img src={logo} alt={alt} className="cardFooterContent-logo" />
      </div>
      <div>
        {meanPrice ? (
          <span className="cardFooterContent-price">&#163;{meanPrice}</span>
        ) : (
          <CircularProgress
            sx={{ color: `${loaderColor}` }}
            size={loaderSize}
          />
        )}
      </div>
    </div>
  );
};
CardFooterContent.propTypes = {
  logo: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  loaderSize: PropTypes.number.isRequired,
  meanPrice: PropTypes.number.isRequired,
  loaderColor: PropTypes.string.isRequired,
};
export default CardFooterContent;
