import React, { useState, useEffect } from "react";
import {
  findInArray,
  getKeyByValue,
  toastErrMessage,
  toastSuccessMessage,
} from "../../../utils/utilFunctions";
import Box from "@mui/material/Box";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import PairForm from "./PairForm";
import {
  getUnassignedKeyWordsApi,
  getByIdUnassignedKeyWordsApi,
  deleteUnassignedKeyWordsApi,
  postPairsApi,
  editPairsApi,
  getPairCategoriesApi,
} from "../../../api/adminDashboardApi";
import {
  PAIR_TYPE,
  PAIR_LOGIC_TYPE,
  CATEGORY_TYPE,
} from "../../../utils/Constants";
import Header from "../../../components/Header/Header";
import AdminNav from "../../../components/AdminNav/AdminNav";
import "../AdminPostageSettings/AdminPostageSettings.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function FullFeaturedCrudGrid() {
  const [pairCategories, setPairCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [pairId, setPairId] = useState();
  const [pairInput, setPairInput] = useState("");
  const [unassignedWordsId, setUnassignedWordsId] = useState("");

  const [pairDescripition, setPairDescripition] = useState("");
  const [selectedPairTypeNum, setSelectedPairTypeNum] = useState(3);
  const [selectedPairTypeText, setSelectedPairTypeText] =
    useState("GHOST WORD");
  const [selectedLogicType, setSelectedLogicType] = useState(1);
  const [selectedPairCategory, setSelectedPairCategory] = useState(2);
  const [isRegexLogic, setIsRegexLogic] = useState(false);
  const [replaceWithInput, setReplaceWithInput] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [shouldRelax, setShouldRelax] = useState(false);

  const [isMinimized, setIsMinimized] = useState(false);

  const [selectedCategoryType, setSelectedCategoryType] = useState(1);

  const [rows, setRows] = useState([]);
  const getData = async () => {
    const fetchedData = await getUnassignedKeyWordsApi();
    setRows(fetchedData.data);
  };

  const handleDeleteClick = (id) => async () => {
    setRows(rows.filter((row) => row.id !== id));
    await deleteUnassignedKeyWordsApi(id);
    await getData();
  };

  const handleSendToPair = async (event, id) => {
    setDisplayForm(true);
    const data = await getByIdUnassignedKeyWordsApi(id);

    const unassignedWords = data.data.unassignedwords;
    setPairInput(unassignedWords);
    setUnassignedWordsId(id);
  };

  const handlePairSubmit = async (event) => {
    event.preventDefault();
    setSubmitDisabled(true);
    setIsMinimized(false);

    const cleanedPairInput =
      selectedPairTypeText === "SANITISER"
        ? pairInput
            .toLowerCase()
            .split(",,")
            .map((pair) => pair.replace(/(,)(.+)/gi, "$2").trim())
            .join(",,")
        : pairInput
            .toLowerCase()
            .split(",")
            .map((pair) => pair.trim())
            .join();

    let response;
    if (isEditing) {
      response = await editPairsApi(pairId, {
        words: cleanedPairInput,
        pairCategoryId: Number(selectedPairCategory),
        replaceWith: replaceWithInput ? replaceWithInput : null,
        pairType: selectedPairTypeNum,
        logicType: selectedLogicType,
        shouldRelax: shouldRelax,
        description: pairDescripition,
        categoryTypeId: Number(selectedCategoryType),
      });
    } else {
      response = await postPairsApi({
        words: cleanedPairInput,
        pairType: selectedPairTypeNum,
        logicType: selectedLogicType,
        pairCategoryId: Number(selectedPairCategory),
        replaceWith: replaceWithInput ? replaceWithInput : null,
        shouldRelax: shouldRelax,
        description: pairDescripition,
        categoryTypeId: Number(selectedCategoryType),
      });
    }
    if (response.success) {
      toastSuccessMessage(
        isEditing ? "Changes saved successfully" : "Pair added successfully"
      );
      const deleteFunction = handleDeleteClick(unassignedWordsId);
      deleteFunction();

      setDisplayForm(false);
      isEditing && setIsEditing(false);
      resetFormValues();
      await fetchData(false);
    } else {
      toastErrMessage(response.message);
    }

    setSubmitDisabled(false);
  };
  const handleMinimize = () => {
    setDisplayForm(false);
    setIsMinimized(true);
  };
  const handlePairTypeChange = (e) => {
    const pairType = Number(e.target.value);
    setSelectedPairTypeNum(pairType);
    setSelectedPairTypeText(getKeyByValue(PAIR_TYPE, pairType));
  };

  const handleLogicTypeChange = (e) => {
    const logicType = Number(e.target.value);
    setSelectedLogicType(logicType);
    if (logicType === findInArray(logicTypes, "STANDARD", "text", "value")) {
      setIsRegexLogic(false);
      setReplaceWithInput("");
    } else {
      setIsRegexLogic(true);
    }
  };
  const resetFormValues = () => {
    setPairId(0);
    setPairInput("");
    setPairDescripition("");
    setSelectedPairTypeNum(3);
    setSelectedPairTypeText("GHOST WORD");
    setSelectedLogicType(1);
    setSelectedPairCategory(2);
    setIsRegexLogic(false);
    setReplaceWithInput("");
  };
  const handleCancel = () => {
    setDisplayForm(false);
    setIsMinimized(false);
    isEditing && setIsEditing(false);
    resetFormValues();
  };

  const pairTypes = Object.keys(PAIR_TYPE).map((key) => {
    return {
      value: PAIR_TYPE[key],
      text: key,
    };
  });
  const logicTypes = Object.keys(PAIR_LOGIC_TYPE).map((key) => {
    return {
      value: PAIR_LOGIC_TYPE[key],
      text: key,
    };
  });
  const CategoryType = Object.keys(CATEGORY_TYPE).map((key) => {
    return {
      value: CATEGORY_TYPE[key],
      text: key,
    };
  });
  const fetchData = async (load = true) => {
    load && setLoading(true);

    const pairCategoryResponse = await getPairCategoriesApi();
    if (pairCategoryResponse.success) {
      setPairCategories(pairCategoryResponse.data);
    }

    load && setLoading(false);
  };

  const PairCategories = Object.keys(pairCategories).map((key) => {
    return {
      value: pairCategories[key].id,
      text: pairCategories[key].name,
    };
  });

  const columns = [
    {
      headerName: "itemId",
      field: "itemId",
      type: "number",
      editable: true,

      renderHeader: () => <strong>Item Id</strong>,
    },
    {
      headerName: "title",
      field: "title",
      editable: true,
      flex: 1,
      renderHeader: () => <strong>User search</strong>,
    },
    {
      headerName: "unassignedwords",
      field: "unassignedwords",
      editable: true,
      flex: 1,
      renderHeader: () => <strong>Unassigned Words</strong>,
    },

    {
      field: "pairsaction",
      type: "actions",
      headerName: "actions",
      cellClassName: "actions",
      flex: 1,
      renderHeader: () => <strong>Send to pairs </strong>,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<AddIcon sx={{ fontSize: "25px" }} />}
            label="AddIcon"
            onClick={(event) => handleSendToPair(event, id)}
            color="inherit"
            key={id}
          />,
        ];
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      flex: 1,
      renderHeader: () => <strong>Actions</strong>,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon sx={{ fontSize: "25px" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            key={id}
          />,
        ];
      },
    },
  ];

  useEffect(async () => {
    await getData();
    await fetchData();
  }, []);

  return (
    <div className="adminFilterStats dashboard">
      <div className="dashboard__container">
        <Header isAuthenticated={true} />
      </div>
      <section className="dashboard__results">
        <div className="dashboard__nav">
          <div className="dashboard__headingSec">unassigned Key Words</div>
          <AdminNav />
        </div>
        {rows?.length ? (
          <Box
            sx={{
              height: 800,
              width: "100%",
              "& .actions": {
                color: "text.secondary",
              },
              "& .textPrimary": {
                color: "text.primary",
              },
            }}
          >
            <DataGrid
              sx={{
                fontSize: 18,
              }}
              rows={rows}
              columns={columns}
            />
          </Box>
        ) : (
          <div className="noDataFound">No data found</div>
        )}
      </section>

      {displayForm && (
        <PairForm
          selectedPairTypeText={selectedPairTypeText}
          pairInput={pairInput}
          setPairInput={setPairInput}
          handlePairTypeChange={handlePairTypeChange}
          pairTypes={pairTypes}
          selectedPairTypeNum={selectedPairTypeNum}
          handleLogicTypeChange={handleLogicTypeChange}
          logicTypes={logicTypes}
          setSelectedPairCategory={setSelectedPairCategory}
          selectedPairCategory={selectedPairCategory}
          PairCategories={PairCategories}
          isRegexLogic={isRegexLogic}
          replaceWithInput={replaceWithInput}
          setReplaceWithInput={setReplaceWithInput}
          selectedCategoryType={selectedCategoryType}
          setSelectedCategoryType={setSelectedCategoryType}
          CategoryType={CategoryType}
          pairDescripition={pairDescripition}
          setPairDescripition={setPairDescripition}
          shouldRelax={shouldRelax}
          setShouldRelax={setShouldRelax}
          handlePairSubmit={handlePairSubmit}
          handleCancel={handleCancel}
          submitDisabled={submitDisabled}
          unassignedWordsId={unassignedWordsId}
          isEditing={isEditing}
          handleMinimize={handleMinimize}
        />
      )}
    </div>
  );
}
